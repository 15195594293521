import { getCall, patchCall } from '../request';

const PatchSurrogateService = async (data, surrogateId) => {
  const access_token = localStorage.getItem('token');

  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
  
  return await patchCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/surrogate/${surrogateId}`,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: `Bearer ${access_token}`
    },
    data
  });
};

export default PatchSurrogateService;
