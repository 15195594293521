/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Input, Modal, notification, Table, Tooltip } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import AddSponsor from '../../../layout/components/sponsors/AddSponsor';
import DeleteSponsor from '../../../layout/components/sponsors/DeleteSponsor';
import AddPromotionCode from '../../../layout/components/sponsors/AddPromotionCode';

import DeleteSponsorService from '../../../api/sponsor/delete-sponsor';
import GetSponsorService from '../../../api/sponsor/get-sponsor';

import { INews, ISponsor } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  onEditSponsor?: (sponsor: ISponsor) => void;
  onDeleteSponsor?: (id: string) => void;
  visibleAddSponsorButton?: boolean;
};

const SponsorsTable = ({ visibleAddSponsorButton = false }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [sponsors, setSponsors] = useState<ISponsor[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState({
    name: true,
    description: true,
    actions: true
  });
  const onClose = () => setVisible(false);

  const actions = (sponsor: ISponsor, news: INews) => (
    <div className='buttons-list nowrap'>
      <AddSponsor componentType={'update'} sponsor={sponsor} />
      <AddPromotionCode sponsorId={sponsor.id} />
      <DeleteSponsor onAccept={() => handleDeleteSponsor(sponsor.id)} />
    </div>
  );

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleDeleteSponsor = (id) => {
    setLoading(true);
    DeleteSponsorService(id)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification('bottomRight', 'success', t.notification_successful_sponsor_delete, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_sponsor_delete,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetSponsor();
  }, [updated, currentPage]);

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t.sponsors_table_column_name_text,
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: t.sponsors_table_column_description_text,
      render: (description) => <strong>{description}</strong>
    },
    {
      key: 'actions',
      title: t.sponsors_table_column_operations_text,
      render: !visibleAddSponsorButton ? actions : null
    }
  ];

  useEffect(() => {
    const data = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(data);
  }, [filterColumns]);

  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };
  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleGetSponsor, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  //kullanıcı yazmayı bitirdiğinde çalışacak fonksiyon
  const handleGetSponsor = () => {
    // page=1&limit=2&sortBy=createdAt:DESC&filter.kvkk=1&filter.gdprStatus=306
    if (filterText.length > 2 || filterText.length < 1) {
      const params = { search: filterText, page: currentPage, limit: 10 };
      GetSponsorService(params)
        .then((res) => {
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
          setSponsors(res.data);
          openNotification('bottomRight', 'success', t.notification_successful_sponsor_load, '');
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_sponsor_load,
            ''
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex' }}>
        {!visibleAddSponsorButton && <AddSponsor componentType={'add'} />}
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Modal
            title={<h3>{t.general_column_filter_text}</h3>}
            visible={visible}
            footer={null}
            onCancel={onClose}
            destroyOnClose
          >
            {columns.map((column) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ width: 100 }}>{column.title}</span>
                  <input
                    type='checkbox'
                    name={column.key}
                    checked={filterColumns[column.key]}
                    onChange={() =>
                      setFilterColumns((state) => ({
                        ...state,
                        [column.key]: !filterColumns[column.key]
                      }))
                    }
                  />
                </div>
              );
            })}
          </Modal>
          <Button type='primary' onClick={() => setVisible(true)}>
            {t.patient_operations_table_column_button_text}
          </Button>
        </div>
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Tooltip title={t.general_search_tip_text}>
            <Input
              type='text'
              id='searchInput'
              placeholder='Search'
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </Tooltip>
        </div>
      </div>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={sponsors}
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        columns={filteredColumns}
        loading={loading}
      />
    </>
  );
};

export default SponsorsTable;
