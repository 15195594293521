import { postCall } from '../request';

const PostMailReSendSecretaryService = async (email) => {
  const access_token = localStorage.getItem('token');

  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/mail/resend/secretary?email=${email}`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default PostMailReSendSecretaryService;
