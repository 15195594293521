import { getCall, postCall } from '../request';

const PostMailResetVerifyService = async (data, verify_token) => {
  const access_token = localStorage.getItem('token');

  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: verify_token ? `Bearer ${verify_token}` : `Bearer ${access_token}`
    }
  });

  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/mail/reset/verify`,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: verify_token ? `Bearer ${verify_token}` : `Bearer ${access_token}`
    },
    data: data
  });
};

export default PostMailResetVerifyService;
