/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';
import moment from 'moment';
import { Button, Input, Modal, notification, Table, Tooltip } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import GetPromotionCodesService from '../../../api/promotions/get-promotion';
import DeletePromotionService from '../../../api/promotions/delete-promotion';

import DeletePromotion from '../../../layout/components/promotion/DeletePromotion';
import GetPromotion from '../../../layout/components/promotion/GetPromotion';

import { LanguageContext, translations } from '../../../translation';

const PromotionCodesTable = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [api, contextHolder] = notification.useNotification();
  const updated = useSelector((state: any) => state.updated);
  const dispatch = useDispatch();
  const [promotionCodes, setPromotionCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState({
    name: true,
    description: false,
    quantity: true,
    expiredAt: true,
    prefix: false,
    actions: true,
    sponsor: true
  });
  useEffect(() => {
    if (filterColumns) {
      setFilterColumns(filterColumns);
    }
  }, []);
  const onClose = () => setVisible(false);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const actions = (promotion) => (
    <div className='buttons-list nowrap'>
      <GetPromotion promotion={promotion} />
      <DeletePromotion onAccept={() => handleDeletePromotion(promotion.id)} />
    </div>
  );

  const handleDeletePromotion = (id) => {
    setLoading(true);
    DeletePromotionService(id)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification('bottomRight', 'success', t.notification_successful_code_deleted, '');
      })
      .catch((err) => {
        console.log(err, 'error');
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_code_deleted,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t.promo_codes_table_column_name_text,
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: t.promo_codes_table_column_description_text,
      render: (description) => <strong>{description}</strong>
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: t.promo_codes_table_column_quantity_text,
      render: (quantity) => <strong>{quantity}</strong>
    },
    {
      key: 'sponsor',
      dataIndex: 'sponsor',
      title: t.promo_codes_table_column_sponsor_text,
      render: (sponsor) => <strong>{sponsor?.name}</strong>
    },
    {
      key: 'expiredAt',
      dataIndex: 'expiredAt',
      title: t.promo_codes_table_column_expiration_date_text,
      render: (expiredAt) => <strong>{moment(expiredAt).format('DD/MM/YYYY')}</strong>
    },
    {
      key: 'prefix',
      dataIndex: 'prefix',
      title: t.promo_codes_table_column_prefix_text,
      render: (prefix) => <strong>{prefix}</strong>
    },
    {
      key: 'actions',
      title: t.promo_codes_table_column_operations_text,
      render: actions
    }
  ];

  useEffect(() => {
    const data = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(data);
  }, [filterColumns]);

  useEffect(() => {
    handleGetPromotionCodes();
  }, [currentPage]);

  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };
  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleGetPromotionCodes, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  //kullanıcı yazmayı bitirdiğinde çalışacak fonksiyon
  const handleGetPromotionCodes = () => {
    // page=1&limit=2&sortBy=createdAt:DESC&filter.kvkk=1&filter.gdprStatus=306
    if (filterText.length > 2 || filterText.length < 1) {
      const params = {
        search: filterText,
        relations: ['codes', 'sponsor'],
        page: currentPage,
        limit: 9
      };
      GetPromotionCodesService(params)
        .then((res) => {
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
          setPromotionCodes(res?.data);
          openNotification('bottomRight', 'success', t.notification_successful_code_loaded, '');
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_code_loaded,
            ''
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex' }}>
        <div style={{ maxWidth: 200, margin: '0 0 12px 0' }}>
          <Modal
            title={<h3>{t.general_column_filter_text}</h3>}
            visible={visible}
            footer={null}
            onCancel={onClose}
            destroyOnClose
          >
            {columns.map((column) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ width: 100 }}>{column.title}</span>
                  <input
                    type='checkbox'
                    name={column.key}
                    checked={filterColumns[column.key]}
                    onChange={() =>
                      setFilterColumns((state) => ({
                        ...state,
                        [column.key]: !filterColumns[column.key]
                      }))
                    }
                  />
                </div>
              );
            })}
          </Modal>
          <Button type='primary' onClick={() => setVisible(true)}>
            {t.general_table_column_text}
          </Button>
        </div>
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Tooltip title={t.general_search_tip_text}>
            <Input
              type='text'
              id='searchInput'
              placeholder={t.general_search_text}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </Tooltip>
        </div>
      </div>
      <Table
        pagination={pagination}
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        className='accent-header'
        rowKey='id'
        dataSource={promotionCodes}
        columns={filteredColumns}
        loading={loading}
      />
    </>
  );
};

export default PromotionCodesTable;
function dispatch(arg0: { type: string; payload: boolean }) {
  throw new Error('Function not implemented.');
}
