/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Input, Modal, notification, Table, Tooltip } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import GetPackageService from '../../../api/package/get-package';
import DeletePackageService from '../../../api/package/delete-package';
import PostOrderService from '../../../api/order/post-order';

import DeletePackage from './DeletePackage';
import AddPackage from './AddPackage';
import GetPackageConsent from './GetPackageConsent';
import BillingForm from '../../../layout/components/billing/BillingForm';
import IyzicoCheckout from './../../../assets/img/iyzico/footer_iyzico_ile_ode/Colored/logo_band_colored@1X.png';
import { IUserInfo } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

const PackageTable = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleBilling, setVisibleBilling] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [visibleBuy, setVisibleBuy] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState<any>([]);
  const [filterColumns, setFilterColumns] = useState({
    name: true,
    description: true,
    duration: true,
    price: true,
    isSingle: true,
    actions: true
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [paymentPageUrl, setPaymentPageUrl] = useState('');
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);

  const onClose = () => setVisible(false);
  const onBillingClose = () => setVisibleBilling(false);

  const actions = (consentPackage: any) => {
    return (
      <div className='buttons-list nowrap'>
        <GetPackageConsent consentGroups={consentPackage?.consentGroups} />
        {userInfo?.role === 'admin' && (
          <AddPackage componentType={'update'} consentPackage={consentPackage} />
        )}
        {userInfo?.role === 'admin' && (
          <DeletePackage onAccept={() => handleDeletePackage(consentPackage.id)} />
        )}
      </div>
    );
  };
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleDeletePackage = (id) => {
    setLoading(true);
    DeletePackageService(id)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification('bottomRight', 'success', t.notification_successful_package_delete, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_package_delete,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [optionsDurations] = useState([
    { value: `1 ${t.purchase_package_month_text}`, id: 'P1M' },
    { value: `3 ${t.purchase_package_month_text}`, id: 'P3M' },
    { value: `6 ${t.purchase_package_month_text}`, id: 'P6M' },
    { value: `9 ${t.purchase_package_month_text}`, id: 'P9M' },
    { value: `1 ${t.purchase_package_year_text}`, id: 'P1Y' }
  ]);

  useEffect(() => {
    handleGetPackage();
  }, [updated, currentPage]);

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t.purchase_package_table_column_name_text,
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: t.purchase_package_table_column_description_text,
      render: (description) => <strong>{description}</strong>
    },
    {
      key: 'duration',
      dataIndex: 'duration',
      title: t.purchase_package_table_column_package_duration_text,
      render: (duration) => (
        <strong>{optionsDurations.find((el) => el.id === duration).value}</strong>
      )
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: t.purchase_package_table_column_package_price_text,
      render: (price) => <strong>{price} ₺</strong>
    },
    {
      key: 'isSingle',
      dataIndex: 'isSingle',
      title: t.purchase_package_table_column_package_type_text,
      render: (isSingle) => (
        <strong>
          {isSingle ? t.purchase_package_single_text : t.purchase_package_multiple_text}
        </strong>
      )
    },

    {
      key: 'actions',
      title: t.purchase_package_table_column_operations_text,
      render: actions
    }
  ];

  useEffect(() => {
    const data = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(data);
  }, [filterColumns]);

  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };

  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleGetPackage, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  //kullanıcı yazmayı bitirdiğinde çalışacak fonksiyon
  const handleGetPackage = () => {
    if (filterText.length > 2 || filterText.length < 1) {
      const params = { search: filterText, page: currentPage, limit: 9 };
      GetPackageService(params)
        .then((res) => {
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
          setPackages(res.data);
          openNotification('bottomRight', 'success', t.notification_successful_package_load, '');
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_package_load,
            ''
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleBuyConsent = () => {
    if (userInfo?.billingAddress !== null) {
      if (selectedRows.length > 0) {
        const packageIds = selectedRows.map((el) => el.id);
        PostOrderService({ packageIds })
          .then((res) => {
            setPaymentPageUrl(res.paymentPageUrl);
            setVisibleBuy(true);
          })
          .catch((err) => err);
      } else openNotification('bottomRight', 'error', t.notification_error_package_choose, '');
    } else {
      setVisibleBilling(true);
    }
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    }
  };
  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', marginBottom: 12 }}>
          {userInfo?.role === 'admin' && <AddPackage componentType={'add'} />}
          <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
            <Modal
              title={<h3>{t.general_column_filter_text}</h3>}
              visible={visible}
              footer={false}
              onCancel={onClose}
              destroyOnClose
            >
              {columns.map((column, index) => {
                return (
                  <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ width: 100 }}>{column.title}</span>
                    <input
                      type='checkbox'
                      name={column.key}
                      checked={filterColumns[column.key]}
                      onChange={() =>
                        setFilterColumns((state) => ({
                          ...state,
                          [column.key]: !filterColumns[column.key]
                        }))
                      }
                    />
                  </div>
                );
              })}
            </Modal>
            <Button type='primary' onClick={() => setVisible(true)}>
              {t.general_table_column_text}
            </Button>
          </div>
          <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
            <Tooltip title={t.general_search_tip_text}>
              <Input
                type='text'
                id='searchInput'
                placeholder={t.general_search_text}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Tooltip>
          </div>
        </div>

        <div>
          <img style={{ height: 24 }} className='mr-2' src={IyzicoCheckout} alt='' />
          <Button
            disabled={selectedRows.length < 1}
            onClick={handleBuyConsent}
          >{`${t.purchase_package_consent_purchase_text}`}</Button>
          {userInfo?.billingAddress !== null ? (
            <Modal
              title={<h3>{t.purchase_package_consent_purchase_text}</h3>}
              visible={visibleBuy}
              footer={null}
              onCancel={() => setVisibleBuy(false)}
              destroyOnClose
              width={'80vw'}
            >
              <iframe
                src={paymentPageUrl}
                style={{ width: '75vw', height: '75vh' }}
                title={'iyzico'}
              ></iframe>
            </Modal>
          ) : (
            <Modal
              visible={visibleBilling}
              footer={false}
              onCancel={onBillingClose}
              destroyOnClose
              bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
              width={'50vw'}
            >
              <BillingForm onCancel={onBillingClose} />
            </Modal>
          )}
        </div>
      </div>
      <Table
        rowSelection={{
          ...rowSelection
        }}
        pagination={pagination}
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        className='accent-header'
        rowKey='id'
        dataSource={packages}
        columns={filteredColumns}
        loading={loading}
      />
    </>
  );
};

export default PackageTable;
