/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Input, notification, Select, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment, { Moment } from 'moment';
import 'moment/locale/tr';

import PostPromotionService from '../../../api/promotions/post-promotion';
import GetPackageOnlyNameService from '../../../api/package/get-package-only-name';

import getDayDifference from '../../../utils/getDateDiff';

import { LanguageContext, translations } from '../../../translation';

type Props = {
  onCancel: () => void;
  sponsorId: string;
};

interface IValues {
  name: string;
  description: string;
  quantity: number;
  prefix: string;
  expiredAt: string;
}

const CreatePromotionCode = ({ onCancel, sponsorId }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [selectedOption, setSelectedOption] = useState([]);
  const [value, setValue] = React.useState<Moment | null>(null);
  const [form] = useForm();
  const [options, setOptions] = useState([]);
  const [formValues, setFormValues] = useState<IValues>({
    name: '',
    description: '',
    quantity: 0,
    prefix: '',
    expiredAt: ''
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    GetPackageOnlyNameService().then((res) => {
      const data = [{ name: 'Public', id: null }, ...res.data].map((el, index) => ({
        value: el.id,
        label: el.name,
        key: index
      }));
      setOptions(data);
    });
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    if (value.isValid()) {
      formValues.expiredAt = moment(value.toISOString()).format('YYYY-MM-DD');
      const newDate = moment(formValues.expiredAt, ['DD/MM/YYYY', 'YYYY-MM-DD']).format(
        'YYYY-MM-DD'
      );
      const parsedValues = {
        name: values.name,
        description: values.description,
        quantity: Number(values.quantity),
        prefix: values.prefix,
        expiredAt: formValues.expiredAt === '' ? null : newDate,
        sponsorId,
        packageIds: selectedOption.map((el) => el.value)
      };
      PostPromotionService(parsedValues)
        .then(() => {
          form.setFieldsValue({
            name: '',
            description: '',
            quantity: 0,
            prefix: '',
            expiredAt: ''
          });
          openNotification('bottomRight', 'success', t.notification_successful_code_created, '');
          dispatch(setDataUpdated(!updated));
          setTimeout(() => {
            onCancel();
          }, 1000);
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err.response.data.message ?? t.notification_error_code_created,
            ''
          );
        })
        .finally(() => setLoading(false));
    } else openNotification('bottomRight', 'error', t.notification_error_past_expiration_date, '');
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const handleChange = (newValue: Moment | null) => {
    const today = new Date();
    const date2 = new Date(moment(newValue).format());
    const diff = getDayDifference(today, date2);
    if (diff > 0) return;
    setValue(newValue);
  };

  return (
    <>
      {contextHolder}
      {/* <Modal visible={visible} footer={null} onCancel={onClose} destroyOnClose> */}
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout={'vertical'}>
        <h3>{t.sponsors_operations_tips_create_code_text}</h3>
        <div className='form-group'>
          <Form.Item
            rules={[{ required: true, message: t.input_error_missing_promotion_name }]}
            label={t.sponsors_create_code_popup_name_text}
            name={'name'}
          >
            <Input
              name={'name'}
              placeholder={t.sponsors_create_code_popup_name_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.name}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'description'}
            rules={[{ required: true, message: t.input_error_missing_promotion_description }]}
            label={t.sponsors_create_code_popup_description_text}
          >
            <Input
              name={'description'}
              placeholder={t.sponsors_create_code_popup_description_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.description}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'quantity'}
            rules={[{ required: true, message: t.input_error_missing_promotion_quantity }]}
            label={t.sponsors_create_code_popup_code_number_text}
          >
            <Input
              min={1}
              name={'quantity'}
              placeholder={t.sponsors_create_code_popup_code_number_text}
              type='number'
              onChange={handleFormValues}
              value={formValues.quantity}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'prefix'}
            rules={[
              { required: true, message: t.input_error_missing_promotion_prefix },
              { max: 5, min: 3 }
            ]}
            label={t.sponsors_create_code_popup_prefix_text}
          >
            <Input
              name={'prefix'}
              type='text'
              placeholder={t.sponsors_create_code_popup_prefix_text}
              onChange={handleFormValues}
              value={formValues.prefix}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Form.Item
              name={'expiredAt'}
              label={t.sponsors_create_code_popup_end_date_text}
              rules={[
                {
                  required: value ? false : true,
                  message: t.input_error_missing_promotion_expiration_date
                }
              ]}
            >
              {' '}
              <DatePicker
                className='customize_date_picker'
                inputFormat='DD-MM-YYYY'
                value={value}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'GG / AA / YYYY'
                    }}
                  />
                )}
                disablePast
              />{' '}
            </Form.Item>{' '}
          </LocalizationProvider>
        </div>
        <div className='form-group'>
          <Form.Item
            name='packageIds'
            rules={[{ required: true, message: t.input_error_missing_promotion_package }]}
          >
            <Select
              maxTagCount='responsive'
              mode='multiple'
              options={options}
              placeholder={t.sponsors_create_code_popup_packages_text}
              onChange={(value, option: any) => setSelectedOption(option)}
            />
          </Form.Item>
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={onCancel}>
            {t.general_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {t.sponsors_operations_tips_create_code_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreatePromotionCode;
