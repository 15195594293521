import React, { useContext, useState } from 'react';

import { Button, Modal, Tooltip } from 'antd';

import SponsorForm from './SponsorForm';
import UpdateSponsorForm from './UpdateSponsorForm';

import { ISponsor } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IAddSponsor {
  sponsor?: ISponsor;
  componentType: 'update' | 'add';
}

const AddSponsor = ({ componentType, sponsor }: IAddSponsor) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const handleClick = () => setVisible(!visible);
  const closeModal = () => setVisible(false);

  return (
    <div className='add-sponsor'>
      {componentType === 'update' && (
        <Tooltip title={t.sponsors_operations_tips_update_sponsor_text}>
          <Button htmlType='submit' onClick={handleClick} shape='circle' type='primary'>
            <span className='icofont icofont-edit-alt' />
          </Button>
        </Tooltip>
      )}
      {componentType === 'add' && (
        <Button style={{ marginBottom: 16 }} type='primary' onClick={handleClick} htmlType='submit'>
          <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
          {t.sponsors_add_sponsor_text}
        </Button>
      )}
      <Modal
        bodyStyle={{ maxHeight: 700, overflowY: 'scroll' }}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
      >
        {componentType === 'update' && (
          <UpdateSponsorForm onCancel={closeModal} sponsor={sponsor} />
        )}
        {componentType === 'add' && <SponsorForm onCancel={closeModal} />}
      </Modal>
    </div>
  );
};

export default AddSponsor;
