import { postCall } from '../request';
import FormData from 'form-data';
import jwt_decode from 'jwt-decode';

const PostGDPRFormService = async (obj) => {
  const access_token = localStorage.getItem('token');
  const decodedToken: any = jwt_decode(access_token);

  let data = new FormData();
  data.append('name', obj.name);
  data.append('file', obj.file);
  data.append('isLatest', obj.isLatest);

  return postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/gdpr-form`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'x-tenant-id': decodedToken?.tenantId,
      'Content-Type': 'multipart/form-data'
    },
    data: data,
    timeout: 60 * 1000
  });
};

export default PostGDPRFormService;
