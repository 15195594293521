/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Input, Modal, notification, Table, Tag, Tooltip } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import GetAllPatientService from '../../../api/patient/get-patients';

import statusCode from '../../../utils/statusCode';
import { DisplayPhoneNumber } from '../../../layout/components/phoneFormat/PhoneFormat';
import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  onEditPatient?: (patient: IPatient) => void;
  onDeletePatient?: (id: string) => void;
  visibleAddPatientButton?: boolean;
  defaultSelectedRows?: IPatient[];
};

const PatientsTable = ({ defaultSelectedRows = [] }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [api, contextHolder] = notification.useNotification();
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState({
    firstName: true,
    lastName: true,
    tckn: false,
    gsm: true,
    email: false,
    birthDay: true,
    gdprStatus: true,
    patientConsent: true
  });
  useEffect(() => {
    if (filterColumns) {
      setFilterColumns(filterColumns);
    }
  }, []);
  const onClose = () => setVisible(false);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const columns = [
    {
      key: 'firstName',
      dataIndex: 'firstName',
      title: t.patient_operations_table_column_name_text,
      render: (firstName) => <strong>{firstName}</strong>
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: t.patient_operations_table_column_surname_text,
      render: (lastName) => <strong>{lastName}</strong>
    },
    {
      key: 'tckn',
      dataIndex: 'tckn',
      title: t.patient_operations_table_column_id_text,
      render: (tckn) => (
        <span className='nowrap' style={{ minWidth: 125, display: 'block', color: '#a5a5a5' }}>
          {tckn}
        </span>
      )
    },
    {
      key: 'gsm',
      dataIndex: 'gsm',
      title: t.patient_operations_table_column_gsm_text,
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {DisplayPhoneNumber(phone)}
        </span>
      )
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t.patient_operations_table_column_email_text,
      render: (email) => <span style={{ minWidth: 125, display: 'block' }}>{email}</span>
    },
    {
      key: 'birthDay',
      dataIndex: 'birthDay',
      title: t.patient_operations_table_column_dob_text,
      render: (birthDay) => (
        <span style={{ minWidth: 100, display: 'block' }}>
          {moment(birthDay).format('DD/MM/YYYY')}
        </span>
      )
    },
    {
      key: 'gdprStatus',
      title: t.patient_operations_table_column_gdpr_status_text,
      render: (patient: IPatient) => {
        return (
          <>
            <Tag
              color={
                (patient.gdprStatus == 'unapproved' && '#f5222d') ||
                (patient.gdprStatus == 'pending' && '#F9A602') ||
                (patient.gdprStatus == 'approved' && '#299617')
              }
            >
              <Tooltip title={t.patient_list_tip_operations_text}>
                <span>{t[statusCode(patient.gdprStatus)]}</span>
              </Tooltip>
            </Tag>
          </>
        );
      }
    },
    {
      key: 'patientConsent',
      dataIndex: 'patientConsent',
      title: t.patient_operations_table_column_consent_status_text,
      render: (consent) => {
        const count = consent.filter((el) => el?.status === 'pending').length;

        return (
          <>
            <Tag
              color={
                (consent.status == 'unapproved' && '#f5222d') ||
                (consent.status == 'pending' && '#F9A602') ||
                (consent.status == 'approved' && '#299617')
              }
            >
              <Tooltip title={t.patient_list_tip_operations_text}>
                <span>{`${count} ${t.patient_list_pending_consent_text}`}</span>
              </Tooltip>
            </Tag>
          </>
        );
      }
    }
  ];

  useEffect(() => {
    const data = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(data);
  }, [filterColumns]);

  useEffect(() => {
    handleGetPatient();
  }, [currentPage]);

  // const pagination = patients.length < 8 ? false : { pageSize: 8 };
  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems
    // pageSizeOptions: ["10", "20", "30", "50", "100"],
    // showSizeChanger: true,
    // showTotal: total => `总条数: ${total}   `,
    // size: "small"
  };
  const selectedRowKeys = {
    selectedRowKeys: patients
      .filter((item) => {
        if (defaultSelectedRows.length > 0) {
          return defaultSelectedRows.filter((element) => item.id === element.id);
        } else return '';
      })
      .map((item, index) => {
        return index;
      })
  };

  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleGetPatient, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  //kullanıcı yazmayı bitirdiğinde çalışacak fonksiyon
  const handleGetPatient = () => {
    // page=1&limit=2&sortBy=createdAt:DESC&filter.kvkk=1&filter.gdprStatus=306
    if (filterText.length > 2 || filterText.length < 1) {
      const params = { search: filterText, page: currentPage, limit: 10 };
      GetAllPatientService(params)
        .then((res) => {
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
          setPatients(res.data);
          openNotification(
            'bottomRight',
            'success',
            t.notification_successful_patient_list_get,
            ''
          );
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_patient_list_get,
            ''
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {contextHolder}

      <div style={{ display: 'flex' }}>
        <div style={{ maxWidth: 200, margin: '0 0 12px 0' }}>
          <Modal
            title={<h3>{t.general_column_filter_text}</h3>}
            visible={visible}
            footer={null}
            onCancel={onClose}
            destroyOnClose
          >
            {columns.map((column) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ width: 100 }}>{column.title}</span>
                  <input
                    type='checkbox'
                    name={column.key}
                    checked={filterColumns[column.key]}
                    onChange={() =>
                      setFilterColumns((state) => ({
                        ...state,
                        [column.key]: !filterColumns[column.key]
                      }))
                    }
                  />
                </div>
              );
            })}
          </Modal>
          <Button type='primary' onClick={() => setVisible(true)}>
            {t.general_table_column_text}
          </Button>
        </div>
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Tooltip title={t.general_search_tip_text}>
            <Input
              type='text'
              id='searchInput'
              placeholder={t.general_search_text}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </Tooltip>
        </div>
      </div>
      <Table
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={patients}
        columns={filteredColumns}
        loading={loading}
      />
    </>
  );
};

export default PatientsTable;
function dispatch(arg0: { type: string; payload: boolean }) {
  throw new Error('Function not implemented.');
}
