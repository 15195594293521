import { useContext, useEffect, useState } from 'react';

import { Button, Input, Modal, notification, Table, Tag, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { NotificationPlacement } from 'antd/lib/notification';

import moment from 'moment';

import GetPromotionCodeByIdService from '../../../api/promotion-code/get-promotion-code-by-id';

import { LanguageContext, translations } from '../../../translation';

export default function GetPromotion({ promotion }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const closeModal = () => {
    setSearch('');
    setVisible(false);
  };
  const openModal = () => {
    getTableData();
    setVisible(true);
  };
  const [search, setSearch] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [api, contextHolder] = notification.useNotification();

  let typingTimer;

  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(searchTableData, 500);
  };

  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  const searchTableData = () => {
    if (search.length < 3 && search.length > 0) {
      return;
    }
    const params = {
      search: search,
      relations: ['promotion'],
      limit: 10
    };
    GetPromotionCodeByIdService(promotion.id, params)
      .then((res) => {
        setData(res.data);
        setItemsPerPage(res?.meta?.itemsPerPage);
        setTotalItems(res?.meta?.totalItems);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_get_promotions,
          ''
        );
      });
  };

  const getTableData = () => {
    const params = {
      search: search,
      relations: ['promotion'],
      page: currentPage,
      limit: 10
    };
    GetPromotionCodeByIdService(promotion.id, params)
      .then((res) => {
        setData(res.data);
        setItemsPerPage(res?.meta?.itemsPerPage);
        setTotalItems(res?.meta?.totalItems);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_get_promotions,
          ''
        );
      });
  };

  useEffect(() => {
    const params = {
      search: search,
      page: currentPage,
      limit: 10
    };
    GetPromotionCodeByIdService(promotion.id, params)
      .then((res) => {
        setData(res.data);
        setItemsPerPage(res?.meta?.itemsPerPage);
        setTotalItems(res?.meta?.totalItems);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_get_promotions,
          ''
        );
      });
  }, [promotion]);

  const columns = [
    {
      key: 'code',
      dataIndex: 'code',
      title: t.promo_codes_codes_popup_table_column_code_text,
      filteredValue: [search],
      render: (code) => <strong>{code}</strong>
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t.promo_codes_codes_popup_table_column_creation_date_text,
      render: (createdAt) => <strong>{moment(createdAt).format('DD/MM/YYYY')}</strong>
    },
    {
      key: 'isUsed',
      dataIndex: 'isUsed',
      title: t.promo_codes_codes_popup_table_column_code_status_text,
      render: (isUsed) => (
        <Tag color={isUsed ? '#f5222d' : '#299617'}>
          {isUsed ? t.promo_codes_codes_popup_used_text : t.promo_codes_codes_popup_unused_text}
        </Tag>
      )
    },
    {
      key: 'user',
      dataIndex: 'user',
      title: t.promo_codes_codes_popup_table_column_email_text,
      render: (user) => <strong>{user?.email ? user?.email : '-'}</strong>
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: t.promo_codes_codes_popup_table_column_usage_date_text,
      render: (updatedAt) => <strong>{moment(updatedAt).format('DD/MM/YYYY')}</strong>
    },
    {
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      title: t.promo_codes_codes_popup_table_column_expiry_date_text,
      render: (finishedAt) => <strong>{moment(finishedAt).format('DD/MM/YYYY')}</strong>
    },
    {
      key: 'isFinished',
      dataIndex: 'isFinished',
      title: t.promo_codes_codes_popup_table_column_status_text,
      render: (isFinished) => (
        <Tag color={isFinished ? '#f5222d' : '#299617'}>
          {isFinished
            ? t.promo_codes_codes_popup_expired_text
            : t.promo_codes_codes_popup_active_text}
        </Tag>
      )
    }
  ];

  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };

  return (
    <div>
      <Tooltip title={t.promo_codes_tips_view_text}>
        <Button style={{ border: 'none' }} shape='circle' onClick={openModal} type={'primary'}>
          <EyeOutlined />
        </Button>
      </Tooltip>
      <Modal
        width={'80%'}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<span style={{ fontSize: '1.5em' }}>{t.promo_codes_codes_popup_title}</span>}
      >
        <Tooltip title={t.general_search_tip_text}>
          <Input
            placeholder={t.general_search_text}
            style={{ width: '300px', marginBottom: '10px' }}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Tooltip>

        <Table
          columns={columns}
          onChange={(pagination) => {
            setCurrentPage(pagination.current);
          }}
          pagination={pagination}
          dataSource={data}
          className='accent-header'
          rowKey='id'
        />
      </Modal>
    </div>
  );
}
