/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Avatar, Button, Card, Rate } from 'antd';
import { usePageData } from '../../hooks';
import { IPageData } from '../../interfaces';

const pageData: IPageData = {
  title: 'Profilim',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Apps',
      route: 'default-dashboard'
    },
    {
      title: 'Service pages',
      route: 'default-dashboard'
    },
    {
      title: 'User profile'
    }
  ]
};

// or const [t, i18n] = useTranslation();

const UserPreview = () => {
  //   const [bool, setBool] = useState(false);
  //   useEffect(() => {
  //     i18n.changeLanguage(bool ? 'tr' : 'en');
  //   }, [bool]);

  // const { t } = useTranslation();
  const userCover = `${window.origin}/content/user-profile.jpg`;
  const userAvatar = `${window.origin}/content/user-400-1.jpg`;

  return (
    <Card
      cover={<img src={userCover} alt='user-cover' />}
      className='personal-info-card with-shadow'
    >
      <div
        className='d-flex align-items-center justify-content-between'
        style={{ marginTop: '-50px' }}
      >
        <Avatar src={userAvatar} size={100} />

        <Button
          style={{
            backgroundColor: '#e91e63',
            borderRadius: 500,
            color: '#fff',
            border: 'none'
          }}
        >
          Subscribe
        </Button>
      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <h5 className='mb-0 mt-0 mr-1'>Liam Jouns</h5>

        <Rate value={4} />
      </div>

      {/* <p style={{ color: '#8f8f90' }}>{t('ui/ux-designer')}</p> */}

      {/* <p
        onClick={() => {
          setBool(!bool);
        }}
      >
        {t('hello')}
      </p> */}
    </Card>
  );
};

const ContactInfo = () => {
  return (
    <Card title='Contact information' className='mb-md-0 with-shadow'>
      <div className='row align-items-center mb-3'>
        <div className='col col-auto'>
          <span className='icofont-ui-touch-phone' style={{ fontSize: 30, color: '#8f8f90' }} />
        </div>
        <div className='col'>
          <div>Mobile</div>
          0126596578
        </div>
      </div>

      <div className='row align-items-center mb-3'>
        <div className='col col-auto'>
          <span className='icofont-slack' style={{ fontSize: 30, color: '#8f8f90' }} />
        </div>
        <div className='col'>
          <div>Slack</div>
          @liam.joun
        </div>
      </div>

      <div className='row align-items-center mb-3'>
        <div className='col col-auto'>
          <span className='icofont-skype' style={{ fontSize: 30, color: '#8f8f90' }} />
        </div>
        <div className='col'>
          <div>Skype</div>
          liam0jouns
        </div>
      </div>

      <div className='row align-items-center mb-3'>
        <div className='col col-auto'>
          <span className='icofont-location-pin' style={{ fontSize: 30, color: '#8f8f90' }} />
        </div>
        <div className='col'>
          <div>Current Address</div>
          71 Pilgrim Avenue Chevy Chase, MD 20815
        </div>
      </div>
    </Card>
  );
};

const UserProfilePage = () => {
  usePageData(pageData);

  return (
    <div className='row'>
      <div className='col-md-6 col-sm-12'>
        <UserPreview />
        <ContactInfo />
      </div>
    </div>
  );
};

export default UserProfilePage;
