import { postCall } from '../request';

const PostPatientGDPRByIdVerifyService = async (id, data) => {
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/patient-gdpr/${id}/verify`,
    data: data
  });
};

export default PostPatientGDPRByIdVerifyService;
