import { getCall } from '../request';

const GetConsentGroupOnlyName= () => {
  const access_token = localStorage.getItem('token');
  return getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/consent-group/only-name`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default GetConsentGroupOnlyName;