import { getCall } from '../request';

const GetMailVerifyInvitationService = async (verify_token) => {
  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/mail/verify/invitation`,
    headers: {
      Authorization: `Bearer ${verify_token}`
    }
  });
};

export default GetMailVerifyInvitationService;
