/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Form, Input, notification, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import PublicLayout from '../../../layout/public/Public';

import PostMailResetVerifyService from '../../../api/mail/post-mail-reset-verify';

import { LanguageContext, translations } from '../../../translation';
import { PASSWORD_REGEX } from '../../../regex';
const { Item } = Form;

const ForgotPassword = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { token }: any = useParams();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    password: '',
    rePassword: ''
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (param, value) => {
    setFormValues((prevState) => ({ ...prevState, [param]: value }));
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    PostMailResetVerifyService(values, token)
      .then(() => {
        form.setFieldsValue({
          password: '',
          rePassword: ''
        });
        openNotification('bottomRight', 'success', t.notification_successful_password_reset, '');
        setTimeout(() => {
          window.location.href = '/public/sign-in';
        }, 2000);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_password_reset,
          ''
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
        <h4 className='mt-0 mb-1'>{t.forgot_password_title}</h4>
        <p className='text-color-200'>{t.forgot_password_new_title}</p>
        <Form onFinish={onFinish} form={form} layout='vertical' className='mb-4'>
          <div className='row'>
            <div className='col-md-10 col-sm-12'>
              <Item
                extra={t.input_error_invalid_password}
                name='password'
                rules={[
                  { required: true, message: t.input_error_invalid_password },
                  {
                    pattern: PASSWORD_REGEX,
                    message: t.input_error_invalid_password
                  }
                ]}
              >
                <Input.Password
                  onChange={(e) => handleFormValues('password', e.target.value)}
                  type='password'
                  value={formValues.password}
                  placeholder={t.profile_new_password_text}
                />
              </Item>
            </div>

            <div className='col-md-10 col-sm-12'>
              <Item
                name='rePassword'
                rules={[
                  {
                    required: true,
                    message: t.input_error_missing_newpassword
                  },
                  {
                    pattern: PASSWORD_REGEX,
                    message: t.input_error_invalid_password
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t.input_error_not_matching_password);
                    }
                  })
                ]}
              >
                <Input.Password
                  onChange={(e) => handleFormValues('rePassword', e.target.value)}
                  placeholder={t.profile_new_password_again_text}
                  type='password'
                  value={formValues.rePassword}
                  onError={() => formValues.password !== formValues.rePassword}
                />
              </Item>
            </div>
          </div>

          <Button
            disabled={isLoading}
            loading={isLoading}
            block={false}
            type='primary'
            htmlType='submit'
          >
            {t.sms_accept_approve_text}
          </Button>
        </Form>
      </PublicLayout>
    </>
  );
};

export default ForgotPassword;
