/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, useHistory } from 'react-router-dom';
import CurrentUserService from '../api/auth/current-user';
import { setUserInfo } from '../redux/user-info/action';

export const ProtectedRoute = ({ access_token, component: Component, ...rest }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    CurrentUserService(access_token)
      .then((res) => dispatch(setUserInfo(res)))
      .catch(() => history.push('/public/sign-in'));
  }, [window.location.href]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (access_token) {
          return <Component {...props} />; // render component
        } else {
          return (
            <Redirect
              to={{
                pathname: '/public/sign-in',
                state: {
                  from: props.location // this is the path that the user was trying to access before they were redirected
                }
              }}
            />
          );
        }
      }}
    />
  );
};
