import { getCall } from '../request';

const GetNewsPublicService = async () => {
  const access_token = localStorage.getItem('token');
  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/news/public`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};
export default GetNewsPublicService;
