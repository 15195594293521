import { getCall, patchCall } from '../request';

const PatchPatientService = async (data, patientId) => {
  const access_token = localStorage.getItem('token');
  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
  return await patchCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/patient/${patientId}`,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: `Bearer ${access_token}`
    },
    data
  });
};

export default PatchPatientService;
