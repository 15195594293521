/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';
import { setUserInfo } from '../../../redux/user-info/action';

import { Button, Input, notification } from 'antd';
import Checkbox from 'antd/es/checkbox';
import { useForm } from 'antd/es/form/Form';
import { Form } from 'antd';
import { NotificationPlacement } from 'antd/es/notification';

import PostUserAddressService from '../../../api/user-address/post-user-address';
import CurrentUserService from '../../../api/auth/current-user';

import { PhoneFormat } from '../phoneFormat/PhoneFormat';
import { IUserInfo } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';
import { EMAIL_REGEX, NAME_REGEX } from '../../../regex';

const BillingForm = ({ onCancel }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [form] = useForm();
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);

  const [formValues, setFormValues] = useState({
    title: '',
    firstName: '',
    lastName: '',
    cityName: '',
    countyName: '',
    address: '',
    email: '',
    gsm: '',
    companyName: '',
    taxAdministration: '',
    taxNumber: '',
    userId: '',
    isCorporate: false,
    hasEInvoice: false
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    const data = { ...formValues, userId: userInfo?.id };
    const access_token = localStorage.getItem('token');

    setLoading(true);
    PostUserAddressService(data)
      .then(() => {
        CurrentUserService(access_token).then((res) => {
          dispatch(setUserInfo(res));
        });
        setFormValues((prev) => ({ ...prev }));
        openNotification('bottomRight', 'success', t.notification_successful_billing_info_add, '');
        dispatch(setDataUpdated(!updated));
        setTimeout(() => {
          onCancel();
        }, 3000);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_billing_info_add,
          ''
        );
      })
      .finally(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout={'vertical'}>
        <h3>{t.settings_invoice_text}</h3>

        <div className='form-group'>
          <Form.Item
            rules={[{ required: true, message: t.input_error_missing_address_name }]}
            label={t.billing_information_address_title_text}
            name={'title'}
          >
            <Input
              name={'title'}
              placeholder={t.billing_information_address_title_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.title}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'firstName'}
            rules={[
              { required: true, message: t.input_error_invalid_name },
              {
                pattern: NAME_REGEX,
                message: t.input_error_invalid_name
              }
            ]}
            label={t.billing_information_address_name_text}
          >
            <Input
              name={'firstName'}
              placeholder={t.billing_information_address_name_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.firstName}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'lastName'}
            rules={[{ required: true, message: t.input_error_invalid_name }]}
            label={t.billing_information_address_surname_text}
          >
            <Input
              name={'lastName'}
              placeholder={t.billing_information_address_surname_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.lastName}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'cityName'}
            rules={[{ required: true, message: t.input_error_missing_address_city }]}
            label={t.billing_information_address_city_text}
          >
            <Input
              name={'cityName'}
              placeholder={t.billing_information_address_city_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.cityName}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'countyName'}
            rules={[{ required: true, message: t.input_error_missing_address_county }]}
            label={t.billing_information_address_county_text}
          >
            <Input
              name={'countyName'}
              placeholder={t.billing_information_address_county_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.countyName}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'address'}
            rules={[{ required: true, message: t.input_error_missing_address }]}
            label={t.billing_information_address_address_text}
          >
            <Input
              name={'address'}
              placeholder={t.billing_information_address_address_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.address}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'email'}
            rules={[
              { required: true, message: t.input_error_invalid_email },
              {
                pattern: EMAIL_REGEX,
                message: t.input_error_invalid_email
              }
            ]}
            label={t.billing_information_address_email_text}
          >
            <Input
              name={'email'}
              placeholder={t.billing_information_address_email_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.email}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.billing_information_address_gsm_text}
            name={'gsm'}
            rules={[
              {
                required: formValues.gsm.length < 10,
                message: t.input_error_invalid_gsm
              }
            ]}
          >
            <input
              pattern='^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}$'
              style={{ display: 'none' }}
              type='text'
              name='gsm'
              value={PhoneFormat(formValues.gsm)}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  [e.target.name]: PhoneFormat(e.target.value).replace(/[ ]*[(]*[)]*/g, '')
                }))
              }
            />
            <Input
              pattern='^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}$'
              name={'gsm'}
              placeholder={t.billing_information_address_gsm_text}
              type='text'
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  [e.target.name]: PhoneFormat(e.target.value).replace(/[ ]*[(]*[)]*/g, '')
                }))
              }
              value={PhoneFormat(formValues.gsm)}
              maxLength={15}
              minLength={10}
            />
          </Form.Item>
        </div>

        <Checkbox
          name='isCorporate'
          onChange={(e) =>
            setFormValues((state) => ({ ...state, [e.target.name]: e.target.checked }))
          }
          checked={formValues.isCorporate}
        />
        <label style={{ marginLeft: 8 }}>
          {t.billing_information_address_corporate_address_text}
        </label>
        <div className='form-group'>
          <Form.Item
            name={'companyName'}
            rules={[
              { required: formValues.isCorporate, message: t.input_error_missing_corporate_name }
            ]}
            label={t.billing_information_address_corporate_name_text}
          >
            <Input
              name={'companyName'}
              placeholder={t.billing_information_address_corporate_name_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.companyName}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'taxAdministration'}
            rules={[
              { required: formValues.isCorporate, message: t.input_error_missing_tax_registry }
            ]}
            label={t.billing_information_address_tax_office_text}
          >
            <Input
              name={'taxAdministration'}
              placeholder={t.billing_information_address_tax_office_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.taxAdministration}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'taxNumber'}
            rules={[
              {
                required: formValues.isCorporate,
                message: t.input_error_missing_tax_number,
                pattern: /^[0][1-9]\d{10}$|^[1-9]\d{10}$/
              }
            ]}
            label={t.billing_information_address_tax_number_text}
          >
            <Input
              name={'taxNumber'}
              placeholder={t.billing_information_address_tax_number_text}
              type='number'
              onChange={handleFormValues}
              value={formValues.taxNumber}
            />
          </Form.Item>
        </div>
        <Checkbox
          name='hasEInvoice'
          onChange={(e) =>
            setFormValues((state) => ({ ...state, [e.target.name]: e.target.checked }))
          }
          checked={formValues.hasEInvoice}
        />
        <label style={{ marginLeft: 8 }}>
          {t.billing_information_address_e_invoice_owner_text}
        </label>

        <div
          style={{ marginTop: 16 }}
          className='d-flex justify-content-between buttons-list settings-actions'
        >
          <Button danger onClick={onCancel}>
            {t.general_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {t.billing_information_save_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default BillingForm;
