import React from 'react';
import { Table } from 'antd';

const columns: any = [
  {
    title: 'Talep ID',
    dataIndex: 'requestid',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.requestid - b.requestid
  },
  {
    title: 'Talep Edilen Onam Formu',
    dataIndex: 'requestform'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    filters: [
      // {
      //   text: 'Joe',
      //   value: 'Joe'
      // },
      // {
      //   text: 'Jim',
      //   value: 'Jim'
      // },
      // {
      //   text: 'Submenu',
      //   value: 'Submenu',
      //   children: [
      //     {
      //       text: 'Green',
      //       value: 'Green'
      //     },
      //     {
      //       text: 'Black',
      //       value: 'Black'
      //     }
      //   ]
      // }
    ],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.name.indexOf(value) === 0,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Age',
    dataIndex: 'age',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.age - b.age
  },
  {
    title: 'Address',
    dataIndex: 'address',
    filters: [
      {
        text: 'London',
        value: 'London'
      },
      {
        text: 'New York',
        value: 'New York'
      }
    ],
    filterMultiple: false,
    onFilter: (value, record) => record.address.indexOf(value) === 0,
    sorter: (a, b) => a.address.length - b.address.length,
    sortDirections: ['descend', 'ascend']
  }
];

const data = [
  {
    key: '1',
    requestid: 121,
    requestform: 'Kızamık Aşısı',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park'
  }
];

const FilterAndSorting = () => {
  return <Table pagination={false} columns={columns} dataSource={data} />;
};

export default FilterAndSorting;
