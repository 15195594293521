import { ISponsor } from '../../interfaces';

export const SET_SPONSORS = '[sponsors] Set';
export const ADD_SPONSOR = '[sponsors] Add';
export const EDIT_SPONSOR = '[sponsors] Edit';
export const DELETE_SPONSOR = '[sponsors] Delete';

export interface SetSponsorAction {
  type: typeof SET_SPONSORS;
  payload: ISponsor[];
}

export interface AddSponsorAction {
  type: typeof ADD_SPONSOR;
  payload: ISponsor;
}

export interface EditSponsorAction {
  type: typeof EDIT_SPONSOR;
  payload: ISponsor;
}

export interface DeleteSponsorAction {
  type: typeof DELETE_SPONSOR;
  id: string;
}

export type SponsorsActions =
  | SetSponsorAction
  | AddSponsorAction
  | DeleteSponsorAction
  | EditSponsorAction;
