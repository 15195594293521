import { getCall } from '../request';

const GetConsentByIdService = (id) => {
  const access_token = localStorage.getItem('token');
  return getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/consent-form/${id}?relations[]=file`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default GetConsentByIdService;
