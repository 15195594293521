/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Form, Input, Modal, notification, Tag } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import { useForm } from 'antd/lib/form/Form';

import PostPatientGDPRDoctorVerifyService from '../../../api/patient-gdpr/post-patient-gdpr-doctor-verify';
import PostPatientGDPRResendService from '../../../api/patient-gdpr/post-patient-gdpr-resend';

import statusCode from '../../../utils/statusCode';

import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IKvkkSend {
  patient: IPatient | any;
}
export default function GdprSmsAccept({ patient }: IKvkkSend) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { gdprStatus, id } = patient;
  const [visible, setVisible] = useState(false);
  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);
  const [smsCode, setSmsCode] = useState('');
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [form] = useForm();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const sendSms = () => {
    PostPatientGDPRResendService({ patientId: id })
      .then(() => {
        openNotification('bottomRight', 'success', t.notification_successful_gdpr_sent, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_gdpr_sent,
          ''
        );
      });
  };

  const data = {
    id: id,
    patientId: patient?.patientId ?? id,
    code: smsCode
  };
  const acceptCode = () => {
    setIsButtonLoading(true);
    PostPatientGDPRDoctorVerifyService(data)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification('bottomRight', 'success', t.notification_successful_gdpr_approved, '');
        setTimeout(() => {
          closeModal();
        }, 3000);
      })
      .catch((err) => {
        setIsButtonLoading(false);
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_gdpr_approved,
          ''
        );
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      {contextHolder}
      <>
        <Tag
          color={
            (gdprStatus == 'unapproved' && '#f5222d') ||
            (gdprStatus == 'pending' && '#F9A602') ||
            (gdprStatus == 'approved' && '#299617')
          }
          style={{ cursor: 'pointer' }}
        >
          <span onClick={openModal}>{t[statusCode(gdprStatus)]}</span>
        </Tag>
      </>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<span style={{ fontSize: '1.5em' }}>{t.sms_accept_gdpr_label}</span>}
      >
        <Form layout='vertical' form={form} onFinish={acceptCode} onFinishFailed={onFinishFailed}>
          <div style={{ margin: '24px 0 16px 0' }}>
            <Form.Item
              rules={[{ required: true, message: t.input_error_missing_otp_code }]}
              label={t.sms_accept_code_text}
              name={'smsCode'}
            >
              <Input
                placeholder={t.input_error_missing_otp_code}
                onChange={(e) => setSmsCode(e.target.value)}
                maxLength={6}
                value={smsCode}
              />
            </Form.Item>
            <p style={{ fontSize: 11, margin: '8px 0 0 8px' }}>
              {t.sms_accept_send_again_question}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span
              style={{
                marginLeft: 8,
                cursor: 'pointer',
                color: '#096dd9',
                textDecoration: 'underline'
              }}
              onClick={sendSms}
            >
              {t.sms_accept_send_again_text}
            </span>
            <Button loading={isButtonLoading} htmlType='submit'>
              {t.sms_accept_approve_text}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
