/* eslint-disable react-hooks/exhaustive-deps */
import { Carousel } from 'antd';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import styled from '@emotion/styled';

interface ICarouselComponent {
  dataCount: number;
  children: ReactNode;
}
const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    height: 3px;
    margin-top: 32px;
    background: gray;
  }
  > .slick-dots li.slick-active button {
    margin-top: 32px;
    background: black;
  }
`;
const CarouselComponent = ({ children, dataCount }: ICarouselComponent) => {
  const carouselWidth = useRef();
  const [carouselNumber, setCarouselNumber] = useState(window.innerWidth < 800 ? 1 : window.innerWidth < 1400 ? 2 : 3);

  const options = {
    autoplaySpeed: 5000,
    speed: 2000,
    autoplay: true,
    draggable: true,
    slidesToScroll: 1,
    // centerMode: true,
    centerPadding: '0px'
  };

  //ekran büyüklüğü 415 den büyükse 3 haber göster küçükse 1 haber göster
  //3 ve üzeri haberde 3 carousel gösterilsin. 3 den az haberde haber sayısı kadar fakat ekran 415 den küçükse her zaman 1 haber.
  //güncellemem gereken slidestoshow özelliği => carouselnumber
  //setcarouselsize ekranın değişip değişmediğini takip ediyor. ekran boyutu değiştiğinde haber sayısı değişebilir
  // haber sayısı değişirse gösterilecek haber değişebilir

  const setCarouselSize = () => {
    if (carouselWidth && carouselWidth.current) {
      if (window.innerWidth < 800) {
        setCarouselNumber(1)
      } else if (window.innerWidth < 1400) {
        setCarouselNumber(dataCount < 2 ? dataCount : 2)
      } else {
        setCarouselNumber(dataCount < 3 ? dataCount : 3)
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setCarouselSize, 1000));
    setCarouselSize();
    return () => {
      window.removeEventListener('resize', throttle(setCarouselSize, 1000));
    };
  }, [dataCount]);

  return (
    <div ref={carouselWidth}>
      <CarouselWrapper {...options} slidesToShow={carouselNumber}>
        {children}
      </CarouselWrapper>
    </div>
  );
};

export default CarouselComponent;
