import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../redux/settings/actions';

import LogoSvg from './../../assets/img/logo.svg';
import lawyer from '../../assets/data/menu-lawyer.json';
import admin from '../../assets/data/menu.json';
import user from '../../assets/data/menu-user.json';
import corporate from '../../assets/data/menu-corporate.json';
import secretary from '../../assets/data/menu-secretary.json';
import corporateSecretary from '../../assets/data/menu-corporate.secretary.json';
import BaseLayout from '../base/BaseLayout';
import Navbar from '../components/navbar/Navbar';
import Menu from '../components/menu/Menu';
import NavLoader from '../components/navbar/NavLoader';
import Actions from '../components/actions/Actions';
import AddPatient from '../components/patients/AddPatient';

import { IAppState, IUserInfo } from '../../interfaces';
import { LanguageContext, translations } from '../../translation';

import './Vertical.scss';

type Props = {
  children: any;
};

const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state: IAppState) => state.settings);
  const pageData = useSelector((state: IAppState) => state.pageData);
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const onSidebarToggle = () => dispatch(toggleSidebar());
  const [menuData, setMenuData] = useState([]);
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);

  useEffect(() => {
    async function fetchMenuData() {
      switch (userInfo.role) {
        case 'user':
          setMenuData(user);
          break;
        case 'admin':
          setMenuData(admin);
          break;
        case 'lawyer':
          setMenuData(lawyer);
          break;
        case 'secretary':
          if (userInfo.isCorporate) {
            setMenuData(corporateSecretary);
          } else {
            setMenuData(secretary);
          }
          break;
        case 'corporate':
          setMenuData(corporate);
          break;
        default:
          setMenuData([]);
          break;
      }
    }
    fetchMenuData().catch((err) => console.log('Server Error', err));
  }, [userInfo]);

  const nav = (
    <Navbar
      boxed={settings.boxed}
      color={settings.topbarColor}
      background={settings.topbarBg}
      orientation='horizontal'
    >
      <button className='no-style navbar-toggle d-lg-none' onClick={onSidebarToggle}>
        <span />
        <span />
        <span />
      </button>

      {/* <Search layout='vertical' data={searchData} /> */}

      <Actions />

      <NavLoader loaded={pageData.loaded} type={'top-bar'} />
    </Navbar>
  );

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      <div className='medi-onam-logo'>
        <img src={LogoSvg} alt='' />
      </div>

      {userInfo?.sponsorId ? (
        <div
          style={{
            textAlign: 'center',
            color: '#8c8c8c',
            fontSize: 13,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <p>{t.sidebar_sponsored_text}</p>
          <img
            style={{ height: 70, maxHeight: 70, maxWidth: 120 }}
            src={userInfo?.sponsor?.logo}
            alt=''
          />
        </div>
      ) : (
        <></>
      )}

      <Menu
        onCloseSidebar={onSidebarToggle}
        opened={settings.sidebarOpened}
        orientation='vertical'
        data={menuData}
      />
      <AddPatient componentType='add' />
      <Menu className='assistant-menu' orientation='vertical'></Menu>
      <NavLoader loaded={pageData.loaded} type={'nav-bar'} />
    </Navbar>
  );

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav}>
        {children}
      </BaseLayout>
    </>
  );
};

export default VerticalLayout;
