/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Table, Tag, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';

import GetSurrogateService from '../../../api/surrogate/get-surrogate';

import statusCode from '../../../utils/statusCode';
import UpdateSurrogateForm from './UpdateSurrogateForm';
import { DisplayPhoneNumber } from '../phoneFormat/PhoneFormat';

import { IPatient, ISurrogate } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

import styles from './SurrogatesTable.module.css';

type Props = {
  patient: IPatient;
  onEditSurrogate?: (surrogate: ISurrogate) => void;
  onDeleteSurrogate?: (id: string) => void;
  slideIndex: number;
  setSlideIndex: any;
};

const SurrogatesTable = ({ patient, slideIndex, setSlideIndex }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const updated = useSelector((state: any) => state.updated);
  const [loading, setLoading] = useState(false);
  const [surrogates, setSurrogates] = useState([]);
  const [surrogate, setSurrogate] = useState<ISurrogate>({
    id: '',
    firstName: '',
    lastName: '',
    tckn: '',
    gsm: '',
    email: '',
    kvkk: '',
    isForeign: false
  });

  const actions = (surrogate: ISurrogate) => (
    <Tooltip title={t.patient_operations_update_surrogate_popup_title}>
      <Button
        type={'primary'}
        shape='circle'
        onClick={() => {
          setSurrogate(surrogate);
          setSlideIndex(1);
        }}
      >
        <span className={'icofont icofont-edit-alt'} />
      </Button>
    </Tooltip>
  );

  useEffect(() => {
    setLoading(true);
    GetSurrogateService(patient.id)
      .then((res) => {
        setSurrogates(res);
      })
      .finally(() => setLoading(false));
  }, [updated]);

  const columns: ColumnProps<ISurrogate>[] = [
    {
      key: 'firstName',
      dataIndex: 'firstName',
      title: t.patient_operations_surrogate_list_popup_table_column_name_text,
      render: (firstName) => <strong>{firstName}</strong>
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      title: t.patient_operations_surrogate_list_popup_table_column_surname_text,

      render: (lastName) => <strong>{lastName}</strong>
    },
    {
      key: 'tckn',
      dataIndex: 'tckn',
      title: t.patient_operations_surrogate_list_popup_table_column_id_text,

      render: (tckn) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {tckn}
        </span>
      )
    },
    {
      key: 'gsm',
      dataIndex: 'gsm',
      title: t.patient_operations_surrogate_list_popup_table_column_gsm_text,
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {DisplayPhoneNumber(phone)}
        </span>
      )
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t.patient_operations_surrogate_list_popup_table_column_email_text,
      render: (email) => <span style={{ display: 'block' }}>{email}</span>
    },
    {
      key: 'gdprStatus',
      dataIndex: 'gdprStatus',
      title: t.patient_operations_surrogate_list_popup_table_column_gdpr_status_text,
      render: (gdprStatus) => (
        <Tag
          style={{ borderRadius: 20 }}
          color={
            (gdprStatus === 'unapproved' && '#f5222d') ||
            (gdprStatus === 'pending' && '#F9A602') ||
            (gdprStatus === 'approved' && '#299617')
          }
        >
          {t[statusCode(gdprStatus)]}
        </Tag>
      )
    },
    {
      key: 'actions',
      title: t.patient_operations_surrogate_list_popup_table_column_operations_text,
      render: actions
    }
  ];

  const pagination = surrogates.length < 8 ? false : { pageSize: 8 };

  return (
    <div className='add-patient'>
      {/* <Tooltip title={'Vekil Listesi'}>
        <Button
          onClick={handleClick}
          shape='circle'
          type='primary'
          style={{ background: '#ff7a45', borderColor: '#ff7a45' }}
        >
          <span className='icofont-file-text' />
        </Button>
      </Tooltip>
      <Modal
        style={{ transition: 'ease-in-out 1s' }}
        transitionName={''}
        maskTransitionName=''
        width={slideIndex === 0 ? '70%' : 640}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>{slideIndex === 0 ? 'Vekil Listesi' : 'Vekil Güncelle'}</h3>}
      > */}
      <div className={styles.slider_container}>
        <div
          className={styles.slider_items_wrapper}
          style={{ right: slideIndex === 0 ? slideIndex : 'calc(100vw / 100 * 70)' }}
        >
          <div className={styles.item}>
            <Table
              pagination={pagination}
              className='accent-header'
              rowKey='id'
              dataSource={surrogates}
              columns={columns}
              loading={loading}
            />
          </div>
          <div className={styles.item}>
            <div className={styles.form}>
              <UpdateSurrogateForm
                patient={patient}
                surrogate={surrogate}
                onCancel={() => setSlideIndex(0)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </Modal> */}
    </div>
  );
};

export default SurrogatesTable;
