import { postCall } from '../request';

const PostPatientConsentIdVerifyService = async (id, data) => {
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/patient-consent/${id}/verify`,
    data: data
  });
};

export default PostPatientConsentIdVerifyService;
