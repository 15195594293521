import { deleteCall } from '../request';

const DeleteSponsorService = async (sponsorId) => {
  const access_token = localStorage.getItem('token');

  return await deleteCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/sponsor/${sponsorId}`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default DeleteSponsorService;
