import { CheckCircleOutlined } from '@ant-design/icons';

export default function Successful() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div>
        <CheckCircleOutlined style={{ fontSize: '5vw', color: 'green' }} />
      </div>
      <h3>Ödeme başarılı!</h3>
    </div>
  );
}
