import { IRoute } from '../interfaces';
import SettingsPage from '../pages/settings/Settings';

import UserProfilePage from '../pages/services/UserProfilePage';
import EditAccountPage from '../pages/services/EditAccounPage';

import KvkkForms from '../pages/dashboards/gdprForms/GdprForms';
import ConsentForms from '../pages/dashboards/consent/ConsentForms';
import KvkkFormUpload from '../pages/forms/GdprFormUpload';
import ConsentFormUpload from '../pages/forms/ConsentFormUpload';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';
import EditInvoicePage from '../pages/services/EditInvoicePage';

export const lawyerRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'main-page',
    component: DashboardPage
  },

  { path: 'consent-list', component: ConsentForms },
  { path: 'gdpr-upload', component: KvkkFormUpload },
  { path: 'consent-upload', component: ConsentFormUpload },
  { path: 'gdpr-list', component: KvkkForms },

  {
    path: 'user-profile',
    component: UserProfilePage
  },
  {
    path: 'edit-account',
    component: EditAccountPage
  },
  {
    path: 'invoice-information',
    component: EditInvoicePage
  }
];
