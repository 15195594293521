import {
  ADD_SPONSOR,
  AddSponsorAction,
  DELETE_SPONSOR,
  DeleteSponsorAction,
  EDIT_SPONSOR,
  EditSponsorAction,
  SET_SPONSORS,
  SetSponsorAction
} from './types';

import axios from 'axios';
import { ISponsor } from '../../interfaces';

export const setSponsors = (sponsors: ISponsor[]): SetSponsorAction => ({
  type: SET_SPONSORS,
  payload: sponsors
});

export const addSponsor = (sponsor: ISponsor): AddSponsorAction => ({
  type: ADD_SPONSOR,
  payload: sponsor
});

export const deleteSponsor = (id: string): DeleteSponsorAction => ({
  type: DELETE_SPONSOR,
  id
});

export const editSponsor = (sponsor: ISponsor): EditSponsorAction => ({
  type: EDIT_SPONSOR,
  payload: sponsor
});

export const fetchSponsors = (url: string): any => {
  return (dispatch) => {
    axios
      .get<ISponsor[]>(url)
      .then((res) => res.data)
      .then((data) => {
        dispatch(setSponsors(data));
      })
      .catch((err) => console.error('Server connections error'));
  };
};
