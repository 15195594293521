/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';

import UpdateBillingForm from '../../layout/components/billing/UpdateBillingForm';

import { usePageData } from '../../hooks';
import { IPageData } from '../../interfaces';
import { LanguageContext, translations } from '../../translation';

const EditInvoicePage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const pageData: IPageData = {
    title: t.settings_invoice_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.settings_invoice_text
      }
    ]
  };
  usePageData(pageData);
  return (
    <div className='stack' style={{ maxWidth: 420, margin: '0 0' }}>
      <UpdateBillingForm />
    </div>
  );
};

export default EditInvoicePage;
