import { deleteCall, getCall } from '../request';

const DeleteSubtenantByIdService = async (subTenantId) => {
  const access_token = localStorage.getItem('token');

  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });

  return await deleteCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/tenant/sub-tenants/${subTenantId}`,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default DeleteSubtenantByIdService;
