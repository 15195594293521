import { getCall } from '../request';

const GetPackageOnlyNameService = async (params: any = null) => {
  const access_token = localStorage.getItem('token');
  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/package/only-name`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};
export default GetPackageOnlyNameService;
