import { patchCall } from '../request';

const PatchPackageService = async (data, id) => {
  const access_token = localStorage.getItem('token');
  return await patchCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/package/${id}`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    data
  });
};

export default PatchPackageService;
