import React, { useContext, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import PackageForm from './PackageForm';
import UpdatePackageForm from './UpdatePackageForm';
import { LanguageContext, translations } from '../../../translation';

interface IAddPatient {
  consentPackage?: any;
  componentType: 'update' | 'add';
}

const AddPackage = ({ componentType, consentPackage }: IAddPatient) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const handleClick = () => setVisible(!visible);
  const closeModal = () => setVisible(false);

  return (
    <div className='add-patient'>
      {componentType === 'update' && (
        <Tooltip title={t.purchase_package_update_package_text}>
          <Button htmlType='submit' onClick={handleClick} shape='circle' type='primary'>
            <span className='icofont icofont-edit-alt' />
          </Button>
        </Tooltip>
      )}
      {componentType === 'add' && (
        <Button style={{ marginBottom: 16 }} type='primary' onClick={handleClick} htmlType='submit'>
          <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
          {t.purchase_package_add_package_text}
        </Button>
      )}

      <Modal
        bodyStyle={{ maxHeight: 700, overflowY: 'scroll' }}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={
          componentType === 'add' ? (
            <h3 style={{ margin: '4px 0' }}>{t.purchase_package_add_package_text}</h3>
          ) : (
            <h3 style={{ margin: '4px 0' }}>{t.purchase_package_update_package_text}</h3>
          )
        }
      >
        {componentType === 'update' && (
          <UpdatePackageForm onCancel={closeModal} consentPackage={consentPackage} />
        )}
        {componentType === 'add' && <PackageForm onCancel={closeModal} />}
      </Modal>
    </div>
  );
};

export default AddPackage;
