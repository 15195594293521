/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { AutoComplete, Button, Input, notification, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import GetSponsorOnlyNameService from '../../../api/sponsor/sponsor-only-name';
import PostNewsService from '../../../api/news/post-news';

import { INews } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  onCancel: () => void;
  news?: INews;
};

const NewsForm = ({ onCancel }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    title: '',
    content: '',
    link: '',
    imgUrl: ''
  });

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    GetSponsorOnlyNameService().then((res) => {
      const data = [{ name: 'Public', id: null }, ...res.data].map((el, index) => ({
        value: el.name,
        id: el.id,
        key: index
      }));
      setOptions(data);
    });
  }, []);

  const onFinish = (values: any) => {
    const newValue = { ...values, sponsorId: selectedOption };
    let data;
    if (newValue.sponsorId) {
      data = newValue;
    } else {
      delete values.sponsorId;
      data = values;
    }
    setLoading(true);
    PostNewsService(data)
      .then(() => {
        form.setFieldsValue({
          title: '',
          content: '',
          link: '',
          imgUrl: ''
        });
        setFormValues((prev) => ({ ...prev }));
        openNotification('bottomRight', 'success', t.notification_successful_news_added, '');
        dispatch(setDataUpdated(!updated));
        setTimeout(() => {
          onCancel();
        }, 1000);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_news_added,
          ''
        );
      })
      .finally(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout={'vertical'}>
        <h3>{t.news_add_news_text}</h3>

        <div className='form-group'>
          <Form.Item
            rules={[{ required: true, message: t.input_error_missing_news_name }]}
            label={t.news_add_news_popup_title_text}
            name={'title'}
          >
            <Input
              name={'title'}
              placeholder={t.news_add_news_popup_title_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.title}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'content'}
            rules={[{ required: true, message: t.input_error_missing_news_topic }]}
            label={t.news_add_news_popup_description_text}
          >
            <Input
              name={'content'}
              placeholder={t.news_add_news_popup_description_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.content}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'link'}
            rules={[{ required: true, message: t.input_error_missing_news_link }]}
            label={t.news_add_news_popup_target_text}
          >
            <Input
              name={'link'}
              placeholder={t.news_add_news_popup_target_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.link}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'imgUrl'}
            rules={[{ required: true, message: t.input_error_missing_news_image_link }]}
            label={t.news_add_news_popup_image_url_text}
          >
            <Input
              name={'imgUrl'}
              placeholder={t.news_add_news_popup_image_url_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.imgUrl}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name='sponsorId'
            rules={[{ required: true, message: t.input_error_missing_news_sponsor }]}
            label={t.news_add_news_popup_sponsor_text}
          >
            <AutoComplete
              style={{ width: 250 }}
              options={options}
              placeholder={t.news_add_news_popup_select_sponsor_text}
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={(value, option) => {
                setSelectedOption(option.id);
              }}
            />
          </Form.Item>
        </div>
        <div
          style={{ marginTop: 16 }}
          className='d-flex justify-content-between buttons-list settings-actions'
        >
          <Button danger onClick={onCancel}>
            {t.general_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {t.news_add_news_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NewsForm;
