/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import PatientsTable from './PatientsTable';
import { usePatients, usePageData } from '../../../hooks';
import { IPageData } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

const PatientsPage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const pageData: IPageData = {
    title: t.sidebar_patient_list_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.sidebar_patient_list_text
      }
    ]
  };
  const { editPatient, deletePatient } = usePatients();
  usePageData(pageData);

  return (
    <>
      <PatientsTable onDeletePatient={deletePatient} onEditPatient={editPatient} />
    </>
  );
};

export default PatientsPage;
