/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { AutoComplete, Button, Input, notification, Select, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import PatchPackageService from '../../../api/package/patch-package';
import GetConsentGroupOnlyName from '../../../api/consent-group/consent-group-only-name';

import { LanguageContext, translations } from '../../../translation';

type Props = {
  onCancel: () => void;
  consentPackage?: IValues;
};

interface IValues {
  id: string;
  name: string;
  description: string;
  price: number;
  duration: string;
  isSingle: boolean;
  consentGroups: { id: string; name: string; summary: string }[];
}
interface IOption {
  id: string;
  value: string;
  summary: string;
}

const UpdatePackageForm = ({ onCancel, consentPackage }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    price: 0,
    isSingle: true,
    duration: '',
    consentGroupIds: []
  });
  const [optionsDurations] = useState([
    { value: `1 ${t.purchase_package_month_text}`, id: 'P1M' },
    { value: `3 ${t.purchase_package_month_text}`, id: 'P3M' },
    { value: `6 ${t.purchase_package_month_text}`, id: 'P6M' },
    { value: `9 ${t.purchase_package_month_text}`, id: 'P9M' },
    { value: `1 ${t.purchase_package_year_text}`, id: 'P1Y' }
  ]);
  // const [selectedOptionDuration, setSelectedOptionDuration] = useState({});

  useEffect(() => {
    GetConsentGroupOnlyName()
      .then((res) => {
        const data = res.data.map((el) => ({
          value: el.id,
          label: el.name
        }));
        setOptions(data);
      })
      .catch(() => openNotification('bottomRight', 'error', 'Onam Grupları Bulunamadı', ''));
  }, []);

  useEffect(() => {
    const newOptions = consentPackage.consentGroups.map((el) => ({
      value: el.id,
      label: el.name,
      summary: el.summary
    }));
    setSelectedOption(newOptions);

    form.setFieldsValue({
      name: consentPackage.name,
      description: consentPackage.description,
      price: consentPackage.price,
      isSingle: consentPackage.isSingle,
      duration: optionsDurations.find((el) => el.id === consentPackage.duration).value,
      consentGroupIds: newOptions.map((el) => el.value)
    });
  }, [consentPackage]);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const obj = {
      ...values,
      duration: optionsDurations.find((el) => el.value === values.duration).id,
      consentGroupIds: selectedOption.map((el) => el?.value),
      price: Number(values.price),
      isSingle: selectedOption.length < 2
    };
    PatchPackageService(obj, consentPackage.id)
      .then(() => {
        openNotification(
          'bottomRight',
          'success',
          t.notification_successful_package_form_update,
          ''
        );
        dispatch(setDataUpdated(!updated));
        onCancel();
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_package_form_update,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <div className='row' style={{ maxWidth: '30vw' }}>
        <div className='col-md-19 col-sm-12'>
          <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
            <div className='form-group'>
              <Form.Item
                name={'name'}
                rules={[{ required: true, message: t.input_error_missing_package_name }]}
                label={t.purchase_package_table_column_name_text}
              >
                <Input
                  placeholder={t.purchase_package_table_column_name_text}
                  name={'name'}
                  type='text'
                  onChange={handleFormValues}
                  value={formValues.name}
                />
              </Form.Item>

              <Form.Item
                name={'description'}
                rules={[{ required: true, message: t.input_error_missing_package_description }]}
                label={t.purchase_package_table_column_description_text}
              >
                <Input
                  placeholder={t.purchase_package_table_column_description_text}
                  name={'description'}
                  type='text'
                  onChange={handleFormValues}
                  value={formValues.description}
                />
              </Form.Item>

              <Form.Item
                name={'price'}
                rules={[{ required: true, message: t.input_error_missing_package_price }]}
                label={t.purchase_package_table_column_package_price_text}
              >
                <Input
                  placeholder={t.purchase_package_table_column_package_price_text}
                  name={'price'}
                  type='number'
                  onChange={handleFormValues}
                  value={formValues.price}
                />
              </Form.Item>

              <Form.Item
                name='duration'
                rules={[{ required: true, message: t.input_error_missing_package_duration }]}
                label={t.purchase_package_table_column_package_duration_text}
              >
                <AutoComplete
                  options={optionsDurations}
                  placeholder={t.purchase_package_table_column_package_duration_text}
                  filterOption={(inputValue, option) =>
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={(value, option) => {
                    // setSelectedOptionDuration(option.id);
                  }}
                  value={formValues.duration}
                />
              </Form.Item>
              <Form.Item
                name='consentGroupIds'
                rules={[{ required: false, message: t.input_error_missing_package_consent }]}
                label={t.purchase_package_table_column_consents_text}
              >
                <Select
                  maxTagCount='responsive'
                  mode='multiple'
                  options={options}
                  allowClear
                  placeholder={t.purchase_package_table_column_consents_text}
                  onChange={(_value, option: any) => setSelectedOption(option)}
                />
              </Form.Item>
            </div>
            <div className='d-flex justify-content-between buttons-list settings-actions'>
              <Button danger onClick={onCancel}>
                {t.general_cancel_text}
              </Button>
              <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
                {t.general_update_text}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UpdatePackageForm;
