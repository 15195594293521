/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Spin } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import './styles.css';
import { useParams } from 'react-router-dom';
import throttle from 'lodash/throttle';
import NotFound from '../sessions/404';
import GetPatientGDPRByIdService from '../../api/patient-gdpr/get-patient-gdpr-id';
import PostPatientGDPRByIdVerifyService from '../../api/patient-gdpr/post-patient-gdpr-id-verify';
import GetPatientConsentByIdService from '../../api/patient-consent/get-patient-consent-id';
import PostPatientConsentIdVerifyService from '../../api/patient-consent/post-patient-consent-id-verify';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function GdprAndConsentPdfView() {
  const [state, setState] = useState({
    numPages: null,
    fileUrl: ''
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [loader, setLoader] = useState(true);
  const { code }: any = useParams();
  const [initialWidth, setInitialWidth] = useState(
    window.innerWidth > 415 ? (window.innerWidth / 100) * 50 : (window.innerWidth / 100) * 80
  );
  const pdfWrapper: any = useRef();
  const [isNotFound, setIsNotFound] = useState(false);
  const [data, setData] = useState<any>({});
  const [isVerify, setIsVerify] = useState<any>({});

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      if (window.innerWidth > 415) {
        setInitialWidth((window.innerWidth / 100) * 50);
      } else {
        setInitialWidth((window.innerWidth / 100) * 80);
      }
    }
  };

    const [isReading, setIsReading] = useState(false);
  
    useEffect(() => {
      let utterance;
      if (isReading) {
        // Create a new SpeechSynthesisUtterance object and start reading
        utterance = new SpeechSynthesisUtterance(pdfWrapper.current.innerText);
        utterance.lang = 'tr';
        utterance.addEventListener('end', () => {
          // Set isReading to false when the end event is triggered
          setIsReading(false);
        });
        window.speechSynthesis.speak(utterance);
      } else {
        // Stop reading if the reading state is set to false
        window.speechSynthesis.cancel();
      }
      return () => {
        // Clean up the SpeechSynthesisUtterance object when the component unmounts
        window.speechSynthesis.cancel();
      };
    }, [isReading]);

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 1000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 1000));
    };
  }, []);

  useEffect(() => {
    const url = window.location.href.split('/').filter((el) => el === 'gdpr')[0];
    if (url === 'gdpr') {
      GetPatientGDPRByIdService(code)
        .then((res) => {
          setData({ id: res.id, code: res.code });
          setState((state) => ({ ...state, fileUrl: res?.file?.url }));
          setIsVerify(res.isVerify);
        })
        .catch(() => {
          setIsNotFound(true);
        })
        .finally(() => setLoader(false));
    } else {
      GetPatientConsentByIdService(code)
        .then((res) => {
          setData({ id: res.id, code: res.code });
          setState((state) => ({ ...state, fileUrl: res?.file?.url }));
          setIsVerify(res.isVerify);
        })
        .catch(() => {
          setIsNotFound(true);
        })
        .finally(() => setLoader(false));
    }
  }, []);

  const onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    setState((state) => ({ ...state, numPages: numPages }));
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setIsReading(false);
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const { numPages, fileUrl } = state;

  const handleAccept = () => {
    const url = window.location.href.split('/').filter((el) => el === 'gdpr')[0];
    if (url) {
      PostPatientGDPRByIdVerifyService(data.id, { code: data.code })
        .then((res) => {
          alert('Onaylandı');
          setIsVerify(res.isVerify);
        })
        .catch((err) => err);
    } else {
      PostPatientConsentIdVerifyService(data.id, { code: data.code })
        .then((res) => {
          alert('Onaylandı');
          setIsVerify(res.isVerify);
        })
        .catch((err) => err);
    }
  };
  return (
    <div className={'pdf-page-container'}>
      {loader ? (
        <Spin />
      ) : isNotFound ? (
        <NotFound />
      ) : (
        <>
          <div id='pdfWrapper' className='pdf-wrapper' ref={pdfWrapper}>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} width={initialWidth} />
            </Document>
          </div>

          <div className='pdf-page-button-wrapper'>
            <p>
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </p>
            <Button disabled={pageNumber <= 1} onClick={previousPage}>
              Önceki
            </Button>
            <Button disabled={pageNumber >= numPages} onClick={nextPage}>
              Sonraki
            </Button>
          </div>
          {!isVerify ? (
            <div className='pdf-page-kvkk-text-accept-button'>
              <Button type='primary' onClick={handleAccept}>
                Onayla
              </Button>
            </div>
          ) : (
            <div className='pdf-page-kvkk-text-accept-button'>Belgeniz Onaylanmıştır</div>
          )}
          <Button onClick={() => setIsReading(!isReading)}>
            {isReading ? 'Okumayı Bitir' : 'Okumaya Başla'}
          </Button>
        </>
      )}
    </div>
  );
}
