import React, { useContext, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import PatientForm from './PatientForm';
import UpdatePatientForm from './UpdatePatientForm';
import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IAddPatient {
  patient?: IPatient;
  componentType: 'update' | 'add';
}

const AddPatient = ({ componentType, patient }: IAddPatient) => {
  const [visible, setVisible] = useState(false);
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  return (
    <div className='add-patient'>
      {componentType === 'update' && (
        <Tooltip title={t.patient_operations_operation_tips_update_patient_text}>
          <Button htmlType='submit' onClick={handleClick} shape='circle' type='primary'>
            <span className='icofont icofont-edit-alt' />
          </Button>
        </Tooltip>
      )}
      {componentType === 'add' && (
        <Button style={{ marginBottom: 16 }} type='primary' onClick={handleClick} htmlType='submit'>
          <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
          {t.sidebar_add_patient_text}
        </Button>
      )}

      <Modal
        bodyStyle={{ maxHeight: 700, overflowY: 'scroll' }}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
      >
        {componentType === 'update' && (
          <UpdatePatientForm onCancel={closeModal} patient={patient} />
        )}
        {componentType === 'add' && <PatientForm onCancel={closeModal} />}
      </Modal>
    </div>
  );
};

export default AddPatient;
