import { postCall } from '../request';

const SignUpService = async (data) => {
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/signup`,
    data
  });
};

export default SignUpService;
