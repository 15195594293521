/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import PatientsTable from './PatientsTable';
import { usePageData } from '../../../hooks';
import { IPageData } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

const ConsentProcedures = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const pageData: IPageData = {
    title: t.sidebar_patient_consent_operations_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.sidebar_patient_consent_operations_text
      }
    ]
  };
  usePageData(pageData);
  return (
    <>
      <PatientsTable />
    </>
  );
};

export default ConsentProcedures;
