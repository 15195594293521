import React, { useContext } from 'react';
import SponsorsTable from './SponsorsTable';
import { usePageData, useSponsors } from '../../../hooks';
import { IPageData } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

const SponsorsPage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const pageData: IPageData = {
    title: t.sidebar_sponsors_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.sidebar_sponsors_text
      }
    ]
  };
  usePageData(pageData);
  const { editSponsor, deleteSponsor } = useSponsors();
  return (
    <>
      <SponsorsTable onDeleteSponsor={deleteSponsor} onEditSponsor={editSponsor} />
    </>
  );
};

export default SponsorsPage;
