import { useContext, useEffect, useState } from 'react';
import { LanguageContext, translations } from '../../../translation';
import { notification, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ISubtenantForm, IUserInfo } from '../../../interfaces';
import { NotificationPlacement } from 'antd/lib/notification';
import GetSubtenantsByIdService from '../../../api/subtenant/get-subtenant';
import { ColumnProps } from 'antd/lib/table';
import DeleteSubtenantByIdService from '../../../api/subtenant/delete-subtenant';
import { setDataUpdated } from '../../../redux/data-updated/action';
import DeleteDoctor from './DeleteDoctor';

const DoctorListTable = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [api, contextHolder] = notification.useNotification();
  const updated = useSelector((state: any) => state.updated);
  const dispatch = useDispatch();
  const [doctors, setDoctors] = useState<ISubtenantForm[]>([]);
  const [loading, setLoading] = useState(false);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  useEffect(() => {
    GetSubtenantsByIdService()
      .then((res) => {
        setDoctors(res);
      })
      .catch((err) => {});
  }, [updated]);

  const handleDeleteDoctor = (subTenantId) => {
    setLoading(true);
    DeleteSubtenantByIdService(subTenantId)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification('bottomRight', 'success', t.doctor_list_remove_doctor_notification, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.doctor_list_remove_doctor_error_notification,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actions = (doctor: ISubtenantForm) => (
    <div className='buttons-list nowrap'>
      <DeleteDoctor onAccept={() => handleDeleteDoctor(doctor.id)} />
    </div>
  );

  const columns: ColumnProps<ISubtenantForm>[] = [
    {
      key: 'title',
      dataIndex: 'doctor',
      title: t.doctors_forms_table_column_title_text,
      render: (doctor) => (doctor ? <strong>{doctor.title}</strong> : '-')
    },
    {
      key: 'firstname',
      dataIndex: 'doctor',
      title: t.doctors_forms_table_column_firstname_text,
      render: (doctor) => (doctor ? <strong>{doctor.firstName}</strong> : '-')
    },
    {
      key: 'surname',
      dataIndex: 'doctor',
      title: t.doctors_forms_table_column_lastname_text,
      render: (doctor) => (doctor ? <strong>{doctor.lastName}</strong> : '-')
    },
    {
      key: 'gsm',
      dataIndex: 'doctor',
      title: t.doctors_forms_table_column_gsm_text,
      render: (doctor) =>
        doctor ? (
          <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
            <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
            {doctor.gsm}
          </span>
        ) : (
          '-'
        )
    },
    {
      key: 'email',
      dataIndex: 'doctor',
      title: t.doctors_forms_table_column_email_text,
      render: (doctor) => (doctor ? <strong>{doctor.email}</strong> : '-')
    },
    {
      key: 'actions',
      title: t.patient_operations_table_column_operations_text,
      render: actions
    }
  ];

  const pagination = doctors.length < 8 ? false : { pageSize: 8 };
  return (
    <>
      {contextHolder}
      <Table className='accent-header' rowKey='id' dataSource={doctors} columns={columns} />
    </>
  );
};

export default DoctorListTable;
