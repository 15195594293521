export default function statusCode(code) {
  const codes = {
    unapproved: 'patient_operations_table_gdpr_unapproved_status_text',
    pending: 'patient_operations_table_consent_pending_status_text',
    approved: 'patient_operations_table_consent_approved_status_text',
    timeout: 'patient_operations_table_consent_timeout_status_text',
    successful: 'patient_operations_table_consent_approved_status_text',
    failed: 'patient_operations_table_consent_unsuccesful_status_text'
  };

  return codes[code];
}
