import React, { useContext, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { LanguageContext, translations } from '../../../translation';

export default function DeletePackage({ onAccept }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const onClose = () => setVisible(false);

  return (
    <div>
      <Modal visible={visible} footer={null} onCancel={onClose} destroyOnClose>
        <p style={{ fontSize: 16 }}>{t.purchase_package_delete_package_popup_text}</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button danger onClick={onClose}>
            {t.general_cancel_text}
          </Button>
          <Button
            type='primary'
            onClick={() => {
              onAccept();
              onClose();
            }}
          >
            {t.general_delete_text}
          </Button>
        </div>
      </Modal>
      <Tooltip title={t.purchase_package_tips_delete_package_text}>
        <Button
          onClick={() => {
            setVisible(true);
          }}
          shape='circle'
          danger
        >
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Tooltip>
    </div>
  );
}
