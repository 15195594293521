/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Form, Button, Input, Divider, notification, AutoComplete, Select } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import { useForm } from 'antd/lib/form/Form';

import PostMailSendSecretaryService from '../../../api/mail/post-mail-send-secretary';

import { IUser } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';
import { EMAIL_REGEX } from '../../../regex';

type Props = {
  onSubmit: (doctor: IUser) => void;
  onCancel: () => void;
};

const SecretaryForm = ({ onCancel }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const updated = useSelector((state: any) => state.updated);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    email: ''
  });
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptionRole, setSelectedOptionRole] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleCancel = () => {
    onCancel();
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    PostMailSendSecretaryService(values.email)
      .then(() => {
        form.setFieldsValue({ email: '' });
        setFormValues((prev) => ({ email: '' }));
        openNotification('bottomRight', 'success', t.users_notification_invitation_sent, '');
        dispatch(setDataUpdated(!updated));
        handleCancel();
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.users_notification_invitation_error,
          ''
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <Form onFinish={onFinish} form={form} layout='vertical' className='mb-4'>
        <div className='row'>
          <div className='col-sm-7 col-12'>
            <div className='form-group'>
              <Form.Item
                rules={[
                  { required: true, message: t.input_error_invalid_email },
                  {
                    pattern: EMAIL_REGEX,
                    message: t.input_error_invalid_email
                  }
                ]}
                label={t.users_popup_add_user_email_text}
                name={'email'}
              >
                <Input
                  placeholder={t.users_popup_add_user_email_placeholder}
                  name='email'
                  type='email'
                  onChange={handleFormValues}
                  value={formValues.email}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: t.input_error_invalid_user_role }]}
                label={t.users_popup_add_user_role_text}
              >
                <Select
                  options={[{ id: '1', value: `${t.users_popup_user_type_assistant_text}` }]}
                  className='app-search'
                  filterOption={(inputValue, option) =>
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={(value, option) => {
                    setSelectedOptionRole(option.id);
                  }}
                >
                  <Input
                    placeholder={t.users_popup_add_user_role_placeholder}
                    suffix={<span className='icofont icofont-search' />}
                  />
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
        <Divider />
        <div className='d-flex justify-content-between buttons-list settings-actions mt-4'>
          <Button danger onClick={handleCancel}>
            {t.users_popup_add_user_cancel}
          </Button>
          <Button disabled={isLoading} loading={isLoading} htmlType='submit' type='primary'>
            {t.users_popup_add_user_invite}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SecretaryForm;
