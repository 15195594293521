/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Modal, notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification';

import DeleteUseService from '../../../api/user/delete-user-invite-id';
import PostMailReSendSecretaryService from '../../../api/mail/post-mail-resend-secretary';
import DeleteUserInvitationService from '../../../api/user-invitation/delete-user-invitation-id-reject';

import { LanguageContext, translations } from '../../../translation';

import './Contact.scss';

interface IContact {
  className: any;
  secretary: any;
}

const Contact = ({ className, secretary }: IContact) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };
  const onClose = () => setVisible(false);
  const updated = useSelector((state: any) => state.updated);
  const handleDelete = (id) => {
    DeleteUseService(id)
      .then(() => {
        dispatch(setDataUpdated(!updated));
      })
      .catch((err) => err);
  };

  return (
    <>
      {contextHolder}
      <div className={`contact ${className}`}>
        <div className='info-box'>
          <h4 className='name'>{`${
            secretary?.status === 'pending'
              ? t.users_card_waiting_approval_text
              : secretary.firstName
          } ${secretary?.status === 'pending' ? '' : secretary?.lastName}`}</h4>
          <p className='address'>{`${secretary.email}`}</p>
          <div className='button-box'>
            <Button
              danger
              onClick={() => {
                setVisible(true);
              }}
            >
              {t.users_card_delete_text}
            </Button>
            <Modal visible={visible} footer={null} onCancel={onClose} destroyOnClose>
              <p style={{ fontSize: 16 }}>{t.users_popup_remove_user_title}</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button danger onClick={onClose}>
                  {t.users_popup_remove_cancel_text}
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    if (secretary?.status !== 'pending') {
                      handleDelete(secretary.id);
                    } else
                      DeleteUserInvitationService(secretary.id).then(() => {
                        openNotification(
                          'bottomRight',
                          'success',
                          t.users_notification_invitation_removed,
                          ''
                        );

                        dispatch(setDataUpdated(!updated));
                      });
                  }}
                >
                  {t.users_popup_remove_remove_text}
                </Button>
              </div>
            </Modal>
            {secretary?.status === 'pending' && (
              <Button
                style={{ width: 120, marginLeft: 16 }}
                onClick={() => {
                  PostMailReSendSecretaryService(secretary.email).then(() =>
                    openNotification(
                      'bottomRight',
                      'success',
                      t.users_notification_invitation_resent,
                      ''
                    )
                  );
                }}
              >
                {t.users_card_send_again_text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
