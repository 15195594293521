import { getCall, postCall } from '../request';

const PostSubtenantAcceptCodeService = async (code) => {
  const access_token = localStorage.getItem('token');

  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });

  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/tenant-invitation/corporate/accept `,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: `Bearer ${access_token}`
    },
    data: {
      code
    }
  });
};

export default PostSubtenantAcceptCodeService;
