import { getCall } from '../request';

const GetGDPRFormById = (id) => {
  const access_token = localStorage.getItem('token');

  return getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/gdpr-form/${id}?relations[]=file`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default GetGDPRFormById;
