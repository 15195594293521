import { getCall } from '../request';

const GetSurrogateService = async (patientId, surrogateId = '') => {
  const access_token = localStorage.getItem('token');

  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
  
  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/surrogate/${patientId}?${surrogateId}`,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default GetSurrogateService;
