/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageDada } from '../../../redux/page-data/actions';
import { Card } from 'antd';

import GetPatientConsentStatsService from '../../../api/patient-consent/get-patient-consent-stats';
import GetNewsPublicService from '../../../api/news/get-news-public';
import GetNewsSponsorService from '../../../api/news/get-news-sponsor';

import CarouselComponent from '../../../layout/components/carousel/Carousel';

import { usePageData } from '../../../hooks';
import { IPageData, IUserInfo } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

import styles from './styles.module.css';

const DashboardPage = () => {
  const [arrayCard, setArrayCard] = useState([]);
  const [mediOnam, setMediOnam] = useState([]);
  const [sponsorData, setSponsorData] = useState([]);

  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);
  const dispatch = useDispatch();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const pageData: IPageData = {
    fulFilled: false,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      }
    ]
  };

  usePageData(pageData);
  useEffect(() => {
    setTimeout(() => dispatch(updatePageDada({ fulFilled: true, loaded: true })), 300);
  }, [pageData, dispatch]);

  useEffect(() => {
    GetPatientConsentStatsService()
      .then((res) => {
        setArrayCard([
          {
            title: t.main_page_card_consent_sent_text,
            value: res?.pending + res?.successful + res?.timeout,
            icon: 'icofont-paper-plane',
            color: '#1890ff',
            link: '/consent-status-list/default'
          },
          {
            title: t.main_page_card_consent_approved_text,
            value: res?.successful,
            icon: 'icofont-check-circled',
            color: '#7cb305',
            link: '/consent-status-list/successful'
          },
          {
            title: t.main_page_card_consent_unapproved_text,
            value: res?.timeout,
            icon: 'icofont-close-circled',
            color: '#f5222d',
            link: '/consent-status-list/timeout'
          }
        ]);
      })
      .catch((err) => err);

    GetNewsPublicService()
      .then((res) => {
        setMediOnam(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    GetNewsSponsorService()
      .then((res) => {
        setSponsorData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [language]);
  return (
    <>
      <div className='col'>
        <div className={styles.info_card_wrapper}>
          {arrayCard.map((item, index) => (
            <Card
              key={index}
              style={{
                background: 'rgba(251, 251, 251)',
                height: 150,
                width: '100%',
                margin: '8px',
                cursor: 'pointer'
              }}
              className={`animated with-shadow ${styles.info_card}`}
              onClick={() => (window.location.href = item.link)}
            >
              <div className='row'>
                <div className='col-3'>
                  <span className={item.icon} style={{ fontSize: 48, color: item?.color }} />
                </div>

                <div className='col-7'>
                  <h6 className='mt-0 mb-1'>{item.title}</h6>
                  <div
                    className='count'
                    style={{ fontSize: 20, lineHeight: 1.4, color: '#40a9ff' }}
                  >
                    {item.value}
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>

        {userInfo?.sponsorId && sponsorData?.length > 0 && (
          <div style={{ margin: '64px 0 0 0 ' }}>
            <h4 className={styles.title}>
              <span>{`${userInfo?.sponsor?.name} ${t.main_page_news_other_news_text}`}</span>
              <div>
                <p />
                <p />
              </div>
            </h4>

            <div className={styles.carousel_container}>
              <CarouselComponent dataCount={sponsorData?.length ?? 1}>
                {sponsorData.map((item, index) => (
                  <div className={styles.carousel_card_container} key={index}>
                    <div className={styles.carousel_card}>
                      <a href={item.link} target='blank'>
                        <div className={styles.carousel_interior_container}>
                          <img src={item.imgUrl} alt='' className={styles.carousel_card_image} />
                          <h5 className={styles.carousel_card_title}>{item.title}</h5>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </CarouselComponent>
            </div>
          </div>
        )}
        <div>
          <h4 className={styles.title}>
            <span>{t.main_page_news_medionam_news_text}</span>
            <div>
              <p />
              <p />
            </div>
          </h4>
          <div className={styles.carousel_container}>
            <CarouselComponent dataCount={mediOnam?.length ?? 1}>
              {mediOnam.map((item, index) => {
                return (
                  <div className={styles.carousel_card_container} key={index}>
                    <div className={styles.carousel_card}>
                      <a href={item.link} target='blank'>
                        <div className={styles.carousel_interior_container}>
                          <img src={item.imgUrl} alt='' className={styles.carousel_card_image} />
                          <h5 className={styles.carousel_card_title}>{item.title}</h5>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </CarouselComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
