import { patchCall } from '../request';

const PatchSponsorService = async (sponsor) => {
  let data = new FormData();
  data.append('name', sponsor.name);
  data.append('file', sponsor.file);
  data.append('description', sponsor.description);
  data.append('fileId', sponsor.fileId);

  const access_token = localStorage.getItem('token');

  return await patchCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/sponsor/${sponsor.id}`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'multipart/form-data'
    },
    data,
    timeout: 60 * 1000
  });
};

export default PatchSponsorService;
