import { IRoute } from '../interfaces/routing';
import SettingsPage from '../pages/settings/Settings';
import PatientsPage from '../pages/dashboards/patients/Patients';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';
import SecretariesPage from '../pages/dashboards/secretary/Secretary';
import EditAccountPage from '../pages/services/EditAccounPage';
import Privacy from '../pages/dashboards/consentProcedures/ConsentProcedures';
import Lists from '../pages/dashboards/consentRequestStatusList/ConsentRequestStatusList';
import KvkkForms from '../pages/dashboards/gdprForms/GdprForms';
import ConsentForms from '../pages/dashboards/consent/ConsentForms';
import PackagePage from '../pages/dashboards/package/Package';
import EditInvoicePage from '../pages/services/EditInvoicePage';
import DoctorsPage from '../pages/dashboards/doctor/Doctor';

export const corporateRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'patient-list',
    component: PatientsPage
  },
  { path: 'consent-status-list/:option', component: Lists },
  {
    path: 'main-page',
    component: DashboardPage
  },
  {
    path: 'users',
    component: SecretariesPage
  },
  { path: 'subtenants', component: DoctorsPage},
  { path: 'packaging', component: PackagePage },
  { path: 'patient-operations', component: Privacy },
  { path: 'consent-list', component: ConsentForms },
  { path: 'gdpr-list', component: KvkkForms },
  {
    path: 'edit-account',
    component: EditAccountPage
  },
  {
    path: 'invoice-information',
    component: EditInvoicePage
  }
];
