import React from "react";

interface LanguageContextType {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

export const LanguageContext = React.createContext<LanguageContextType>({
  language: "tr",
  setLanguage: () => {},
});
