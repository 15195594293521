import { postCall } from '../request';

const PostMailSendSecretaryService = async (email) => {
  const access_token = localStorage.getItem('token');

  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/mail/send/secretary`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params: {
      email
    }
  });
};

export default PostMailSendSecretaryService;
