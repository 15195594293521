import { getCall } from '../request';

const GetPackageService = async (params: any = null) => {
  const access_token = localStorage.getItem('token');
  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/package?relations[]=consentGroups`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};
export default GetPackageService;
