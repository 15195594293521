import { postCall } from '../request';

const PostMailSendResetPasswordService = async (email) => {
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/mail/send/reset/password`,
    params: {
      email
    }
  });
};

export default PostMailSendResetPasswordService;
