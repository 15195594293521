/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../redux/data-updated/action';

import { Button, Form, Input, notification, AutoComplete, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import PostConsentGroupService from '../../api/consent-group/post-consent-group';

import { LanguageContext, translations } from '../../translation';

const CreateConsentGroup = ({ onCancel }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    name: '',
    summary: ''
  });
  const { Option } = Select;
  const [isLoading, setIsLoading] = useState(false);
  const [optionsDurations] = useState([
    { value: `1 ${t.purchase_package_month_text}`, id: 'P1M' },
    { value: `3 ${t.purchase_package_month_text}`, id: 'P3M' },
    { value: `6 ${t.purchase_package_month_text}`, id: 'P6M' },
    { value: `9 ${t.purchase_package_month_text}`, id: 'P9M' },
    { value: `1 ${t.purchase_package_year_text}`, id: 'P1Y' }
  ]);

  const [selectedOptionDuration, setSelectedOptionDuration] = useState({});
  const [selectedConsentType, setSelectedConsentType] = useState('approve');

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    const parsedValues = {
      name: values.name,
      summary: values.summary,
      duration: selectedOptionDuration,
      type: selectedConsentType,
      price: parseFloat(values.price)
    };
    PostConsentGroupService(parsedValues)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        form.setFieldsValue({
          name: '',
          summary: ''
        });
        openNotification('bottomRight', 'success', t.notification_successful_file_upload, '');
        setTimeout(() => {
          onCancel();
        }, 2000);
      })
      .catch(() => {
        openNotification('bottomRight', 'error', t.notification_error_file_upload, '');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {contextHolder}
      <div className='row' style={{ maxWidth: '30vw' }}>
        <div className='col-md-19 col-sm-12'>
          <Form onFinish={onFinish} form={form} layout='vertical'>
            <Form.Item
              name='name'
              rules={[{ required: true, message: t.input_error_invalid_consent_information }]}
              label={t.consent_upload_create_consent_consent_name}
            >
              <Input
                value={formValues.name}
                onChange={handleFormValues}
                placeholder={t.consent_upload_create_consent_consent_name}
              />
            </Form.Item>
            <Form.Item
              name='summary'
              rules={[{ required: true, message: t.input_error_invalid_consent_information }]}
              label={t.consent_upload_create_consent_consent_description}
            >
              <Input
                value={formValues.name}
                onChange={handleFormValues}
                placeholder={t.consent_upload_create_consent_consent_description}
              />
            </Form.Item>
            <Form.Item
              name='duration'
              rules={[{ required: true, message: t.input_error_missing_duration }]}
              label={t.consent_upload_create_consent_consent_duration}
            >
              <AutoComplete
                disabled={isLoading}
                options={optionsDurations}
                placeholder={t.consent_upload_create_consent_consent_duration}
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(value, option) => {
                  setSelectedOptionDuration(option.id);
                }}
              />
            </Form.Item>
            <Form.Item
              name='consentType'
              rules={[{ required: true, message: t.input_error_invalid_consent_type }]}
              label={t.consent_upload_create_consent_consent_type}
            >
              <Select
                placeholder={t.consent_upload_create_consent_consent_type}
                value={selectedConsentType}
                optionFilterProp='children'
                className='mb-2 mb-md-0'
                onSelect={(value, option) => {
                  setSelectedConsentType(value);
                }}
              >
                <Option value='approve'>
                  {t.patient_operations_send_consent_popup_consent_type_approve_text}
                </Option>
                <Option value='reject'>
                  {t.patient_operations_send_consent_popup_consent_type_reject_text}
                </Option>
                <Option value='cancel'>
                  {t.patient_operations_send_consent_popup_consent_type_cancel_text}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='price'
              rules={[{ required: true, message: t.input_error_invalid_consent_price }]}
              label={t.consent_upload_create_consent_consent_price}
            >
              <Input
                disabled={isLoading}
                value={formValues.name}
                onChange={handleFormValues}
                placeholder={t.consent_upload_create_consent_consent_price}
                type='number'
              />
            </Form.Item>
            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
              style={{ marginTop: 20 }}
            >
              {t.general_create_text}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateConsentGroup;
