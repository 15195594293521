import { deleteCall } from '../request';

const DeletePackageService = async (id) => {
  const access_token = localStorage.getItem('token');
  return await deleteCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/package/${id}`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default DeletePackageService;
