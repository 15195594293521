/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Form, Input, notification } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import SignInService from '../../api/auth/sign-in';

import PublicLayout from '../../layout/public/Public';
import LanguageSelector from '../../layout/components/actions/LanguageSelector';
import { useNavigateHome } from '../../utils/use-navigate-home';

import { LanguageContext, translations } from '../../translation';
import { EMAIL_REGEX } from '../../regex';
const { Item } = Form;

const SignIn = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    SignInService(values)
      .then((res) => {
        localStorage.setItem('token', res.tokens.access_token);
        openNotification('bottomRight', 'error', t.notification_successful_sign_in, '');
        navigateHome();
      })
      .catch((err) => {
        localStorage.removeItem('token');
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_sign_in,
          ''
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
        <h4 className='mt-0 mb-1'>{t.sign_in_title}</h4>
        <p className='text-color-200'>{t.sign_in_subtitle}</p>
        <Form onFinish={onFinish} form={form} layout='vertical' className='mb-4'>
          <Item
            name='email'
            rules={[
              { required: true, message: t.input_error_invalid_email },
              {
                pattern: EMAIL_REGEX,
                message: t.input_error_invalid_email
              }
            ]}
          >
            <Input
              value={formValues.email}
              onChange={handleFormValues}
              placeholder={t.sign_in_email_text}
              type='mail'
            />
          </Item>
          <Item
            name='password'
            rules={[{ required: true, message: t.input_error_invalid_login_password }]}
          >
            <Input.Password
              onChange={handleFormValues}
              value={formValues.password}
              placeholder={t.sign_in_password_text}
              type='password'
            />
          </Item>
          <Button
            disabled={isLoading}
            loading={isLoading}
            block={false}
            type='primary'
            htmlType='submit'
            icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
          >
            {t.sign_in_title}
          </Button>
        </Form>
        <br />
        <p className='mb-1'>
          <Link to='mail-forgot-password'>{t.sign_in_forgot_password_text}</Link>
        </p>
        <p>
          {t.sign_in_register_question_text} <Link to='sign-up'>{t.sign_in_register_text}</Link>
        </p>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ width: '100%' }}>
            <LanguageSelector />
          </div>
        </div>
        <div></div>
      </PublicLayout>
    </>
  );
};

export default SignIn;
