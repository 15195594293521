import { getCall } from '../request';

const CurrentUserService = async (access_token) => {
  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })
};
export default CurrentUserService;
