import { patchCall } from '../request';
import jwt_decode from 'jwt-decode';

const PutGDPRFormService = (id) => {
  const access_token = localStorage.getItem('token');
  const decodedToken: any = jwt_decode(access_token);

  return patchCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/gdpr-form/${id}/set-default`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'x-tenant-id': decodedToken?.tenantId,
    },
    timeout: 60 * 1000
  });
};

export default PutGDPRFormService;
