import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../redux/data-updated/action';

import { Button, Checkbox, Form, Input, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { NotificationPlacement } from 'antd/es/notification';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';

import PostGDPRFormService from '../../api/gdpr-form/post-gdpr-form';

import { usePageData } from '../../hooks';
import { IPageData } from '../../interfaces';
import { LanguageContext, translations } from '../../translation';

interface IValues {
  name: string;
  file: string;
  checkbox: boolean;
}

const KvkkFormUpload = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const pageData: IPageData = {
    title: t.sidebar_gdpr_upload_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.sidebar_gdpr_upload_text
      }
    ]
  };
  usePageData(pageData);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [form] = useForm();
  const [formValues, setFormValues] = useState<IValues>({
    name: 'string',
    file: 'string',
    checkbox: true
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    const parsedValues = {
      name: values.name,
      file: fileList[0],
      isLatest: formValues.checkbox
    };

    PostGDPRFormService(parsedValues)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        form.setFieldsValue({
          name: ''
        });
        setFileList([]);
        openNotification('bottomRight', 'success', t.notification_successful_file_upload, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_file_upload,
          ''
        );
      })
      .finally(() => setIsLoading(false));
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      {contextHolder}
      <div className='row' style={{ maxWidth: '30vw' }}>
        <div className='col-md-13 col-sm-12'>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout='vertical'>
            <Form.Item
              name='name'
              rules={[{ required: true, message: t.input_error_invalid_file_name }]}
            >
              <Input
                value={formValues.name}
                onChange={handleFormValues}
                placeholder={t.general_folder_name}
              />
            </Form.Item>

            <Form.Item
              name='file'
              rules={[{ required: true, message: t.input_error_missing_file }]}
            >
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>{t.general_pick_folder_text}</Button>
              </Upload>
            </Form.Item>
            <div>
              <label style={{ marginRight: 8 }}>{t.gdpr_upload_default_gdpr_text}</label>
              <Checkbox
                name='checkbox'
                onChange={(e) =>
                  setFormValues((state) => ({ ...state, [e.target.name]: e.target.checked }))
                }
                checked={formValues.checkbox}
              />
            </div>

            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
              style={{ marginTop: 20 }}
            >
              {t.general_upload_text}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default KvkkFormUpload;
