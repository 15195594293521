/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { Button, Form, Input, notification, Switch, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import SignUpSecretaryService from '../../api/auth/sign-up-secretary';
import SignUpService from '../../api/auth/sign-up';

import PublicLayout from '../../layout/public/Public';
import LanguageSelector from '../../layout/components/actions/LanguageSelector';
import { useNavigateHome } from '../../utils/use-navigate-home';

import { LanguageContext, translations } from '../../translation';
import { EMAIL_REGEX, NAME_REGEX, PASSWORD_REGEX, GSM_REGEX } from '../../regex';
const { Item } = Form;

const SignUp = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { mail, token }: any = useParams();
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    promotionCode: '',
    firstName: '',
    lastName: '',
    email: '',
    gsm: '',
    password: '',
    passwordConfirm: ''
  });
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const navigateHome = useNavigateHome();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    form.setFieldsValue({
      email: mail
    });
    setFormValues((prev) => ({ ...prev, email: mail }));
  }, []);

  const onFinish = (values: any) => {
    delete values['passwordConfirm'];
    values.gsm = values.gsm.replace(/\D/g,'');
    if (mail && token) {
      SignUpSecretaryService(values, mail, token)
        .then((res) => {
          openNotification(
            'bottomRight',
            'success',
            t.notification_successful_sign_up_secretary,
            ''
          );
          setTimeout(() => {
            window.location.href = '/sign-in';
          }, 2000);
        })
        .catch((error) => {
          localStorage.removeItem('token');
          openNotification(
            'bottomRight',
            'error',
            error?.response?.data?.message ?? t.notification_error_sign_up,
            ''
          );
        });
    } else
      SignUpService(values)
        .then((res) => {
          openNotification('bottomRight', 'success', t.notification_successful_sign_up, '');
          setTimeout(() => {
            navigateHome();
          }, 3000);
        })
        .catch((error) => {
          localStorage.removeItem('token');
          openNotification(
            'bottomRight',
            'error',
            error?.response?.data?.message ?? t.notification_error_sign_up,
            ''
          );
        });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <PublicLayout bgImg={`${window.origin}/content/register-page.jpg`}>
        <h4 className='mt-0 mb-1'>{t.sign_up_title}</h4>
        <p className='text-color-200'>{t.sign_up_subtitle}</p>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout='vertical'
          className='mb-5'
        >
          <Item
            name='firstName'
            rules={[
              { required: true, message: t.input_error_invalid_name },
              {
                pattern: NAME_REGEX,
                message: t.input_error_invalid_name
              }
            ]}
          >
            <Input
              onChange={handleFormValues}
              placeholder={t.sign_up_name_text}
              value={formValues.firstName}
            />
          </Item>
          <Item
            name='lastName'
            rules={[
              { required: true, message: t.input_error_invalid_name },
              {
                pattern: NAME_REGEX,
                message: t.input_error_invalid_name
              }
            ]}
          >
            <Input
              onChange={handleFormValues}
              placeholder={t.sign_up_surname_text}
              value={formValues.lastName}
            />
          </Item>
          <Item
            name='email'
            rules={[
              { required: true, message: t.input_error_invalid_email },
              {
                pattern: EMAIL_REGEX,
                message: t.input_error_invalid_email
              }
            ]}
          >
            <Input
              disabled={mail ? true : false}
              onChange={handleFormValues}
              placeholder={t.sign_up_email_text}
              type='mail'
              value={formValues.email}
            />
          </Item>
          <Item
            name='gsm'
            rules={[
              { required: true, message: t.input_error_invalid_gsm },
              {
                pattern: GSM_REGEX,
                message: t.input_error_invalid_gsm
              }
            ]}
            initialValue={5}
          >
            <InputMask
              mask='(999)-999-99-99'
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={handleFormValues}
              value={formValues.gsm}
            >
              {(inputProps) => (
                <Input placeholder={t.sign_up_gsm_text} type='tel' {...inputProps} />
              )}
            </InputMask>
          </Item>
          <Item
            name='password'
            extra={t.input_error_invalid_password}
            rules={[
              { required: true, message: t.input_error_invalid_password },
              {
                pattern: PASSWORD_REGEX,
                message: t.input_error_invalid_password
              }
            ]}
          >
            <Input.Password
              onChange={handleFormValues}
              placeholder={t.sign_up_password_text}
              type='password'
              value={formValues.password}
            />
          </Item>
          <Item
            name='passwordConfirm'
            rules={[
              {
                required: true,
                message: t.input_error_invalid_password
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t.input_error_not_matching_password));
                }
              })
            ]}
          >
            <Input.Password
              onChange={handleFormValues}
              placeholder={t.sign_up_repeat_password_text}
              type='password'
              value={formValues.passwordConfirm}
              onError={() => formValues.password !== formValues.passwordConfirm}
            />
          </Item>
          <Item
            name='promotionCode'
            rules={[{ required: false, message: t.input_error_missing_promotion_code }]}
          >
            <Input placeholder={t.sign_up_promotion_code_text} />
          </Item>
          <div className='d-flex align-items-center mb-4'>
            <Switch defaultChecked={privacyCheck} onChange={(bool) => setPrivacyCheck(bool)} />{' '}
            {language === 'en' ? (
              <span>
                {t.sign_up_terms_accept_text}{' '}
                <a
                  target='_blank'
                  href='https://storage.googleapis.com/medionam/docs/%C3%9Cyelik%20S%C3%B6zle%C5%9Fmesi.pdf'
                  rel='noreferrer'
                >
                  {t.sign_up_terms_and_conditions_text}
                </a>
              </span>
            ) : (
              <span>
                {' '}
                <a
                  target='_blank'
                  href='https://storage.googleapis.com/medionam/docs/%C3%9Cyelik%20S%C3%B6zle%C5%9Fmesi.pdf'
                  rel='noreferrer'
                >
                  {t.sign_up_terms_and_conditions_text}
                </a>{' '}
                {t.sign_up_terms_accept_text}
              </span>
            )}
          </div>
          <Button
            disabled={!privacyCheck}
            block={false}
            htmlType='submit'
            type='primary'
            icon={<span className='icofont icofont-plus mr-2' style={{ fontSize: '1.2rem' }} />}
          >
            {t.sign_up_title}
          </Button>
        </Form>
        {!token && (
          <p>
            {t.sign_up_login_question_text} <Link to='sign-in'>{t.sign_up_login_text}</Link>
          </p>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ width: '100%' }}>
            <LanguageSelector />
          </div>
        </div>
      </PublicLayout>
    </>
  );
};

export default SignUp;
