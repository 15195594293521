/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, notification, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Form } from 'antd';
import { NotificationPlacement } from 'antd/es/notification';
import { setDataUpdated } from '../../../redux/data-updated/action';
import { useDispatch, useSelector } from 'react-redux';
import PatchSponsorService from '../../../api/sponsor/patch-sponsor';
import { ISponsor } from '../../../interfaces';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  onCancel: () => void;
  sponsor?: ISponsor;
};
interface IValues {
  name: string;
  file: string;
  description: string;
}

const UpdateSponsorForm = ({ onCancel, sponsor }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [form] = useForm();
  const [formValues, setFormValues] = useState<IValues>({
    name: '',
    description: '',
    file: ''
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    form.setFieldsValue({
      name: sponsor?.name,
      description: sponsor?.description
    });
  }, [sponsor]);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const parsedValues = {
      name: values.name,
      description: values.description,
      file: fileList[0],
      fileId: sponsor.fileId,
      id: sponsor.id
    };

    PatchSponsorService(parsedValues)
      .then(() => {
        openNotification('bottomRight', 'success', t.notification_successful_sponsor_update, '');
        dispatch(setDataUpdated(!updated));
        onCancel();
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_sponsor_update,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList
  };

  return (
    <>
      {contextHolder}
      <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
        <h3>{t.sponsors_operations_tips_update_sponsor_text}</h3>
        <div className='form-group'>
          <Form.Item
            label={t.sponsors_add_sponsor_popup_name_text}
            name={'name'}
            rules={[{ required: true, message: t.input_error_missing_sponsor_name }]}
          >
            <Input
              placeholder={t.sponsors_add_sponsor_popup_name_text}
              name={'name'}
              type='text'
              onChange={handleFormValues}
              value={formValues.name}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.sponsors_add_sponsor_popup_description_text}
            name={'description'}
            rules={[{ required: true, message: t.input_error_missing_sponsor_description }]}
          >
            <Input
              placeholder={t.sponsors_add_sponsor_popup_description_text}
              name={'description'}
              type='text'
              onChange={handleFormValues}
              value={formValues.description}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item name='file'>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>{t.general_pick_folder_text}</Button>
            </Upload>
          </Form.Item>
        </div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={onCancel}>
            {t.general_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {t.sponsors_operations_tips_update_sponsor_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdateSponsorForm;
