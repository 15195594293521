import { getCall } from '../request';

const GetSponsorService = async (params = null) => {
  const access_token = localStorage.getItem('token');

  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/sponsor`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};
export default GetSponsorService;
