/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Input, Modal, notification, Table, Tooltip } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import DeleteNewsService from '../../../api/news/delete-news';
import GetNewsService from '../../../api/news/get-news';
import AddNews from '../../../layout/components/news/AddNews';
import DeleteNews from '../../../layout/components/news/DeleteNews';

import { INews } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  onEditNews?: (news: INews) => void;
  onDeleteNews?: (id: string) => void;
  visibleAddNewsButton?: boolean;
};

const NewsTable = ({ visibleAddNewsButton = false }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [news, setNews] = useState<INews[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filterColumns, setFilterColumns] = useState({
    title: true,
    content: true,
    sponsor: true,
    actions: true
  });
  const onClose = () => setVisible(false);

  const actions = (news: INews) => (
    <div className='buttons-list nowrap'>
      <AddNews componentType={'update'} news={news} />
      <DeleteNews onAccept={() => handleDeleteNews(news.id)} />
    </div>
  );

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleDeleteNews = (id) => {
    setLoading(true);
    DeleteNewsService(id)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification('bottomRight', 'success', t.notification_successful_news_deleted, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_news_deleted,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetNews();
  }, [updated, currentPage]);

  const columns = [
    {
      key: 'title',
      dataIndex: 'title',
      title: t.news_table_column_title_text,
      render: (title) => <strong>{title}</strong>
    },
    {
      key: 'content',
      dataIndex: 'content',
      title: t.news_table_column_description_text,
      render: (content) => <strong>{content}</strong>
    },
    {
      key: 'sponsor',
      dataIndex: 'sponsor',
      title: t.news_table_column_sponsor_name_text,
      render: (sponsor) => <strong>{sponsor?.name ?? '-'}</strong>
    },
    {
      key: 'actions',
      title: t.news_table_column_operations_text,
      render: !visibleAddNewsButton ? actions : null
    }
  ];

  useEffect(() => {
    const data = columns.filter((el) => filterColumns[el.key]);
    setFilteredColumns(data);
  }, [filterColumns]);

  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };
  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleGetNews, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  //kullanıcı yazmayı bitirdiğinde çalışacak fonksiyon
  const handleGetNews = () => {
    // page=1&limit=2&sortBy=createdAt:DESC&filter.kvkk=1&filter.gdprStatus=306
    if (filterText.length > 2 || filterText.length < 1) {
      const params = { search: filterText, 'relations[]': 'sponsor', page: currentPage, limit: 10 };
      GetNewsService(params)
        .then((res) => {
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
          setNews(res.data);
          openNotification('bottomRight', 'success', t.notification_successful_news_loaded, '');
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_news_loaded,
            ''
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {contextHolder}
      <div style={{ display: 'flex' }}>
        {!visibleAddNewsButton && <AddNews componentType={'add'} />}
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Modal
            title={<h3>{t.general_column_filter_text}</h3>}
            visible={visible}
            footer={null}
            onCancel={onClose}
            destroyOnClose
          >
            {columns.map((column) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ width: 100 }}>{column.title}</span>
                  <input
                    type='checkbox'
                    name={column.key}
                    checked={filterColumns[column.key]}
                    onChange={() =>
                      setFilterColumns((state) => ({
                        ...state,
                        [column.key]: !filterColumns[column.key]
                      }))
                    }
                  />
                </div>
              );
            })}
          </Modal>
          <Button type='primary' onClick={() => setVisible(true)}>
            {t.general_table_column_text}
          </Button>
        </div>
        <div style={{ maxWidth: 200, margin: '0 0 0 12px' }}>
          <Tooltip title={t.general_search_tip_text}>
            <Input
              type='text'
              id='searchInput'
              placeholder={t.general_search_text}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </Tooltip>
        </div>
      </div>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        dataSource={news}
        columns={filteredColumns}
        loading={loading}
      />
    </>
  );
};

export default NewsTable;
