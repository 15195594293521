import { getCall } from '../request';

const GetPromotionCodeByIdService = async (id, params) => {
  const access_token = localStorage.getItem('token');
  return await getCall({
    // promotion-code?filter.promotionId=c1481bc3-4c2c-4e16-881e-78df7528ebcc&relations=user
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/promotion-code?filter.promotionId=${id}&relations=user`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

export default GetPromotionCodeByIdService;
