/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import PublicLayout from '../../../layout/public/Public';

import PostMailSendResetPasswordService from '../../../api/mail/post-mail-send-reset-password';

import { LanguageContext, translations } from '../../../translation';
import { EMAIL_REGEX } from '../../../regex';
const { Item } = Form;

const SendMailForForgotPassword = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: ''
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setIsLoading(true);

    PostMailSendResetPasswordService(values.email)
      .then(() => {
        form.setFieldsValue({ email: '' });
        setFormValues((prev) => ({ email: '' }));
        openNotification('bottomRight', 'success', t.notification_successful_forgot_password, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_forgot_password,
          ''
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {contextHolder}
      <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
        <h4 className='mt-0 mb-1'>{t.forgot_password_title}</h4>
        <p className='text-color-200'>{t.forgot_password_subtitle}</p>
        <Form onFinish={onFinish} form={form} layout='vertical' className='mb-4'>
          <Item
            name='email'
            rules={[
              { required: true, message: t.input_error_invalid_email },
              {
                pattern: EMAIL_REGEX,
                message: t.input_error_invalid_email
              }
            ]}
          >
            <Input
              value={formValues.email}
              onChange={handleFormValues}
              placeholder={t.forgot_password_email_text}
              type='mail'
            />
          </Item>

          <Button
            disabled={isLoading}
            loading={isLoading}
            block={false}
            type='primary'
            htmlType='submit'
          >
            {t.forgot_password_send_text}
          </Button>
        </Form>
        <p className='mb-1'>
          <Link to='sign-in'>{t.forgot_password_back_text}</Link>
        </p>
      </PublicLayout>
    </>
  );
};

export default SendMailForForgotPassword;
