import React, { useState, useContext } from 'react';
import { usePageData } from '../../../hooks/usePage';
import { IPageData } from '../../../interfaces/page';
import { LanguageContext } from '../../../translation/LanguageContext';
import { translations } from '../../../translation/translation';
import { Button, Modal } from 'antd';
import DoctorForm from './DoctorForm';
import DoctorListTable from './DoctorListTable';

const DoctorsPage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const pageData: IPageData = {
    title: t.sidebar_subtenants_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'subtenants'
      },
      {
        title: t.sidebar_subtenants_text
      }
    ]
  };
  usePageData(pageData);

  const [addingDoctor, setAddingDoctor] = useState(false);

  const openModal = () => setAddingDoctor(true);
  const closeModal = () => setAddingDoctor(false);

  usePageData(pageData);

  return (
    <>
      <Button style={{ marginBottom: 24 }} onClick={openModal}>
        <span className='icofont-contact-add' style={{ fontSize: '2em' }} />
        {t.doctors_add_user_text}
      </Button>

      <DoctorListTable></DoctorListTable>

      <Modal
        visible={addingDoctor}
        onCancel={closeModal}
        title={<h3 className='title'>{t.doctors_add_user_text}</h3>}
        destroyOnClose
        footer={null}
      >
        <DoctorForm onSubmit={closeModal} onCancel={closeModal} />
      </Modal>
    </>
  );
};

export default DoctorsPage;
