import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
const Context = React.createContext({ name: 'Default' });

ReactDOM.render(
  <Context.Provider value={{ name: 'Ant Design' }}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Context.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
