import { postCall } from '../request';

const SignInService = async (values) => {
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/signin`,
    data: values
  });
};

export default SignInService;
