import React, { useEffect, useState, useContext } from 'react';
import { Button, Form, Select } from 'antd';

import {
  DisplayPhoneNumber,
  isForeignNumber
} from '../../../layout/components/phoneFormat/PhoneFormat';

import { calculate_age } from '../../../utils/ageCalculate';

import GetSurrogateService from '../../../api/surrogate/get-surrogate';

import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IGdprSendModal {
  patient?: IPatient;
  sendSms?: (surrogateId: string) => void;
}
const { Option } = Select;

const GdprSendModel = ({ patient = null, sendSms }: IGdprSendModal) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [surrogates, setSurrogates] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!patient) {
      return;
    }
    GetSurrogateService(patient.id).then((res) => {
      setSurrogates(res);
    });
  }, [patient]);

  useEffect(() => {
    if (calculate_age(patient.birthDay) < 18 && surrogates && surrogates.length > 0) {
      setSelectedPerson(surrogates[0].id);
    }
  }, [surrogates]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <h6
          style={{
            marginTop: '12px',
            marginBottom: '12px',
            marginRight: '8px',
            display: 'flex',
            justifyContent: 'left',
            textAlign: 'left'
          }}
        >
          {t.sms_communication_preference_text}
        </h6>
        <Form.Item style={{ display: 'inline-block', width: 320 }}>
          <Select
            placeholder='Form gönderilecek kişiyi seçin'
            value={selectedPerson}
            optionFilterProp='children'
            className='mb-2 mb-md-0'
            onSelect={(value, option) => {
              setSelectedPerson(value);
            }}
          >
            {calculate_age(patient.birthDay) < 18 ? (
              <></>
            ) : (
              <Option value=''>
                {patient?.firstName} {patient?.lastName} /{' '}
                {isForeignNumber(patient?.gsm) ? patient?.email : DisplayPhoneNumber(patient?.gsm)}
              </Option>
            )}
            {surrogates.map((surrogate) => {
              return (
                <Option value={surrogate.id}>
                  {surrogate.firstName} {surrogate.lastName} /{' '}
                  {isForeignNumber(surrogate?.gsm)
                    ? surrogate?.email
                    : DisplayPhoneNumber(surrogate?.gsm)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          loading={isButtonLoading}
          onClick={() => {
            setIsButtonLoading(true);
            sendSms(selectedPerson);
            setTimeout(() => {
              setIsButtonLoading(false);
            }, 1000);
          }}
        >
          {t.sms_send_text}
        </Button>
      </div>
    </div>
  );
};

export default GdprSendModel;
