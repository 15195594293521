import moment from 'moment';

export const calculate_and_set_age = (birthDay, setAge) => {
  if (!moment(birthDay, "DD-MM-YYYY", true).isValid()) {
    setAge(100);
  } else {
    const diff = moment().diff(birthDay, 'years');
    setAge(diff);
  }
};

export const calculate_age = (birthDay: string): number => {
  if (!moment(birthDay, "DD-MM-YYYY", true).isValid() && !moment(birthDay, "YYYY-MM-DD", true).isValid()) {
    return 100
  } else {
    return moment().diff(birthDay, 'years');
  }
};
