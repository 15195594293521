import React, { useContext, useState } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { LanguageContext, translations } from '../../../translation';

export default function GetPackageConsent({ consentGroups }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t.purchase_package_content_popup_consent_text,
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'summary',
      dataIndex: 'summary',
      title: t.purchase_package_content_popup_consent_detail_text,
      render: (summary) => <strong>{summary}</strong>
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t.purchase_package_content_popup_consent_creation_date_text,
      render: (createdAt) => <strong>{moment(createdAt).format('DD/MM/YYYY')}</strong>
    }
  ];

  return (
    <div>
      <Modal
        title={
          <span style={{ fontSize: '1.5em' }}>
            {t.purchase_package_content_popup_consent_title}
          </span>
        }
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <Table className='accent-header' columns={columns} dataSource={consentGroups ?? []} />
      </Modal>

      <Tooltip title={t.purchase_package_tips_view_package_text}>
        <Button
          onClick={() => {
            setShowModal(true);
          }}
          style={{ border: 'none' }}
          shape='circle'
          type='primary'
        >
          <EyeOutlined />
        </Button>
      </Tooltip>
    </div>
  );
}
