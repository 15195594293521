import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Input, notification, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';
import CountryPhoneInput, { CountryPhoneInputValue } from 'antd-country-phone-input';

import PostSurrogateService from '../../../api/surrogate/post-surrogate';

import { PhoneFormat } from '../phoneFormat/PhoneFormat';

import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';
import { EMAIL_REGEX, NAME_REGEX, TCKN_REGEX } from '../../../regex';

type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
};

const SurrogateForm = ({ submitText = 'Hasta Ekle', onCancel, patient }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [surrogatePhone, setSurrogatePhone] = useState<CountryPhoneInputValue>({
    short: 'TR',
    code: 90
  });
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    tckn: '',
    gsm: '',
    isForeign: false
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = () => {
    setLoading(true);
    const obj = {
      ...formValues,
      patientId: patient.id,
      gsm: formValues.gsm.length < 8 ? '' : formValues.gsm
    };
    obj.email.length < 1 && delete obj.email;
    PostSurrogateService(obj)
      .then(() => {
        form.setFieldsValue({
          firstName: '',
          lastName: '',
          email: '',
          tckn: ''
        });
        setFormValues((prev) => ({ ...prev, gsm: '' }));
        openNotification('bottomRight', 'success', t.notification_successful_surrogate_added, '');
        dispatch(setDataUpdated(!updated));
        setTimeout(() => {
          onCancel();
        }, 1000);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_surrogate_added,
          ''
        );
      })
      .finally(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <div style={{ paddingBottom: '4px' }}>
        <Button
          id='turkish'
          style={{
            width: '50%',
            height: '32px',
            fontSize: '12px',
            borderRadius: '20px 0 0 20px',
            border: 'none',
            background: formValues.isForeign ? '#dbdbe7' : '#336cfb',
            color: formValues.isForeign ? '#181818' : '#f9f9f9'
          }}
          onClick={() => setFormValues((prevState) => ({ ...prevState, isForeign: false }))}
        >
          {t.patient_operations_update_patient_popup_turkish_text}
        </Button>
        <Button
          id='foreign'
          style={{
            width: '50%',
            height: '32px',
            fontSize: '12px',
            borderRadius: '0 20px 20px 0',
            border: 'none',
            background: formValues.isForeign ? '#336cfb' : '#dbdbe7',
            color: formValues.isForeign ? '#f9f9f9' : '#181818'
          }}
          onClick={() => setFormValues((prevState) => ({ ...prevState, isForeign: true }))}
        >
          {t.patient_operations_update_patient_popup_foreign_text}
        </Button>
      </div>
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout={'vertical'}>
        <div className='form-group'>
          <Form.Item
            label={t.patient_operations_add_surrogate_popup_name_text}
            name={'firstName'}
            rules={[
              { required: true, message: t.input_error_invalid_name },
              {
                pattern: NAME_REGEX,

                message: t.input_error_invalid_name
              }
            ]}
          >
            <Input
              placeholder={t.patient_operations_add_surrogate_popup_name_text}
              name='firstName'
              type='text'
              onChange={handleFormValues}
              value={formValues.firstName}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.patient_operations_add_surrogate_popup_surname_text}
            name={'lastName'}
            rules={[
              { required: true, message: t.input_error_invalid_name },
              {
                pattern: NAME_REGEX,
                message: t.input_error_invalid_name
              }
            ]}
          >
            <Input
              placeholder={t.patient_operations_add_surrogate_popup_surname_text}
              name='lastName'
              type='text'
              onChange={handleFormValues}
              value={formValues.lastName}
            />
          </Form.Item>
        </div>

        <div className='form-group'>
          <Form.Item
            label={
              formValues.isForeign
                ? t.patient_operations_add_patient_popup_surrogate_passport_no_text
                : t.patient_operations_add_patient_popup_surrogate_tc_id_text
            }
            name={'tckn'}
            rules={[
              {
                message: formValues.isForeign
                  ? t.input_error_invalid_passport_id
                  : t.input_error_invalid_tc_id,
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      formValues.isForeign
                        ? t.input_error_invalid_passport_id
                        : t.input_error_invalid_tc_id
                    );
                  }

                  if (formValues.isForeign) {
                    return Promise.resolve();
                  }

                  if (TCKN_REGEX.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(t.input_error_invalid_tc_id);
                  }
                }
              }
            ]}
          >
            <Input
              placeholder={t.patient_operations_add_surrogate_popup_tc_id_text}
              name='tckn'
              onChange={handleFormValues}
              value={formValues.tckn}
              maxLength={formValues.isForeign ? 15 : 11}
              minLength={formValues.isForeign ? 5 : 11}
            />
          </Form.Item>
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Form.Item
                label={t.patient_operations_add_surrogate_popup_email_text}
                name={'email'}
                rules={[
                  {
                    required: surrogatePhone?.code !== 90,
                    message: t.input_error_invalid_email
                  },
                  {
                    pattern: EMAIL_REGEX,
                    message: t.input_error_invalid_email
                  }
                ]}
              >
                <Input
                  placeholder={t.patient_operations_add_surrogate_popup_email_text}
                  name='email'
                  type='email'
                  onChange={handleFormValues}
                  value={formValues.email}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Form.Item
                label={t.patient_operations_add_surrogate_popup_gsm_text}
                name={'gsm'}
                rules={[
                  {
                    required: formValues.gsm.length < 10,
                    message: t.input_error_invalid_gsm
                  }
                ]}
              >
                <input style={{ display: 'none' }} type='text' name='gsm' value={formValues.gsm} />
                <CountryPhoneInput
                  className='customize_country_gsm'
                  value={{
                    phone: PhoneFormat(surrogatePhone.phone),
                    code: surrogatePhone.code,
                    short: surrogatePhone.short
                  }}
                  onChange={(v) => {
                    const param = 'gsm';
                    setSurrogatePhone({
                      phone: PhoneFormat(v.phone).replace(/[ ]*[(]*[)]*/g, ''),
                      code: v.code,
                      short: v.short
                    });
                    setFormValues((prev) => ({
                      ...prev,
                      [param]: '+' + v.code + PhoneFormat(v.phone).replace(/[ ]*[(]*[)]*/g, '')
                    }));
                  }}
                  placeholder={t.patient_operations_add_surrogate_popup_gsm_text}
                  maxLength={15}
                  minLength={10}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={onCancel}>
            {t.patient_operations_add_surrogate_popup_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SurrogateForm;
