/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, notification, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { IPatient } from '../../../interfaces';
import { NotificationPlacement } from 'antd/es/notification';
import PatchPatientService from '../../../api/patient/patch-patient';
import { setDataUpdated } from '../../../redux/data-updated/action';
import { useDispatch, useSelector } from 'react-redux';
import { PhoneFormat } from '../phoneFormat/PhoneFormat';
import { calculate_and_set_age } from '../../../utils/ageCalculate';
import 'moment/locale/tr';
import moment, { Moment } from 'moment';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import getDayDifference from '../../../utils/getDateDiff';
import CountryPhoneInput, { CountryPhoneInputValue } from 'antd-country-phone-input';
import { LanguageContext, translations } from '../../../translation';
import { EMAIL_REGEX, NAME_REGEX, GSM_REGEX, TCKN_REGEX } from '../../../regex';

type Props = {
  onCancel: () => void;
  patient?: IPatient;
};

interface IFormValues {
  firstName: string;
  lastName: string;
  email?: string;
  gsm?: string;
  tckn: string;
  birthDay: any;
  hasSurrogate: boolean;
  isForeign?: boolean;
  surrogate?: {
    firstName: string;
    lastName: string;
    email?: any;
    gsm?: string;
    tckn: string;
    isForeign: boolean;
  };
}

const UpdatePatientForm = ({ onCancel, patient }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const [age, setAge] = useState<number>();
  const [birthDate, setBirthDate] = React.useState<Moment | null>(moment(patient.birthDay));
  const [phone, setPhone] = useState<CountryPhoneInputValue>({ short: 'TR', code: 90 });
  const [surrogatePhone, setSurrogatePhone] = useState<CountryPhoneInputValue>({
    short: 'TR',
    code: 90
  });
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [form] = useForm();
  const [formValues, setFormValues] = useState<IFormValues>({
    firstName: '',
    lastName: '',
    email: '',
    tckn: '',
    gsm: '',
    birthDay: new Date(patient.birthDay),
    hasSurrogate: patient?.hasSurrogate,
    isForeign: patient?.isForeign
  });
  const [formValuesSurrogate, setFormValuesSurrogate] = useState({
    firstNameSurrogate: '',
    lastNameSurrogate: '',
    emailSurrogate: '',
    tcknSurrogate: '',
    gsmSurrogate: '',
    isForeign: false
  });

  useEffect(() => {
    calculate_and_set_age(moment(formValues.birthDay).format('DD-MM-YYYY'), setAge);
  }, [formValues.birthDay]);

  useEffect(() => {
    calculate_and_set_age(moment(birthDate).format('DD-MM-YYYY'), setAge);
  }, [birthDate]);

  useEffect(() => {
    form.setFieldsValue({
      firstName: patient?.firstName,
      lastName: patient?.lastName,
      email: patient?.email,
      tckn: patient?.tckn,
      gsm: patient?.gsm,
      isForeign: patient?.isForeign,
      birthDate: moment(patient.birthDay).format('DD-MM-YYYY')
    });
    if (patient.gsm) {
      setPhone({
        phone: patient.gsm.slice(patient?.gsm.length - 10, patient?.gsm.length),
        code: parseInt(patient?.gsm.slice(1, patient?.gsm.length - 10))
      });
    }
    setFormValues((prev) => ({
      ...prev,
      isForeign: patient?.isForeign,
      hasSurrogate: patient?.hasSurrogate,
      gsm: patient?.gsm
    }));
    setBirthDate(moment(patient?.birthDay));
    calculate_and_set_age(moment(patient.birthDay).format('DD-MM-YYYY'), setAge);
  }, [patient]);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleFormValuesSurrogate = (e) => {
    setFormValuesSurrogate((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleBirthDateChange = (newDate: Moment | null) => {
    const today = new Date();
    const date2 = new Date(moment(newDate).format());
    const diff = getDayDifference(today, date2);
    if (diff < 0) return;
    setBirthDate(newDate);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (!birthDate.isValid()) {
      openNotification('bottomRight', 'error', t.input_error_invalid_dob, '');
      setLoading(false);
      return;
    }
    formValues.birthDay = new Date(moment(birthDate.toISOString()).format('YYYY-MM-DD'));
    const obj: IFormValues =
      age < 18
        ? {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            tckn: values.tckn,
            birthDay: moment(formValues.birthDay).format('YYYY-MM-DD'),
            gsm: formValues.gsm.length < 6 ? '' : formValues.gsm,
            hasSurrogate: true,
            isForeign: formValues.isForeign,
            surrogate: {
              firstName: formValuesSurrogate.firstNameSurrogate,
              lastName: formValuesSurrogate.lastNameSurrogate,
              email: formValuesSurrogate.emailSurrogate,
              tckn: formValuesSurrogate.tcknSurrogate,
              gsm:
                formValuesSurrogate.gsmSurrogate.length < 8 ? '' : formValuesSurrogate.gsmSurrogate,
              isForeign: formValuesSurrogate.isForeign
            }
          }
        : {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            tckn: values.tckn,
            birthDay: moment(formValues.birthDay).format('YYYY-MM-DD'),
            gsm: formValues.gsm.length < 8 ? '' : formValues.gsm,
            hasSurrogate: formValues.hasSurrogate,
            isForeign: formValues.isForeign
          };
    !obj.email && delete obj.email;
    if (obj.surrogate) {
      !obj?.surrogate?.email && delete obj.surrogate.email;
      !obj?.surrogate?.firstName && delete obj.surrogate;
    }
    PatchPatientService(obj, patient.id)
      .then(() => {
        openNotification('bottomRight', 'success', t.notification_successful_patient_update, '');
        dispatch(setDataUpdated(!updated));
        onCancel();
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_patient_update,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
        <h3>{t.patient_operations_update_patient_popup_title}</h3>
        <div style={{ paddingBottom: '4px' }}>
          <Button
            id='turkish'
            style={{
              width: '50%',
              height: '32px',
              fontSize: '12px',
              borderRadius: '20px 0 0 20px',
              border: 'none',
              background: formValues.isForeign ? '#dbdbe7' : '#336cfb',
              color: formValues.isForeign ? '#181818' : '#f9f9f9'
            }}
            onClick={() => setFormValues((prevState) => ({ ...prevState, isForeign: false }))}
          >
            {t.patient_operations_update_patient_popup_turkish_text}
          </Button>
          <Button
            id='foreign'
            style={{
              width: '50%',
              height: '32px',
              fontSize: '12px',
              borderRadius: '0 20px 20px 0',
              border: 'none',
              background: formValues.isForeign ? '#336cfb' : '#dbdbe7',
              color: formValues.isForeign ? '#f9f9f9' : '#181818'
            }}
            onClick={() => setFormValues((prevState) => ({ ...prevState, isForeign: true }))}
          >
            {t.patient_operations_update_patient_popup_foreign_text}
          </Button>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.patient_operations_update_patient_popup_name_text}
            name={'firstName'}
            rules={[
              { required: true, message: t.input_error_invalid_name },
              {
                pattern: NAME_REGEX,
                message: t.input_error_invalid_name
              }
            ]}
          >
            <Input
              placeholder={t.patient_operations_update_patient_popup_name_text}
              name={'firstName'}
              type='text'
              onChange={handleFormValues}
              value={formValues.firstName}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.patient_operations_update_patient_popup_surname_text}
            name={'lastName'}
            rules={[
              { required: true, message: t.input_error_invalid_name },
              {
                pattern: NAME_REGEX,
                message: t.input_error_invalid_name
              }
            ]}
          >
            <Input
              placeholder={t.patient_operations_update_patient_popup_surname_text}
              name={'lastName'}
              type='text'
              onChange={handleFormValues}
              value={formValues.lastName}
            />
          </Form.Item>
        </div>

        <div className='form-group'>
          <Form.Item
            label={
              formValues.isForeign
                ? t.patient_operations_update_patient_popup_passport_no_text
                : t.patient_operations_update_patient_popup_tc_id_text
            }
            name={'tckn'}
            rules={[
              {
                message: formValues.isForeign
                  ? t.input_error_invalid_passport_id
                  : t.input_error_invalid_tc_id,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(
                      formValues.isForeign
                        ? t.input_error_invalid_passport_id
                        : t.input_error_invalid_tc_id
                    );
                  }

                  if (formValues.isForeign) {
                    return Promise.resolve();
                  }

                  if (TCKN_REGEX.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(t.input_error_invalid_tc_id);
                  }
                }
              }
            ]}
          >
            <Input
              placeholder={
                formValues.isForeign
                  ? t.patient_operations_update_patient_popup_passport_no_text
                  : t.patient_operations_update_patient_popup_tc_id_text
              }
              name={'tckn'}
              onChange={handleFormValues}
              value={formValues.tckn}
              maxLength={formValues.isForeign ? 15 : 11}
              minLength={formValues.isForeign ? 5 : 11}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Form.Item
              name={'birthDay'}
              label={t.patient_operations_update_patient_popup_dob_text}
              rules={[
                {
                  required: true,
                  message: t.input_error_invalid_dob,
                  validator: () => {
                    if (!birthDate.isValid() || moment().diff(birthDate, 'years') < 0) {
                      return Promise.reject('Some message here');
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              {' '}
              <DatePicker
                className='customize_date_picker'
                inputFormat='DD-MM-YYYY'
                value={birthDate}
                onChange={handleBirthDateChange}
                disableFuture
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'GG / AA / YYYY'
                    }}
                  />
                )}
              />{' '}
            </Form.Item>{' '}
          </LocalizationProvider>
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Form.Item
                label={t.patient_operations_update_patient_popup_email_text}
                name={'email'}
                rules={[
                  {
                    required: age < 18 ? false : phone?.code !== 90,
                    message: t.input_error_invalid_email
                  },
                  {
                    pattern: EMAIL_REGEX,
                    message: t.input_error_invalid_email
                  }
                ]}
              >
                <Input
                  placeholder={t.patient_operations_update_patient_popup_email_text}
                  name={'email'}
                  onChange={handleFormValues}
                  value={formValues.email}
                />
              </Form.Item>
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <Form.Item
                label={t.patient_operations_update_patient_popup_gsm_text}
                name={'gsm'}
                rules={[
                  {
                    required: age < 18 ? false : phone?.phone?.length < 10 || !phone.phone,
                    message: t.input_error_invalid_gsm
                  },
                  {
                    max: 15,
                    min: 10
                  }
                ]}
              >
                <input style={{ display: 'none' }} type='text' name='gsm' value={formValues.gsm} />
                <CountryPhoneInput
                  className='customize_country_gsm'
                  value={{
                    phone: PhoneFormat(phone.phone),
                    code: phone.code,
                    short: phone.short
                  }}
                  onChange={(v) => {
                    const param = 'gsm';
                    setPhone({
                      phone: PhoneFormat(v.phone).replace(/[ ]*[(]*[)]*/g, ''),
                      code: v.code,
                      short: v.short
                    });
                    setFormValues((prev) => ({
                      ...prev,
                      [param]: '+' + v.code + PhoneFormat(v.phone).replace(/[ ]*[(]*[)]*/g, '')
                    }));
                  }}
                  placeholder={t.patient_operations_update_patient_popup_gsm_text}
                  maxLength={15}
                  minLength={10}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        {age < 18 && !patient?.hasSurrogate && (
          <>
            {' '}
            <h3>{t.patient_operations_update_patient_popup_surrogate_title}</h3>
            <div style={{ paddingBottom: '4px' }}>
              <Button
                id='turkish'
                style={{
                  width: '50%',
                  height: '32px',
                  fontSize: '12px',
                  borderRadius: '20px 0 0 20px',
                  border: 'none',
                  background: formValuesSurrogate.isForeign ? '#dbdbe7' : '#336cfb',
                  color: formValuesSurrogate.isForeign ? '#181818' : '#f9f9f9'
                }}
                onClick={() =>
                  setFormValuesSurrogate((prevState) => ({ ...prevState, isForeign: false }))
                }
              >
                {t.patient_operations_update_patient_popup_turkish_text}
              </Button>
              <Button
                id='foreign'
                style={{
                  width: '50%',
                  height: '32px',
                  fontSize: '12px',
                  borderRadius: '0 20px 20px 0',
                  border: 'none',
                  background: formValuesSurrogate.isForeign ? '#336cfb' : '#dbdbe7',
                  color: formValuesSurrogate.isForeign ? '#f9f9f9' : '#181818'
                }}
                onClick={() =>
                  setFormValuesSurrogate((prevState) => ({ ...prevState, isForeign: true }))
                }
              >
                {t.patient_operations_update_patient_popup_foreign_text}
              </Button>
            </div>
            <div className='form-group'>
              <Form.Item
                label={t.patient_operations_update_patient_popup_surrogate_name_text}
                name={'firstNameSurrogate'}
                rules={[
                  { required: true, message: t.input_error_invalid_name },
                  {
                    pattern: NAME_REGEX,
                    message: t.input_error_invalid_name
                  }
                ]}
              >
                <Input
                  placeholder={t.patient_operations_update_patient_popup_surrogate_name_text}
                  name='firstNameSurrogate'
                  type='text'
                  onChange={handleFormValuesSurrogate}
                  value={formValuesSurrogate.firstNameSurrogate}
                />
              </Form.Item>
            </div>
            <div className='form-group'>
              <Form.Item
                label={t.patient_operations_update_patient_popup_surrogate_surname_text}
                name={'lastNameSurrogate'}
                rules={[
                  { required: true, message: t.input_error_invalid_name },
                  {
                    pattern: NAME_REGEX,
                    message: t.input_error_invalid_name
                  }
                ]}
              >
                <Input
                  placeholder={t.patient_operations_update_patient_popup_surrogate_surname_text}
                  name='lastNameSurrogate'
                  type='text'
                  onChange={handleFormValuesSurrogate}
                  value={formValuesSurrogate.lastNameSurrogate}
                />
              </Form.Item>
            </div>
            <div className='form-group'>
              <Form.Item
                label={
                  formValuesSurrogate.isForeign
                    ? t.patient_operations_add_patient_popup_surrogate_passport_no_text
                    : t.patient_operations_add_patient_popup_surrogate_tc_id_text
                }
                name={'tcknSurrogate'}
                rules={[
                  {
                    message: formValuesSurrogate.isForeign
                      ? t.input_error_invalid_passport_id
                      : t.input_error_invalid_tc_id,
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject(
                          formValuesSurrogate.isForeign
                            ? t.input_error_invalid_passport_id
                            : t.input_error_invalid_tc_id
                        );
                      }

                      if (formValuesSurrogate.isForeign) {
                        return Promise.resolve();
                      }

                      if (/^[1-9]{1}[0-9]{9}[02468]{1}$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(t.input_error_invalid_tc_id);
                      }
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t.patient_operations_update_patient_popup_surrogate_tc_id_text}
                  name='tcknSurrogate'
                  onChange={handleFormValuesSurrogate}
                  value={formValuesSurrogate.tcknSurrogate}
                  maxLength={formValuesSurrogate.isForeign ? 15 : 11}
                  minLength={formValuesSurrogate.isForeign ? 5 : 11}
                />
              </Form.Item>
            </div>
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Form.Item
                    label={t.patient_operations_update_patient_popup_surrogate_email_text}
                    name={'emailSurrogate'}
                    rules={[
                      {
                        required: age < 18 && surrogatePhone?.short !== 'TR',
                        message: t.input_error_invalid_email
                      },
                      {
                        pattern: EMAIL_REGEX,
                        message: t.input_error_invalid_email
                      }
                    ]}
                  >
                    <Input
                      placeholder={t.patient_operations_update_patient_popup_surrogate_email_text}
                      name='emailSurrogate'
                      onChange={handleFormValuesSurrogate}
                      value={formValuesSurrogate.emailSurrogate}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className='col-sm-6 col-12'>
                <div className='form-group'>
                  <Form.Item
                    label={t.patient_operations_update_patient_popup_surrogate_gsm_text}
                    name={'gsmSurrogate'}
                    rules={[
                      {
                        required: surrogatePhone?.phone?.length < 10 || !surrogatePhone.phone,
                        message: t.input_error_invalid_gsm
                      },
                      {
                        max: 15,
                        min: 10,
                        pattern: GSM_REGEX
                      }
                    ]}
                  >
                    <input
                      style={{ display: 'none' }}
                      type='text'
                      name='gsmSurrogate'
                      value={formValuesSurrogate.gsmSurrogate}
                    />
                    <CountryPhoneInput
                      className='customize_country_gsm'
                      value={{
                        phone: PhoneFormat(surrogatePhone.phone),
                        code: surrogatePhone.code,
                        short: surrogatePhone.short
                      }}
                      onChange={(v) => {
                        const param = 'gsmSurrogate';
                        setSurrogatePhone({
                          phone: PhoneFormat(v.phone).replace(/[ ]*[(]*[)]*/g, ''),
                          code: v.code,
                          short: v.short
                        });
                        setFormValuesSurrogate((prev) => ({
                          ...prev,
                          [param]: '+' + v.code + PhoneFormat(v.phone).replace(/[ ]*[(]*[)]*/g, '')
                        }));
                      }}
                      placeholder={t.patient_operations_update_patient_popup_surrogate_gsm_text}
                      maxLength={15}
                      minLength={10}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </>
        )}
        {phone.code !== 90 || surrogatePhone.code !== 90 ? (
          <h6 style={{ paddingBottom: '12px', margin: 0, fontSize: '12px', fontWeight: 'bold' }}>
            {t.patient_operations_update_patient_foreign_phone_info_text}
          </h6>
        ) : (
          <></>
        )}
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={onCancel}>
            {t.patient_operations_update_patient_popup_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {t.patient_operations_update_patient_popup_add_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdatePatientForm;
