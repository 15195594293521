import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { FormControl } from '@mui/material';
import { Input } from '@mui/material';
import styles from './styles.module.css';
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='(#00) 000 00 00'
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function FormattedInputs({ phoneNumber, setPhoneNumber, name, placeholder }) {
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (phoneNumber.length < 1) {
      setError(false);
    } else if (phoneNumber.length === 15) {
      setError(false);
    } else setError(true);
  }, [phoneNumber]);

  return (
    <FormControl style={{ width: '100%' }}>
      <div data-active={error} className={styles.gsm}>
        <Input
          disableUnderline={true}
          style={{ width: '100%' }}
          error={error}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber((state) => ({ ...state, [name]: e.target.value }))}
          name='textmask'
          placeholder={placeholder}
          id='formatted-text-mask-input'
          inputComponent={TextMaskCustom}
        />
      </div>
    </FormControl>
  );
}
