import { postCall } from '../request';

const SignUpSecretaryService = async (data, email, verifyToken) => {
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/signup/secretary?email=${email}&verifyToken=${verifyToken}`,
    data
  });
};

export default SignUpSecretaryService;
