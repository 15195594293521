export const PhoneFormat = (gsmNo: string) => {
  let text = gsmNo ?? ''.replace(/ /g, '')
  // eslint-disable-next-line no-unused-expressions
  text.charAt(0) === '0'
    ? (text = text.substr(1, text.length))
    : // eslint-disable-next-line no-console
    console.log();
  // eslint-disable-next-line no-unused-expressions
  text.charCodeAt(0) >= 48 && text.charCodeAt(0) <= 57
    ? // eslint-disable-next-line no-console
    console.log()
    : (text = text.substr(1, text.length));
  text = text.replace('(', '');
  text = text.replace(')', '');
  let newText = '';
  if (text.length > 0) {
    if (text.length <= 3) {
      newText = `(${text.substr(0, text.length)}`;
    } else if (text.length < 6) {
      newText = `(${text.substr(0, 3)}) ${text.substr(3, text.length - 3)}`;
    } else if (text.length < 8) {
      newText = `(${text.substr(0, 3)}) ${text.substr(3, 3)} ${text.substr(6, text.length - 6)}`;
    } else {
      newText = `(${text.substr(0, 3)}) ${text.substr(3, 3)} ${text.substr(6, 2)} ${text.substr(
        8,
        text.length - 8
      )}`;
    }
  }
  return newText.trim();
};

export const DisplayPhoneNumber = (gsmNo: string) => {
  if (gsmNo.length < 4 || !gsmNo) {
    return ''
  }
  let phone = gsmNo?.slice(gsmNo.length - 10, gsmNo?.length)
  let countryCode = parseInt(gsmNo?.slice(1, gsmNo?.length - 10))
  let text = phone ?? ''.replace(/ /g, '')
  text.charAt(0) === '0'
    ? (text = text.substr(1, text.length))
    : text.charCodeAt(0) >= 48 && text.charCodeAt(0) <= 57
      ? // Do Nothing
      console.log()
      : (text = text.substr(1, text.length));
  text = text.replace('(', '');
  text = text.replace(')', '');
  let newText = '';
  if (text.length > 0) {
    if (text.length <= 3) {
      newText = `(${text.substr(0, text.length)}`;
    } else if (text.length < 6) {
      newText = `(${text.substr(0, 3)}) ${text.substr(3, text.length - 3)}`;
    } else if (text.length < 8) {
      newText = `(${text.substr(0, 3)}) ${text.substr(3, 3)} ${text.substr(6, text.length - 6)}`;
    } else {
      newText = `(${text.substr(0, 3)}) ${text.substr(3, 3)} ${text.substr(6, 2)} ${text.substr(
        8,
        text.length - 8
      )}`;
    }
  }
  return `+${countryCode} ` + newText.trim();
};

export const isForeignNumber = (gsmNo: string): boolean => {
   return parseInt(gsmNo?.slice(1, gsmNo?.length - 10)) !== 90
}
