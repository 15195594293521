/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Input, notification, Select, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import PatchNewsService from '../../../api/news/patch-news';
import GetSponsorOnlyNameService from '../../../api/sponsor/sponsor-only-name';

import { INews } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  onCancel: () => void;
  news?: INews;
};

const UpdateNewsForm = ({ onCancel, news }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    title: '',
    content: '',
    link: '',
    imgUrl: '',
    sponsorId: ''
  });
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    GetSponsorOnlyNameService().then((res) => {
      const data = [{ name: 'Public', id: null }, ...res.data].map((el, index) => ({
        value: el.id,
        label: el.name,
        key: index
      }));
      setOptions(data);
      const updatedSponsor = data.find((el) => el.value === news.sponsorId);
      form.setFieldsValue({
        sponsorId: updatedSponsor.value
      });
      setSelectedOption(updatedSponsor.value);
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      title: news.title,
      content: news.content,
      link: news.link,
      imgUrl: news.imgUrl
    });
  }, [news]);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setLoading(true);

    const obj = {
      ...values,
      sponsorId: selectedOption
    };

    PatchNewsService(obj, news.id)
      .then(() => {
        openNotification('bottomRight', 'success', t.notification_successful_news_updated, '');
        dispatch(setDataUpdated(!updated));
        onCancel();
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_news_updated,
          ''
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <Form layout={'vertical'} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
        <h3>{t.news_update_news_text}</h3>
        <div className='form-group'>
          <Form.Item
            label={t.news_add_news_popup_title_text}
            name={'title'}
            rules={[{ required: true, message: t.input_error_missing_news_name }]}
          >
            <Input
              placeholder={t.news_add_news_popup_title_text}
              name={'title'}
              type='text'
              onChange={handleFormValues}
              value={formValues.title}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.news_add_news_popup_description_text}
            name={'content'}
            rules={[{ required: true, message: t.input_error_missing_news_topic }]}
          >
            <Input
              placeholder={t.news_add_news_popup_description_text}
              name={'content'}
              type='text'
              onChange={handleFormValues}
              value={formValues.content}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.news_add_news_popup_target_text}
            name={'link'}
            rules={[{ required: true, message: t.input_error_missing_news_link }]}
          >
            <Input
              placeholder={t.news_add_news_popup_target_text}
              name={'link'}
              type='text'
              onChange={handleFormValues}
              value={formValues.link}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            label={t.news_add_news_popup_image_url_text}
            name={'imgUrl'}
            rules={[{ required: true, message: t.input_error_missing_news_image_link }]}
          >
            <Input
              placeholder={t.news_add_news_popup_image_url_text}
              name={'imgUrl'}
              type='text'
              onChange={handleFormValues}
              value={formValues.imgUrl}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name='sponsorId'
            rules={[{ required: true, message: t.input_error_missing_news_sponsor }]}
            label={t.news_add_news_popup_sponsor_text}
          >
            <Select
              maxTagCount='responsive'
              options={options}
              allowClear
              placeholder={t.news_add_news_popup_select_sponsor_text}
              onChange={(value, option: any) => {
                setSelectedOption(option?.id);
              }}
            />
          </Form.Item>
        </div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={onCancel}>
            {t.general_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {t.news_update_news_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default UpdateNewsForm;
