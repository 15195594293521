import { getCall } from '../request';
import jwt_decode from 'jwt-decode';

const GetGDPRFormService = () => {
  const access_token = localStorage.getItem('token');
  const decodedToken: any = jwt_decode(access_token);

  return getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/gdpr-form`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'x-tenant-id': decodedToken?.tenantId,
    }
  });
};

export default GetGDPRFormService;
