import React, { useContext } from 'react';
import NewsTable from './NewsTable';
import { usePageData } from '../../../hooks';
import { IPageData } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

const NewsPage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const pageData: IPageData = {
    title: t.sidebar_news_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.sidebar_news_text
      }
    ]
  };
  usePageData(pageData);

  return (
    <>
      <NewsTable />
    </>
  );
};

export default NewsPage;
