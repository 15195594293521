/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Modal, notification, Tag } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

import PostPatientGDPRService from '../../../api/patient-gdpr/post-patient-gdpr';

import GdprSendModel from '../../../pages/dashboards/gdprForms/GdprSendModel';
import { calculate_age } from '../../../utils/ageCalculate';
import statusCode from '../../../utils/statusCode';

import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IGdprSendSms {
  patient: IPatient;
}
export default function GdprSendSms({ patient }: IGdprSendSms) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { gdprStatus, id, birthDay, gsm } = patient;
  const [visible, setVisible] = useState(false);
  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const sendSms = (surrogateId) => {
    if (!surrogateId) {
      if (calculate_age(birthDay) < 18) {
        openNotification('bottomRight', 'error', t.notification_error_underage_gdpr, '');
        closeModal();
        return;
      }
      if (!gsm) {
        openNotification('bottomRight', 'error', t.notification_error_missing_phone, '');
        closeModal();
        return;
      }
    }
    if (surrogateId) {
      PostPatientGDPRService({ patientId: id, surrogateId: surrogateId })
        .then(() => {
          dispatch(setDataUpdated(!updated));
          openNotification('bottomRight', 'success', t.notification_successful_gdpr_sent, '');
          setTimeout(() => {
            closeModal();
          }, 3000);
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_gdpr_sent,
            ''
          );
        });
    } else {
      PostPatientGDPRService({ patientId: id })
        .then(() => {
          dispatch(setDataUpdated(!updated));
          openNotification('bottomRight', 'success', t.notification_successful_gdpr_sent, '');
          setTimeout(() => {
            closeModal();
          }, 3000);
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_gdpr_sent,
            ''
          );
        });
    }
  };

  return (
    <>
      {contextHolder}
      <Tag
        color={
          (gdprStatus == 'unapproved' && '#f5222d') ||
          (gdprStatus == 'pending' && '#F9A602') ||
          (gdprStatus == 'approved' && '#299617')
        }
        style={{ cursor: 'pointer' }}
      >
        <span onClick={openModal}>{t[statusCode(gdprStatus)]}</span>
      </Tag>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<span style={{ fontSize: '1.5em' }}>{t.sms_accept_gdpr_label}</span>}
      >
        <GdprSendModel patient={patient} sendSms={sendSms}></GdprSendModel>
      </Modal>
    </>
  );
}
