import { IRoute } from '../interfaces';
import SettingsPage from '../pages/settings/Settings';
import PatientsPage from '../pages/dashboards/patients/Patients';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';

import UserProfilePage from '../pages/services/UserProfilePage';
import EditAccountPage from '../pages/services/EditAccounPage';

import Lists from '../pages/dashboards/consentRequestStatusList/ConsentRequestStatusList';
import KvkkForms from '../pages/dashboards/gdprForms/GdprForms';
import ConsentForms from '../pages/dashboards/consent/ConsentForms';
import Privacy from '../pages/dashboards/consentProcedures/ConsentProcedures';
import EditInvoicePage from '../pages/services/EditInvoicePage';
import DoctorsPage from '../pages/dashboards/doctor/Doctor';

export const corporateSecretaryRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'patient-list',
    component: PatientsPage
  },
  { path: 'consent-status-list/:option', component: Lists },
  {
    path: 'main-page',
    component: DashboardPage
  },
  { path: 'subtenants', component: DoctorsPage },
  { path: 'patient-operations', component: Privacy },
  { path: 'consent-list', component: ConsentForms },
  { path: 'gdpr-list', component: KvkkForms },

  {
    path: 'user-profile',
    component: UserProfilePage
  },
  {
    path: 'edit-account',
    component: EditAccountPage
  },
  {
    path: 'invoice-information',
    component: EditInvoicePage
  }
];
