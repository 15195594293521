import { postCall } from '../request';

const PostConsentGroupService = (data) => {
  const access_token = localStorage.getItem('token');
  return postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/consent-group`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    data
  });
};

export default PostConsentGroupService;
