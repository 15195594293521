import React, { useContext } from 'react';
import { Menu, Dropdown } from 'antd';
import { LanguageContext, translations } from '../../../translation';
import './Actions.scss';

const LanguageSelector = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  const countryFlags = {
    tr: '🇹🇷',
    en: '🇬🇧',
  };

  const countryCapitals = {
    tr: 'TR',
    en: 'EN',
  };

  const languageMenu = (
    <Menu>
      {Object.keys(translations).map((lang) => (
        <Menu.Item
          key={lang}
          onClick={() => {
            setLanguage(lang);
          }}
          className='language-selector-item'
        >
          <span className='text'>
            {countryFlags[lang]} {countryCapitals[lang]}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={languageMenu} className='language-selector'>
      <span>
        {countryFlags[language]} {countryCapitals[language]}
        <span className='icofont-simple-down' />
      </span>
    </Dropdown>
  );
};

export default LanguageSelector;
