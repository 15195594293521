import { getCall, postCall } from '../request';

const PostPatientGDPRResendService = async (data) => {
  const access_token = localStorage.getItem('token');

  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
  
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/patient-gdpr/resend`,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: `Bearer ${access_token}`
    },
    data
  });
};

export default PostPatientGDPRResendService;
