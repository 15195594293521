/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification';

import GetMailVerifyInvitationService from '../../api/mail/get-mail-verify-invitation';

import { useNavigateHome } from '../../utils/use-navigate-home';
import { LanguageContext, translations } from '../../translation';

export default function MailRedirect() {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { token }: any = useParams();
  const navigateHome = useNavigateHome();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };
  useEffect(() => {
    GetMailVerifyInvitationService(token)
      .then((res) => {
        navigateHome();
      })
      .catch((err) =>
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_mail_invitation,
          ''
        )
      );
  }, []);

  return <React.Fragment>{contextHolder}</React.Fragment>;
}
