import { postCall } from '../request';

const PostOrderService = (data) => {
  const access_token = localStorage.getItem('token');
  return postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/order`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    data: data
  });
};

export default PostOrderService;
