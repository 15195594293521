import { useContext, useEffect, useState } from 'react';
import { Button, Divider, Table, Tag } from 'antd';
import moment from 'moment';

import GetPatientConsentService from '../../../api/patient-consent/get-patient-consent';

import ConsentSend from '../../../layout/components/consentSend/ConsentSend';
import ConsentSmsAccept from '../../../layout/components/consentSend/ConsentSmsAccept';

import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IPatientsConsents {
  patient: IPatient;
}

export default function PatientsConsents({ patient }: IPatientsConsents) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      key: 'consentForm',
      dataIndex: 'consentForm',
      title: t.patient_operations_patient_consents_popup_table_column_consent_name_text,
      render: (consentForm) => <strong>{consentForm?.name}</strong>
    },
    {
      key: 'status',
      title: t.patient_operations_patient_consents_popup_table_column_consent_status_text,
      render: (data) => {
        const patientData = { ...data };
        if (data?.status) {
          return (
            <>
              {data?.status === 'pending' && (
                <ConsentSmsAccept patient={patientData} fromConsentList={true} />
              )}
              {data?.status === 'timeout' && <ConsentSend isText patient={patientData} />}
              {data?.status === 'successful' && (
                <Tag color={'#299617'}>
                  <span>{t.patient_operations_table_consent_approved_status_text}</span>
                </Tag>
              )}
            </>
          );
        } else return <ConsentSend isText patient={patient} />;
      }
    },
    {
      key: 'type',
      title: t.patient_operations_patient_consents_popup_table_column_consent_type_text,
      dataIndex: 'type',
      render: (value) => (
        <strong>
          {value === 'reject'
            ? t.patient_operations_patient_consents_popup_consent_type_reject_text
            : value === 'cancel'
            ? t.patient_operations_patient_consents_popup_consent_type_cance_text
            : t.patient_operations_patient_consents_popup_consent_type_approve_text}
        </strong>
      )
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t.patient_operations_patient_consents_popup_table_column_consent_request_date_text,
      render: (createdAt) => <strong>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</strong>
    },
    {
      key: 'consentForm',
      title: t.patient_operations_patient_consents_popup_table_column_consent_operations_text,
      render: (data) => {
        return data?.status === 'successful' ? (
          <Button onClick={() => window.open(data?.file?.url, '_blank')}>
            {t.patient_operations_patient_consents_popup_table_column_view_consent_text}
          </Button>
        ) : (
          <></>
        );
      }
    }
  ];

  const pagination = tableData.length < 4 ? false : { pageSize: 10 };

  useEffect(() => {
    GetPatientConsentService({ relations: 'consentForm,file', 'filter.patientId': patient.id })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => err);
  }, [patient]);

  return (
    <div>
      <div>
        <h5>
          {t.patient_operations_patient_consents_popup_patient_name_text}{' '}
          {`${patient.firstName} ${patient.lastName}`}
        </h5>
      </div>
      <Divider />
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={tableData}
        columns={columns}
      />
    </div>
  );
}
