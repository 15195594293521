import React from 'react';
import { Button, Card, Form, Input } from 'antd';

import { useFormik } from 'formik';

import { useGetPatient, usePageData } from '../../hooks';
import { IPageData } from '../../interfaces';

const pageData: IPageData = {
  title: 'Hasta Vekil Bilgileri',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Ana Sayfa',
      route: 'main-page'
    },
    {
      title: 'Hasta/Vekil Listesi',
      route: 'patients'
    },
    {
      title: 'Hasta Vekil Bilgileri'
    }
  ]
};

const FormItem = Form.Item;
// const Option = Select.Option;

const ProfileForm = ({ patient }) => {
  const { values } = useFormik({
    initialValues: { ...patient },
    onSubmit: () => null
  });

  return (
    <Form layout='vertical'>
      <FormItem label='Ad/Soyad'>
        <Input value={values.fullName} placeholder='Ad/Soyad' />
      </FormItem>

      <FormItem label='T.C. Kimlik No'>
        <Input value={values.id} placeholder='T.C. Kimlik No' />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='GSM'>
            <Input value={values.phone} placeholder='GSM' />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='E-posta'>
            <Input value={values.email} placeholder='E-posta' />
          </FormItem>
        </div>
      </div>
    </Form>
  );
};

const PatientProfilePage = () => {
  const { patient } = useGetPatient('Liam');
  // const billings = useGetBillings();

  usePageData(pageData);

  return (
    patient && (
      <>
        <div className='row mb-4'>
          <div className='col-md-6 col-sm-12'>
            <div className='header mb-3'>
              {/* <h6 className='mt-0 mb-3'>Photo</h6>
              <ImageLoader src={patient.profileImg as string} size={100} /> */}
            </div>

            <div className='info stack'>
              <ProfileForm patient={patient} />
              <Button type='primary'>Vekil Ekle</Button>
            </div>
          </div>

          <div className='col-md-6 col-sm-12'>
            <Card></Card>
          </div>
        </div>

        {/* <Card title='Billings' className='mb-0'>
          <BillingTable billings={billings} />
        </Card> */}
      </>
    )
  );
};

export default PatientProfilePage;
