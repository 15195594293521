/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { AutoComplete, Button, Form, Input, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import PostPackageService from '../../../api/package/post-package';
import GetConsentGroupOnlyName from '../../../api/consent-group/consent-group-only-name';

import { LanguageContext, translations } from '../../../translation';

interface IValues {
  name: string;
  description: string;
  price: number;
  isSingle: boolean;
}
interface IOption {
  id: string;
  value: string;
  summary: string;
}

const PackageFormPage = ({ onCancel }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [form] = useForm();
  const [formValues, setFormValues] = useState<IValues>({
    name: '',
    description: '',
    price: 0,
    isSingle: true
  });
  const [selectedOptionDuration, setSelectedOptionDuration] = useState({});
  const [optionsDurations] = useState([
    { value: `1 ${t.purchase_package_month_text}`, id: 'P1M' },
    { value: `3 ${t.purchase_package_month_text}`, id: 'P3M' },
    { value: `6 ${t.purchase_package_month_text}`, id: 'P6M' },
    { value: `9 ${t.purchase_package_month_text}`, id: 'P9M' },
    { value: `1 ${t.purchase_package_year_text}`, id: 'P1Y' }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  useEffect(() => {
    GetConsentGroupOnlyName()
      .then((res) => {
        const data = res.data.map((el) => ({
          value: el.id,
          label: el.name
        }));
        setOptions(data);
      })
      .catch(() =>
        openNotification('bottomRight', 'error', t.notification_error_package_form_not_found, '')
      );
  }, []);

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    const parsedValues = {
      name: values.name,
      consentGroupIds: selectedOption.map((el: any) => el.value),
      description: values.description,
      price: Number(values.price),
      duration: selectedOptionDuration,
      isSingle: selectedOption.length > 1 ? false : true
    };
    PostPackageService(parsedValues)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        openNotification(
          'bottomRight',
          'success',
          t.notification_successful_package_form_created,
          ''
        );
        setTimeout(() => {
          onCancel();
        }, 3000);
      })
      .catch(() => {
        openNotification('bottomRight', 'error', t.notification_error_package_form_created, '');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {contextHolder}
      <div className='row' style={{ maxWidth: '30vw' }}>
        <div className='col-md-19 col-sm-12'>
          <Form onFinish={onFinish} form={form} layout='vertical'>
            <Form.Item
              label={t.purchase_package_table_column_name_text}
              name='name'
              rules={[{ required: true, message: t.input_error_missing_package_name }]}
            >
              <Input
                disabled={isLoading}
                value={formValues.name}
                onChange={handleFormValues}
                placeholder={t.purchase_package_table_column_name_text}
              />
            </Form.Item>
            <Form.Item
              label={t.purchase_package_table_column_description_text}
              name='description'
              rules={[{ required: true, message: t.input_error_missing_package_description }]}
            >
              <Input
                disabled={isLoading}
                value={formValues.description}
                onChange={handleFormValues}
                placeholder={t.purchase_package_table_column_description_text}
              />
            </Form.Item>
            <Form.Item
              label={t.purchase_package_table_column_package_price_text}
              name='price'
              rules={[{ required: true, message: t.input_error_missing_package_price }]}
            >
              <Input
                type='number'
                disabled={isLoading}
                value={formValues.price}
                onChange={handleFormValues}
                placeholder={t.purchase_package_table_column_package_price_text}
              />
            </Form.Item>

            <Form.Item
              label={t.purchase_package_table_column_package_duration_text}
              name='duration'
              rules={[{ required: true, message: t.input_error_missing_package_duration }]}
            >
              <AutoComplete
                disabled={isLoading}
                options={optionsDurations}
                placeholder={t.purchase_package_table_column_package_duration_text}
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(value, option) => {
                  setSelectedOptionDuration(option.id);
                }}
              />
            </Form.Item>

            <Form.Item
              label={t.purchase_package_table_column_consents_text}
              name='consentGroupIds'
              rules={[{ required: true, message: t.input_error_missing_package_consent }]}
            >
              <Select
                maxTagCount='responsive'
                mode='multiple'
                options={options}
                placeholder={t.purchase_package_table_column_consents_text}
                onChange={(value, option: any) => setSelectedOption(option)}
              />
            </Form.Item>

            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
              style={{ marginTop: 20 }}
            >
              {t.purchase_package_add_package_text}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PackageFormPage;
