import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../redux/sponsors/actions';

import { IAppState, ISponsor } from '../interfaces';

export default function useSponsors() {
  const dispatch = useDispatch();
  const sponsors = useSelector<IAppState, ISponsor[]>((state) => state.sponsors);

  const editSponsor = (sponsor: ISponsor) => {
    return dispatch(actions.editSponsor(sponsor));
  };

  const addSponsor = (sponsor: ISponsor) => {
    return dispatch(actions.addSponsor(sponsor));
  };

  const deleteSponsor = (id: string) => {
    return dispatch(actions.deleteSponsor(id));
  };

  return { sponsors, addSponsor, editSponsor, deleteSponsor };
}
