/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Table } from 'antd';

const renderContent = (value, row, index) => {
  const obj: any = {
    children: value,
    props: {}
  };
  if (index === 4) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const columns = [
  {
    title: 'Talep ID',
    dataIndex: 'requestid',
    render: renderContent
  },
  {
    title: 'Talep Edilen Onam Formu',
    dataIndex: 'requestform',
    render: renderContent
  },
  {
    title: 'Talep Edilen Onam Formu Versiyon',
    dataIndex: 'requestversion',
    render: renderContent
  },
  {
    title: 'Onam Durumu',
    dataIndex: 'requeststatus',
    render: renderContent
  },
  {
    title: '',
    dataIndex: 'button',
    render: renderContent
  },
  {
    title: 'KVKK Durumu',
    dataIndex: 'gdprStatus',
    render: renderContent
  },
  {
    title: '',
    dataIndex: 'button2',
    render: renderContent
  },

  {
    title: 'Hasta Bilgileri',
    innerWidth: 2,
    colSpan: 4,
    dataIndex: 'info',
    render: (value, row, index) => {
      const obj: any = {
        children: value,
        props: {}
      };
      if (index === 2) {
        obj.props.rowSpan = 2;
      }
      // These two are merged into above cell
      if (index === 3) {
        obj.props.rowSpan = 2;
      }
      if (index === 4) {
        obj.props.colSpan = 0;
      }
      return obj;
    }
  },
  {
    title: 'Id',
    colSpan: 0,
    dataIndex: 'id',
    render: renderContent
  },
  {
    title: 'Phone',
    colSpan: 0,
    dataIndex: 'phone',
    render: renderContent
  },
  {
    title: 'Email',
    colSpan: 0,
    dataIndex: 'email',
    render: renderContent
  },
  {
    title: 'Hasta Vekil Bilgileri',
    colSpan: 4,
    dataIndex: 'info2',
    render: (value, row, index) => {
      const obj: any = {
        children: value,
        props: {}
      };

      if (index === 2) {
        obj.props.rowSpan = 2;
      }
      // These two are merged into above cell
      if (index === 3) {
        obj.props.rowSpan = 2;
      }
      if (index === 4) {
        obj.props.colSpan = 0;
      }
      return obj;
    }
  },
  {
    title: 'Id',
    colSpan: 0,
    dataIndex: 'id2',
    render: renderContent
  },
  {
    title: 'Phone',
    colSpan: 0,
    dataIndex: 'phone2',
    render: renderContent
  },
  {
    title: 'Email',
    colSpan: 0,
    dataIndex: 'email2',
    render: renderContent
  }
];

const data = [
  {
    key: '1',
    requestid: 121,
    requestform: 'Kızamık Aşısı',
    requestversion: 'v1',
    requeststatus: 'Onam Alındı',
    button: 'Görüntüle', //buton olmalı
    gdprStatus: 'Güncel',
    button2: 'Görüntüle', //buton olmalı
    info: 'Deniz YILDIZ',
    id: '21129366863',
    phone: '05362230168',
    email: 'deniz@gmail.com',
    info2: 'Selma YILDIZ',
    id2: '19300539402',
    phone2: '05736640163',
    email2: 'selma@gmail.com'
  },
  {
    key: '2',
    requestid: 90,
    requestform: 'Covid-19 Testi',
    requestversion: 'v3.4',
    requeststatus: 'Onam Alındı',
    button: 'Görüntüle', //buton olmalı
    gdprStatus: 'Güncel',
    button2: 'Görüntüle', //buton olmalı
    info: 'Burak KEPEK',
    id: '85746277169',
    phone: '04157796547',
    email: 'burak@gmail.com',
    info2: 'Ahmet KEPEK',
    id2: '34596577961',
    phone2: '04769823541',
    email2: 'ahmet@gmail.com'
  }
];

const CollSpan = () => {
  return (
    <Table
      bordered
      pagination={false}
      className='accent-header'
      columns={columns}
      dataSource={data}
    />
  );
};

export default CollSpan;
