import axios from 'axios';
import { IGetCall, IPostCall, IPatchCall, IDeleteCall } from '../interfaces';

enum Method {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

// TODO replace axios with fetch
const DEFAULT_TIMEOUT_MILLIS = 1000 * 5;

const DEFAULT_HEADERS = {};

// Send a GET request
async function getCall({
  url,
  params,
  headers = DEFAULT_HEADERS,
  timeout = DEFAULT_TIMEOUT_MILLIS
}: IGetCall) {
  const result = await axios({
    method: Method.GET,
    url,
    params,
    headers,
    timeout
  });
  return result.data;
}

// Send a POST request
async function postCall({
  url,
  data,
  params,
  headers = DEFAULT_HEADERS,
  timeout = DEFAULT_TIMEOUT_MILLIS
}: IPostCall) {
  const result = await axios({
    method: Method.POST,
    url,
    headers,
    data,
    timeout,
    params
  });
  return result.data;
}

// Send a PATCH request
async function patchCall({
  url,
  data,
  headers = DEFAULT_HEADERS,
  timeout = DEFAULT_TIMEOUT_MILLIS
}: IPatchCall) {
  const result = await axios({
    method: Method.PATCH,
    url,
    headers,
    data,
    timeout
  });
  return result.data;
}

// Send a DELETE request
async function deleteCall({
  url,
  data,
  params,
  headers = DEFAULT_HEADERS,
  timeout = DEFAULT_TIMEOUT_MILLIS
}: IDeleteCall) {
  const result = await axios({
    method: Method.DELETE,
    url,
    headers,
    data,
    params,
    timeout
  });
  return result.data;
}

export { getCall, postCall, patchCall, deleteCall };
