import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import SurrogatesTable from './SurrogatesTable';
import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IAddPatient {
  patient?: IPatient;
}

const ShowSurrogate = ({ patient }: IAddPatient) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const handleClick = () => setVisible(!visible);
  const closeModal = () => setVisible(false);

  useEffect(() => {
    setSlideIndex(0);
  }, [visible]);

  return (
    <div className='add-patient'>
      <Tooltip title={t.patient_operations_surrogate_list_popup_title}>
        <Button
          onClick={handleClick}
          shape='circle'
          type='primary'
          style={{ background: '#ff7a45', borderColor: '#ff7a45' }}
        >
          <span className='icofont-file-text' />
        </Button>
      </Tooltip>
      <Modal
        style={{ transition: 'ease-in-out 1s' }}
        transitionName={''}
        maskTransitionName=''
        width={slideIndex === 0 ? '70%' : 640}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={
          <h3 className='title'>
            {slideIndex === 0
              ? `${t.patient_operations_surrogate_list_popup_title}`
              : `${t.patient_operations_update_surrogate_popup_title}`}
          </h3>
        }
      >
        <SurrogatesTable patient={patient} slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
      </Modal>
    </div>
  );
};

export default ShowSurrogate;
