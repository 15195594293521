import { getCall } from '../request';

const GetPromotionCodesService = async (params = null ) => {
  const access_token = localStorage.getItem('token');
  return await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/promotion`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};
export default GetPromotionCodesService;
