import React, { useContext, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import PatientsConsents from './PatientsConsents';
import { IPatient } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IAddPatient {
  patient?: IPatient;
}

const ShowPatientConsent = ({ patient }: IAddPatient) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const closeModal = () => setVisible(false);
  const openModal = () => setVisible(true);

  return (
    <div className='add-patient'>
      <Tooltip title={t.patient_operations_patient_consents_popup_title}>
        <Button
          style={{ backgroundColor: '#3f6600', border: 'none' }}
          shape='circle'
          onClick={openModal}
        >
          <FormOutlined />
        </Button>
      </Tooltip>
      <Modal
        width={'80%'}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={
          <span style={{ fontSize: '1.5em' }}>
            {t.patient_operations_patient_consents_popup_title}
          </span>
        }
      >
        <PatientsConsents patient={patient} />
      </Modal>
    </div>
  );
};

export default ShowPatientConsent;
