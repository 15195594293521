import { patchCall } from '../request';

const PatchNewsService = async (data, id) => {
  const access_token = localStorage.getItem('token');
  return await patchCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/news/${id}`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    data
  });
};

export default PatchNewsService;
