import { getCall } from '../request';
import jwt_decode from 'jwt-decode';

const GetConsentService = async (params = null) => {
  const access_token = localStorage.getItem('token');
  const decodedToken: any = jwt_decode(access_token);
  return getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/consent-form`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'x-tenant-id': decodedToken?.tenantId,
    },
    params
  });
};

export default GetConsentService;
