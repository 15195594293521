const getDayDifference = (date1: Date, date2: Date) => {
  // Önce iki tarih arasındaki saniye farkını bulalım
  const timeDifference = date1.getTime() - date2.getTime();

  // Saniye farkını gün cinsinden ifade etmek için 24 saat * 60 dakika * 60 saniye'yi kullanarak dönüştürelim
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

  // Gün farkının tam sayı kısmını döndürelim
  return Math.floor(dayDifference);
};

export default getDayDifference;
