/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../redux/user-info/action';

import { Button, Divider, Form, Input, notification, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';

import PostProfileNewPasswordService from '../../api/user-profile/post-profile-new-password';
import PatchProfileUpdateService from '../../api/user-profile/patch-profile-update';
import GetUserByIdService from '../../api/user/get-user-id';
import PostPromotionCodeService from '../../api/promotion-code/post-promotion-code';
import CurrentUserService from '../../api/auth/current-user';

import moment from 'moment';
import FormattedInputs from './GsmInput';
import GetSubtenantCodeService from '../../api/subtenant/get-subtenant-code';
import PostSubtenantCodeService from '../../api/subtenant/post-subtenant-code';
import DeleteSubtenantCodeService from '../../api/subtenant/delete-subtenant-code';
import { usePageData } from '../../hooks';
import { IPageData, IUserInfo } from '../../interfaces';
import { LanguageContext, translations } from '../../translation';
import { NAME_REGEX, PASSWORD_REGEX } from '../../regex';
import DeleteParentTenantService from '../../api/subtenant/delete-parent-tenant';
const { Item } = Form;

const AccountForm = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [spinner, setSpinner] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    title: '',
    firstName: '',
    lastName: '',
    gsm: ''
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };
  const handleChange = (e) => {
    setFormValues((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    GetUserByIdService()
      .then((res) => {
        form.setFieldsValue({ ...res });
        setFormValues({
          title: res?.title,
          firstName: res?.firstName,
          lastName: res?.lastName,
          gsm: res?.gsm === null ? '' : res?.gsm
        });
        setSpinner(false);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_account_load,
          ''
        );
      });
  }, []);

  const onFinish = () => {
    setLoading(true);
    const data = {
      ...formValues,
      gsm: formValues.gsm.replace(/ /g, '').replace(' ', '').replace('(', '').replace(')', '')
    };
    PatchProfileUpdateService(data)
      .then(() =>
        openNotification('bottomRight', 'success', t.notification_successful_account_update, '')
      )
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_account_update,
          ''
        );
      })
      .finally(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return spinner ? (
    <Spin />
  ) : (
    <>
      {contextHolder}
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout='vertical'>
        <Item
          name={'title'}
          rules={[{ required: false, message: <>Ünvan giriniz!</> }]}
          label={t.profile_title_text}
        >
          <Input
            name='title'
            onChange={handleChange}
            placeholder={t.profile_title_text}
            value={formValues.title}
          />
        </Item>

        <Item
          name={'firstName'}
          rules={[
            { required: true, message: t.input_error_invalid_name },
            {
              pattern: NAME_REGEX,
              message: t.input_error_invalid_name
            }
          ]}
          label={t.profile_name_text}
        >
          <Input
            name='firstName'
            onChange={handleChange}
            placeholder={t.profile_name_text}
            value={formValues.firstName}
          />
        </Item>
        <Item
          name={'lastName'}
          rules={[
            { required: true, message: t.input_error_invalid_name },
            {
              pattern: NAME_REGEX,
              message: t.input_error_invalid_name
            }
          ]}
          label={t.profile_surname_text}
        >
          <Input
            name='lastName'
            onChange={handleChange}
            placeholder={t.profile_surname_text}
            value={formValues.lastName}
          />
        </Item>
        <Item name={'gsm'} label={t.profile_gsm_text}>
          <FormattedInputs
            phoneNumber={formValues.gsm ?? ''}
            name={'gsm'}
            placeholder=''
            setPhoneNumber={setFormValues}
          />
        </Item>
        <div className='elem-list justify-content-between'>
          <Button
            loading={loading}
            disabled={(formValues.gsm.length < 15 && formValues.gsm.length > 1) || loading}
            type={'primary'}
            htmlType='submit'
          >
            {t.profile_save_changes_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

const PasswordForm = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [formValues, setFormValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };
  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setLoading(true);
    PostProfileNewPasswordService(values)
      .then(() => {
        form.setFieldsValue({
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
        openNotification('bottomRight', 'success', t.notification_successful_password_change, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_password_change,
          ''
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {contextHolder}
      <Form onFinish={onFinish} form={form} layout='vertical'>
        <Item
          label={t.profile_current_password_text}
          name='oldPassword'
          rules={[{ required: true, message: t.input_error_invalid_login_password }]}
        >
          <Input.Password
            onChange={handleFormValues}
            value={formValues.oldPassword}
            placeholder={t.profile_current_password_text}
            type='oldPassword'
          />
        </Item>

        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <Item
              name='newPassword'
              label={t.profile_new_password_text}
              rules={[
                { required: true, message: t.input_error_invalid_password },
                {
                  pattern: PASSWORD_REGEX,
                  message: t.input_error_invalid_password
                }
              ]}
            >
              <Input.Password
                onChange={handleFormValues}
                type='newPassword'
                value={formValues.newPassword}
                placeholder={t.profile_new_password_text}
              />
            </Item>
          </div>

          <div className='col-md-6 col-sm-12'>
            <Item
              name='confirmPassword'
              label={t.profile_new_password_again_text}
              rules={[
                {
                  required: true,
                  message: t.input_error_invalid_password
                },
                {
                  pattern: PASSWORD_REGEX,
                  message: t.input_error_invalid_password
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Girdiğiniz şifreler eşleşmiyor!');
                  }
                })
              ]}
            >
              <Input.Password
                onChange={handleFormValues}
                placeholder={t.profile_new_password_again_text}
                type='password'
                value={formValues.confirmPassword}
                onError={() => formValues.newPassword !== formValues.confirmPassword}
              />
            </Item>
          </div>
        </div>

        <Button loading={loading} disabled={loading} block={false} htmlType='submit' type='primary'>
          {t.profile_update_password_text}
        </Button>
      </Form>
    </>
  );
};

const PromotionCode = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const [form] = useForm();
  const [code, setCode] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [finishedAt, setFinishedAt] = useState('');
  const [currentPromotionCode, setCurrentPromotionCode] = useState('');

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const onFinish = (values: any) => {
    const access_token = localStorage.getItem('token');
    PostPromotionCodeService({ code })
      .then(() => {
        form.setFieldsValue({
          promotionCode: ''
        });
        openNotification(
          'bottomRight',
          'success',
          t.notification_successful_promotion_code_entered,
          ''
        );
        window.location.reload();
        CurrentUserService(access_token)
          .then((res) => {
            dispatch(setUserInfo(res));
          })
          .catch(() => console.log());
      })
      .catch(() => {
        openNotification('bottomRight', 'error', t.notification_error_invalid_promotion_code, '');
      });
  };
  useEffect(() => {
    const access_token = localStorage.getItem('token');
    CurrentUserService(access_token)
      .then((res) => {
        setFinishedAt(moment(res?.promotionCode?.finishedAt).format('DD/MM/YYYY'));
        setCurrentPromotionCode(res?.promotionCode?.code);
      })
      .catch((err) => err);
  }, []);

  return (
    <>
      {contextHolder}

      <Form onFinish={onFinish} layout='vertical'>
        <Item
          label={t.profile_promotion_code_text}
          name='promotionCode'
          rules={[{ required: false, message: <>Promosyon kodunu giriniz!</> }]}
        >
          <Input
            placeholder={t.profile_promotion_code_text}
            type='text'
            onChange={(e) => setCode(e.target.value)}
          />
        </Item>
        <div>
          <span style={{ fontWeight: 'bold' }}>{t.profile_active_promotion_code_text}</span>
          <span> {currentPromotionCode}</span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>{t.profile_promotion_expiration_date_text}</span>
          <span> {finishedAt}</span>
        </div>

        <Button htmlType='submit' type='primary' style={{ marginTop: 16 }}>
          {t.general_update_text}
        </Button>
      </Form>
    </>
  );
};

const InvitationCode = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [currentInvitationCode, setCurrentInvitationCode] = useState('');

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);

  useEffect(() => {
    getCode()
  }, []);

  const getCode =  () => {
    GetSubtenantCodeService()
    .then((res) => {
      setCurrentInvitationCode(res.code);
    })
    .catch((err) => {
      // openNotification('bottomRight', 'error', err?.response?.data?.message ?? 'Hata Alındı', '');
    });
  }

  const createCode = () => {
    PostSubtenantCodeService()
      .then((res) => {
        openNotification('bottomRight', 'success', t.edit_profile_code_generated_notification, '');
        getCode()
      })
      .catch((err) => {
        openNotification('bottomRight', 'error', err?.response?.data?.message ?? t.edit_profile_code_generated_error_notification, '');
      });
  };

  const deleteCode = () => {
    DeleteSubtenantCodeService()
      .then((res) => {
        setCurrentInvitationCode(null);
        openNotification('bottomRight', 'success', t.edit_profile_delete_code_notification, '');
      })
      .catch((err) => {
        openNotification('bottomRight', 'error', err?.response?.data?.message ?? t.edit_profile_delete_code_error_notification, '');
      });
  };

  const deleteParentTenant = () => {
    const access_token = localStorage.getItem('token');
    DeleteParentTenantService()
      .then((res) => {
        openNotification('bottomRight', 'success', t.edit_profile_delete_connection_notification, '');
        window.location.reload();
        CurrentUserService(access_token)
          .then((res) => {
            dispatch(setUserInfo(res));
          })
          .catch(() => console.log());
      })
      .catch((err) => {
        openNotification('bottomRight', 'error', err?.response?.data?.message ?? t.edit_profile_delete_connection_error_notification, '');
      });
  };

  return (
    <>
      {contextHolder}
      <>
        {userInfo.parentId ? (
          <Button onClick={deleteParentTenant} danger type='primary' style={{ marginTop: 16 }}>
            {t.edit_profile_delete_corporate_conntection_text}
          </Button>
        ) : currentInvitationCode ? (
          <div>
            <div>
              <span style={{ fontWeight: 'bold' }}>{t.edit_profile_corporate_area_label}</span>
              <span> {currentInvitationCode}</span>
            </div>
            <Button onClick={deleteCode} danger type='primary' style={{ marginTop: 16 }}>
              {t.edit_profile_delete_code_text}
            </Button>
          </div>
        ) : (
          <Button onClick={createCode} type='primary' style={{ marginTop: 16 }}>
            {t.edit_profile_generate_corporate_code_text}
          </Button>
        )}
      </>
    </>
  );
};

const EditAccountPage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);
  const pageData: IPageData = {
    title: t.settings_profile_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.settings_profile_text
      }
    ]
  };
  usePageData(pageData);
  return (
    <div className='stack' style={{ maxWidth: 420, margin: '0 0' }}>
      {/* <UserAvatar className='mt-0' src={user.img} /> */}
      <AccountForm />
      <Divider />
      <PasswordForm />
      <Divider />
      <PromotionCode />
      <Divider />
      {userInfo.role !== 'corporate' && userInfo.role !== 'secretary' && (
        <InvitationCode />
      )}
    </div>
  );
};

export default EditAccountPage;
