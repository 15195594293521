import { postCall } from '../request';
import FormData from 'form-data';

const PostConsentFormService = (consent) => {
  const access_token = localStorage.getItem('token');

  let data = new FormData();
  data.append('name', consent.name);
  data.append('groupId', consent.group);
  data.append('file', consent.file);
  data.append('duration', consent.duration);
  data.append('price', consent.price);

  return postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/consent-form`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'multipart/form-data'
    },
    data: data,
    timeout: 60 * 1000
  });
};

export default PostConsentFormService;
