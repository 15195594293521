/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';

import { Button, Input, notification, Upload, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NotificationPlacement } from 'antd/es/notification';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';

import PostSponsorService from '../../../api/sponsor/post-sponsor';

import { LanguageContext, translations } from '../../../translation';

type Props = {
  onCancel: () => void;
};

interface IValues {
  name: string;
  file: string;
  description: string;
}

const SponsorForm = ({ onCancel }: Props) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [form] = useForm();
  const [formValues, setFormValues] = useState<IValues>({
    name: 'string',
    file: 'string',
    description: 'string'
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setLoading(true);
    const parsedValues = {
      name: values.name,
      description: values.description,
      file: fileList[0]
    };
    PostSponsorService(parsedValues)
      .then(() => {
        form.setFieldsValue({
          name: '',
          description: ''
        });
        openNotification('bottomRight', 'success', t.notification_successful_sponsor_add, '');
        dispatch(setDataUpdated(!updated));
        setTimeout(() => {
          onCancel();
        }, 1000);
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err.response.data.message ?? t.notification_error_sponsor_add,
          ''
        );
      })
      .finally(() => setLoading(false));
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} layout={'vertical'}>
        <h3>{t.sponsors_add_sponsor_text}</h3>
        <div className='form-group'>
          <Form.Item
            rules={[{ required: true, message: t.input_error_missing_sponsor_name }]}
            label={t.sponsors_add_sponsor_popup_name_text}
            name={'name'}
          >
            <Input
              name={'name'}
              placeholder={t.sponsors_add_sponsor_popup_name_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.name}
            />
          </Form.Item>
        </div>
        <div className='form-group'>
          <Form.Item
            name={'description'}
            rules={[{ required: true, message: t.input_error_missing_sponsor_description }]}
            label={t.sponsors_add_sponsor_popup_description_text}
          >
            <Input
              name={'description'}
              placeholder={t.sponsors_add_sponsor_popup_description_text}
              type='text'
              onChange={handleFormValues}
              value={formValues.description}
            />
          </Form.Item>
        </div>
        <Form.Item name='file' rules={[{ required: true, message: t.input_error_missing_file }]}>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>{t.general_pick_folder_text}</Button>
          </Upload>
        </Form.Item>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={onCancel}>
            {t.general_cancel_text}
          </Button>
          <Button loading={loading} disabled={loading} type='primary' htmlType='submit'>
            {t.sponsors_add_sponsor_text}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SponsorForm;
