import { getCall, postCall } from '../request';

const PostPatientConsentResendService = async (id) => {
  const access_token = localStorage.getItem('token');
  const user = await getCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/auth/currentUser`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/patient-consent/${id}/resend`,
    headers: {
      'x-tenant-id': user.tenantId,
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default PostPatientConsentResendService;
