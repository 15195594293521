import React, { useContext, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import SurrogateForm from './SurrogateForm';
import UpdateSurrogateForm from './UpdateSurrogateForm';
import { usePatients } from '../../../hooks';
import { IPatient, ISurrogate } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

type Props = {
  surrogate?: ISurrogate;
  patient: IPatient;
  componentType: 'add' | 'update';
};

const AddSurrogate = ({ patient, surrogate, componentType }: Props) => {
  const { addPatient } = usePatients();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);
  const closeModal = () => setVisible(false);

  const handleAddPatient = (patient: IPatient) => {
    addPatient(patient);
    closeModal();
  };

  return (
    <div className='add-patient'>
      <Tooltip
        title={
          componentType === 'add'
            ? `${t.patient_operations_add_surrogate_popup_title}`
            : `${t.patient_operations_update_surrogate_popup_title}`
        }
      >
        <Button
          type={componentType === 'add' ? 'default' : 'primary'}
          shape='circle'
          onClick={handleClick}
          htmlType='submit'
        >
          <span
            className={
              componentType === 'add' ? 'icofont icofont-plus' : 'icofont icofont-edit-alt'
            }
          />
        </Button>
      </Tooltip>
      <Modal
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={
          <h3 className='title'>
            {componentType === 'add'
              ? `${t.patient_operations_add_surrogate_popup_title}`
              : `${t.patient_operations_update_surrogate_popup_title}`}
          </h3>
        }
      >
        {componentType === 'add' && (
          <SurrogateForm
            onCancel={closeModal}
            onSubmit={handleAddPatient}
            patient={patient}
            submitText={t.patient_operations_add_surrogate_popup_add_text}
          />
        )}
        {componentType === 'update' && (
          <UpdateSurrogateForm onCancel={closeModal} patient={patient} surrogate={surrogate} />
        )}
      </Modal>
    </div>
  );
};

export default AddSurrogate;
