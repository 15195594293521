import React, { useContext, useState } from 'react';

import { Button, Modal, Tooltip } from 'antd';

import CreatePromotionCode from './CreatePromotionCode';

import { LanguageContext, translations } from '../../../translation';

const AddPromotionCode = ({ sponsorId }) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const closeModal = () => setVisible(false);

  return (
    <div className='add-sponsor'>
      <Tooltip title={t.sponsors_operations_tips_create_code_text}>
        <Button
          onClick={() => {
            setVisible(true);
          }}
          shape='circle'
        >
          <span className='icofont icofont-ui-add' />
        </Button>
      </Tooltip>
      <Modal visible={visible} footer={null} onCancel={closeModal} destroyOnClose>
        <CreatePromotionCode sponsorId={sponsorId} onCancel={closeModal} />
      </Modal>
    </div>
  );
};

export default AddPromotionCode;
