import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';

import GetUserSecretartyService from '../../../api/user/get-user-secretart';
import GetUserInvitationService from '../../../api/user-invitation/get-user-invitation';

import Contact from '../../../layout/components/doctor/Contact';
import className from '../../../utils/class-names';

import SecretaryForm from './SecretaryForm';
import { useFetchPageData, usePageData } from '../../../hooks';
import { IPageData, IUser } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

const pageData: IPageData = {
  title: 'Kullanıcılarım',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Ana Sayfa',
      route: 'main-page'
    },
    {
      title: 'Kullanıcılarım'
    }
  ]
};
const SecretarysPage = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  pageData.title = t.users_title;
  pageData.breadcrumbs[0].title = t.sidebar_mainpage_text;
  pageData.breadcrumbs[1].title = t.users_title;

  usePageData(pageData);

  const [doctors, setDoctors] = useFetchPageData<IUser[]>('./data/doctors.json', []);
  const [addingDoctor, setAddingDoctor] = useState(false);
  const [secretarys, setSecretarys] = useState([]);
  const [secretarysPending, setSecretarysPending] = useState([]);
  const updated = useSelector((state: any) => state.updated);

  const openModal = () => setAddingDoctor(true);
  const closeModal = () => setAddingDoctor(false);
  const addDoctor = (doctor: IUser) => setDoctors([doctor, ...doctors]);
  const getClass = (index: number, length: number) =>
    className({
      'mb-0': index === length - 1,
      'mb-md-0': index === length - 2 || index === length - 3
    });

  useEffect(() => {
    // setSecretarys(res)
    GetUserSecretartyService()
      .then((res) => {
        setSecretarys(res);
      })
      .catch((err) => err);

    GetUserInvitationService()
      .then((res) => {
        setSecretarysPending(res.data);
      })
      .catch((err) => err);
  }, [updated]);

  return (
    <>
      <Button style={{ marginBottom: 24 }} onClick={openModal}>
        <span className='icofont-contact-add' style={{ fontSize: '2em' }} />
        {t.users_add_user_text}
      </Button>

      <div className='row'>
        {secretarysPending?.map((secretary, index) => {
          if (secretary?.status === 'pending') {
            return (
              <div key={index} className='col-sm-12 col-md-6 col-lg-4'>
                <Contact
                  className={getClass(index, secretarysPending.length)}
                  secretary={secretary}
                />
              </div>
            );
          } else return '';
        })}

        {secretarys?.map((secretary, index) => (
          <div key={index} className='col-sm-12 col-md-6 col-lg-4'>
            <Contact className={getClass(index, secretarys.length)} secretary={secretary} />
          </div>
        ))}

        <Modal
          visible={addingDoctor}
          onCancel={closeModal}
          title={<h3 className='title'>{t.users_popup_add_user_title}</h3>}
          destroyOnClose
          footer={null}
        >
          <SecretaryForm onSubmit={addDoctor} onCancel={closeModal} />
        </Modal>
      </div>
    </>
  );
};

export default SecretarysPage;
