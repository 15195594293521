/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../redux/data-updated/action';

import { AutoComplete, Button, Form, Input, Modal, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { NotificationPlacement } from 'antd/es/notification';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';

import PostConsentFormService from '../../api/consent-form/post-consent-form';
import GetConsentGroupOnlyName from '../../api/consent-group/consent-group-only-name';

import CreateConsentGroup from './CreateConsentGroup';
import { usePageData } from '../../hooks';
import { IPageData } from '../../interfaces';
import { LanguageContext, translations } from '../../translation';

interface IValues {
  name: string;
  group: string;
  file: string;
  price: number;
  duration: string;
}
interface IOption {
  id: string;
  value: string;
  summary: string;
}

const ConsentFormUpload = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const pageData: IPageData = {
    title: t.sidebar_consent_upload_text,
    fulFilled: true,
    breadcrumbs: [
      {
        title: t.sidebar_mainpage_text,
        route: 'main-page'
      },
      {
        title: t.sidebar_consent_upload_text
      }
    ]
  };
  usePageData(pageData);
  const dispatch = useDispatch();
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [form] = useForm();
  const [formValues, setFormValues] = useState<IValues>({
    name: '',
    group: '',
    file: '',
    price: 0,
    duration: ''
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [visible, setVisible] = useState(false);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };

  useEffect(() => {
    GetConsentGroupOnlyName()
      .then((res) => {
        const data = res.data.map((el) => ({ value: el.name, id: el.id, summary: el.summary }));
        setOptions(data);
      })
      .catch((err) => openNotification('bottomRight', 'error', 'Onam Grupları Bulunamadı', ''));
  }, [updated]);

  const handleFormValues = (e) => {
    setFormValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    const parsedValues = {
      name: values.name,
      group: selectedOption,
      file: fileList[0]
    };

    PostConsentFormService(parsedValues)
      .then(() => {
        dispatch(setDataUpdated(!updated));
        form.setFieldsValue({
          name: '',
          group: '',
          packagePrice: null
        });
        setFileList([]);
        openNotification('bottomRight', 'success', t.notification_successful_file_upload, '');
      })
      .catch(() => {
        openNotification('bottomRight', 'error', t.notification_error_file_upload, '');
      })
      .finally(() => setIsLoading(false));
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList
  };
  const closeModal = () => setVisible(false);
  return (
    <>
      {contextHolder}
      <div className='row' style={{ maxWidth: '30vw' }}>
        <div className='col-md-19 col-sm-12'>
          <Form onFinish={onFinish} form={form} layout='vertical'>
            <Form.Item
              name='name'
              rules={[{ required: true, message: t.input_error_invalid_file_name }]}
            >
              <Input
                disabled={isLoading}
                value={formValues.name}
                onChange={handleFormValues}
                placeholder={t.general_folder_name}
              />
            </Form.Item>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <Form.Item
                name='group'
                rules={[{ required: true, message: t.input_error_invalid_file_type }]}
                style={{ height: 40 }}
              >
                <AutoComplete
                  style={{ width: 250 }}
                  disabled={isLoading}
                  options={options}
                  placeholder={t.purchase_package_table_column_consents_text}
                  filterOption={(inputValue, option) =>
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={(value, option) => {
                    setSelectedOption(option.id);
                  }}
                />
              </Form.Item>

              <Modal
                title={<h4>{t.consent_upload_create_consent_document_text}</h4>}
                visible={visible}
                footer={null}
                onCancel={closeModal}
                destroyOnClose
              >
                <CreateConsentGroup onCancel={closeModal} />
              </Modal>
              <Button type='primary' style={{ marginLeft: 12 }} onClick={() => setVisible(true)}>
                {t.consent_upload_create_consent_document_text}
              </Button>
            </div>

            <Form.Item
              name='file'
              rules={[{ required: true, message: t.input_error_missing_file }]}
            >
              <Upload disabled={isLoading} {...props}>
                <Button disabled={isLoading} icon={<UploadOutlined />}>
                  {t.general_pick_folder_text}
                </Button>
              </Upload>
            </Form.Item>
            <Button
              disabled={isLoading}
              loading={isLoading}
              type='primary'
              htmlType='submit'
              style={{ marginTop: 20 }}
            >
              {t.general_upload_text}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ConsentFormUpload;
