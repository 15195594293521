import { postCall } from '../request';

const PostPromotionService = async (data) => {
  const access_token = localStorage.getItem('token');

  return await postCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/promotion`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    data
  });
};

export default PostPromotionService;
