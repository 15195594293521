import React from 'react';

import Breadcrumbs from './Breadcrumbs';
import classNames from '../../../utils/class-names';
import { IBreadcrumb } from '../../../interfaces';
import FooterLoader from './FooterLoader';
import './Footer.scss';

type Props = {
  loaded?: boolean;
  boxed: boolean;
  layout: string;
  breadcrumbs: IBreadcrumb[];
  openModal: () => void;
};

const Footer = ({ boxed, loaded = false, layout, breadcrumbs, openModal }: Props) => {
  let footerClasses = classNames({
    loaded,
    boxed
  });

  return (
    <div className={`footer ${footerClasses}`}>
      <div className='footer-wrap'>
        {/* <div className='col-12 col-md-4 d-none d-md-flex align-items-center '>
          <img src={IyzicoSvg} />
          <div className='col-12 d-none d-md-block'>
            <Breadcrumbs layout={layout} breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className='col-12 col-md-8 row  align-items-center justify-content-end'>
          <div className='d-flex align-items-end justify-content-end pr-sm-0 pr-md-'>
            <span style={{fontSize:13}}>Copyright © 2023 SMART İNOVASYON TEKNOLOJİ A.Ş.</span>
          </div>
        </div> */}
        <div className='row justify-content-center w-100'>
          <div className='col-12 col-md-8 d-none d-md-flex  align-items-center'>
            <Breadcrumbs layout={layout} breadcrumbs={breadcrumbs} />
          </div>
          <div className='col-12 col-md-4 col-sm-12 d-md-flex justify-content-center align-items-center text-center'>
            Copyright © 2023 SMART İNOVASYON TEKNOLOJİ A.Ş.
          </div>
          <div />
        </div>
        <FooterLoader />
      </div>
    </div>
  );
};

export default Footer;
