import React, { useContext, useState } from 'react';

import { Button, Modal, Tooltip } from 'antd';
import UpdateNewsForm from './UpdateNewsForm';
import NewsForm from './NewsForm';
import { INews } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

interface IAddNews {
  news?: INews;
  componentType: 'update' | 'add';
}

const AddNews = ({ componentType, news }: IAddNews) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);
  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  return (
    <div className='add-news'>
      {componentType === 'update' && (
        <Tooltip title={t.news_operations_tips_update_news_text}>
          <Button htmlType='submit' onClick={handleClick} shape='circle' type='primary'>
            <span className='icofont icofont-edit-alt' />
          </Button>
        </Tooltip>
      )}
      {componentType === 'add' && (
        <Button style={{ marginBottom: 16 }} type='primary' onClick={handleClick} htmlType='submit'>
          <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
          {t.news_add_news_text}
        </Button>
      )}

      <Modal
        bodyStyle={{ maxHeight: 700, overflowY: 'scroll' }}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
      >
        {componentType === 'update' && <UpdateNewsForm onCancel={closeModal} news={news} />}
        {componentType === 'add' && <NewsForm onCancel={closeModal} />}
      </Modal>
    </div>
  );
};

export default AddNews;
