import { deleteCall } from '../request';

const DeleteNewsService = async (id) => {
  const access_token = localStorage.getItem('token');
  return await deleteCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/news/${id}`,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export default DeleteNewsService;
