/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Spin } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import './styles.css';
import { useParams } from 'react-router-dom';
import throttle from 'lodash/throttle';
import NotFound from '../sessions/404';
import GetPatientGDPRByIdService from '../../api/patient-gdpr/get-patient-gdpr-id';
import PostPatientGDPRByIdVerifyService from '../../api/patient-gdpr/post-patient-gdpr-id-verify';
import GetGDPRFormById from '../../api/gdpr-form/get-gdpr-form-id';
import { ReactComponent as CheckmarkIcon } from '../../assets/img/icons/checkmark.square.svg'; // Import the image here
import { ReactComponent as SquareIcon } from '../../assets/img/icons/square.svg'; // Import the image herepdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function GdprAndConsentPdfView() {
  const [state, setState] = useState({
    numPages: null,
    fileUrl: ''
  });
  const [loader, setLoader] = useState(true);
  const { code }: any = useParams();
  const [initialWidth, setInitialWidth] = useState(
    window.innerWidth > 415 ? (window.innerWidth / 100) * 50 : (window.innerWidth / 100) * 80
  );
  const pdfWrapper: any = useRef();
  const [isNotFound, setIsNotFound] = useState(false);
  const [data, setData] = useState<any>({});
  const [isVerify, setIsVerify] = useState<any>({});
  const [corporateFormAvailable, setCorporateFormAvailable] = useState(true); // change this based on the actual availability
  const [hospitalFormAvailable, setHospitalFormAvailable] = useState(false); // change this based on the actual availability
  const [corporatePdfUrl, setCorporatePdfUrl] = useState('');
  const [hospitalPdfUrl, setHospitalPdfUrl] = useState('');
  const [hospitalPdfName, setHospitalPdfName] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const [corporatePdfRead, setCorporatePdfRead] = useState(false);
  const [hospitalPdfRead, setHospitalPdfRead] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      if (window.innerWidth > 415) {
        setInitialWidth((window.innerWidth / 100) * 50);
      } else {
        setInitialWidth((window.innerWidth / 100) * 80);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 1000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 1000));
    };
  }, []);

  useEffect(() => {
    GetPatientGDPRByIdService(code)
      .then((res) => {
        setData({ id: res.id, code: res.code });
        setCorporatePdfUrl(res?.file?.url);
        setCorporateFormAvailable(true);
        setSelectedButton('corporate');
        setCorporatePdfRead(true);
        setState((state) => ({ ...state, fileUrl: res?.file?.url }));
        setIsVerify(res.isVerify);
        if (res?.corporateGdprFormId) {
          GetGDPRFormById(res.corporateGdprFormId).then((response) => {
            setHospitalFormAvailable(true);
            setHospitalPdfName(response?.name);
            setHospitalPdfUrl(response?.file?.url);
            if (res.isVerify) {
              setHospitalPdfRead(true);
            }
          });
        }
      })
      .catch(() => {
        setIsNotFound(true);
      })
      .finally(() => setLoader(false));
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState((state) => ({ ...state, numPages }));
    setCurrentPage(1); // Set the initial page number to 1
  };

  const { numPages, fileUrl } = state;

  const handleAccept = () => {
    if (corporatePdfRead) {
      if (hospitalFormAvailable && !hospitalPdfRead) {
        alert(`${hospitalPdfName} formunu onaylamadınız. Forma yönlendiriliyorsunuz.`);
        setSelectedButton('hospital');
        setHospitalPdfRead(true);
        setState({ ...state, fileUrl: hospitalPdfUrl });
        return;
      }
      PostPatientGDPRByIdVerifyService(data.id, { code: data.code })
        .then((res) => {
          alert('Belgeniz Onaylandı');
          setIsVerify(true);
        })
        .catch((err) => err);
    }
  };
  return (
    <div className={'pdf-page-container'}>
      {loader ? (
        <Spin />
      ) : isNotFound ? (
        <NotFound />
      ) : (
        <>
          <div className='top-bar'>
            {corporateFormAvailable && (
              <div
                id='corporate'
                className={selectedButton === 'corporate' ? 'selected' : 'unselected'}
                onClick={() => {
                  setSelectedButton('corporate');
                  setCorporatePdfRead(true);
                  setState({ ...state, fileUrl: corporatePdfUrl });
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ verticalAlign: 'middle' }}>MediOnam KVKK</span>
                    {corporatePdfRead ? (
                      <div style={{ color: '#52C57A', marginLeft: '4px', padding: '0' }}>
                        <CheckmarkIcon
                          style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                        />
                      </div>
                    ) : (
                      <div style={{ color: '#52C57A', marginLeft: '4px', padding: '0' }}>
                        <SquareIcon
                          style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                        />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            )}
            {hospitalFormAvailable && (
              <div
                id='hospital'
                className={selectedButton === 'hospital' ? 'selected' : 'unselected'}
                onClick={() => {
                  setSelectedButton('hospital');
                  setHospitalPdfRead(true);
                  setState({ ...state, fileUrl: hospitalPdfUrl });
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ verticalAlign: 'middle' }}>{hospitalPdfName}</span>
                    {hospitalPdfRead ? (
                      <div style={{ color: '#52C57A', marginLeft: '4px', padding: '0' }}>
                        <CheckmarkIcon
                          style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                        />
                      </div>
                    ) : (
                      <div style={{ color: '#52C57A', marginLeft: '4px', padding: '0' }}>
                        <SquareIcon
                          style={{ width: '20px', height: '20px', verticalAlign: 'middle' }}
                        />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div id='pdfWrapper' className='pdf-wrapper' ref={pdfWrapper}>
            {window.innerWidth > 415 ? ( // Check if the screen size is greater than 415px (desktop)
              <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={currentPage} width={initialWidth} />
              </Document>
            ) : (
              <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} width={initialWidth} />
                ))}
              </Document>
            )}
          </div>

          <div className='pdf-page-kvkk-button-area' style={{ maxWidth: window.innerWidth }}>
            {window.innerWidth > 415 ? ( // Check if the screen size is greater than 415px (desktop)
              <>
                <Button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className='page-button'
                >
                  Önceki Sayfa
                </Button>
                {!isVerify ? (
                  <Button type='primary' onClick={handleAccept} className='confirm-button'>
                    Onayla
                  </Button>
                ) : (
                  <Button type='primary' className='confirmed-text'>
                    Belgeniz Onaylanmıştır
                  </Button>
                )}

                <Button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === numPages}
                  className='page-button'
                >
                  Sonraki Sayfa
                </Button>
              </>
            ) : (
              <>
                {!isVerify ? (
                  <Button type='primary' onClick={handleAccept} className='confirm-button'>
                    Onayla
                  </Button>
                ) : (
                  <Button type='primary' className='confirmed-text'>
                    Belgeniz Onaylanmıştır
                  </Button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
