import { CloseCircleOutlined } from '@ant-design/icons';

export default function Error() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div>
        <CloseCircleOutlined style={{ fontSize: '5vw', color: 'red' }} />
      </div>
      <h3>Ödeme başarısız!</h3>
    </div>
  );
}
