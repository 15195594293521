import { Button, Modal, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { LanguageContext, translations } from '../../../translation';

export default function DeleteDoctor({ onAccept }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [visible, setVisible] = useState(false);

  const onClose = () => setVisible(false);

  return (
    <div>
      <Modal visible={visible} footer={null} onCancel={onClose} destroyOnClose>
        <p style={{ fontSize: 16 }}>{t.users_popup_remove_user_title}</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onClose}>
            {t.users_popup_remove_cancel_text}
          </Button>
          <Button
            danger
            onClick={() => {
              onAccept();
              onClose();
            }}
          >
            {t.users_popup_remove_remove_text}
          </Button>
        </div>
      </Modal>
      <Tooltip title={t.users_popup_remove_remove_text}>
        <Button
          onClick={() => {
            setVisible(true);
          }}
          shape='circle'
          danger
        >
          <span className='icofont icofont-ui-delete' />
        </Button>
      </Tooltip>
    </div>
  );
}
