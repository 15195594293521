/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { Avatar, Dropdown, Menu } from 'antd';

import { LanguageContext, translations } from '../../../translation';

const SettingsDropdown = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const accountItems = [
    { text: t.settings_profile_text, icon: 'icofont-ui-user', route: '/edit-account' },
    { text: t.settings_invoice_text, icon: 'icofont-ui-settings', route: '/invoice-information' },
    // { text: 'Takvim', icon: 'icofont-ui-calendar', route: '/vertical/events-calendar' },
    // { text: 'Ayarlar', icon: 'icofont-ui-settings', route: '/vertical/settings' },
    { text: t.settings_logout_text, icon: 'icofont-logout', route: '/public/sign-in' }
  ];

  const accountMenu = (
    <Menu style={{ minWidth: '180px' }}>
      {accountItems.map((item, index) => (
        <Menu.Item
          className='action-item'
          key={index}
          onClick={() => {
            if (item.route === '/public/sign-in') {
              localStorage.removeItem('token');
            }
          }}
        >
          <NavLink className='d-flex w-100' to={item.route} replace>
            <span className={`icon mr-3 ${item.icon}`} />
            <span className='text'>{item.text}</span>
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={accountMenu}>
      <div className='item'>
        <Avatar
          size={40}
          className='mr-1'
          src={`${window.location.origin}/content/user-401-1.png`}
        />
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsDropdown;
